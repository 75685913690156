import axios from 'axios';
import { Invoice } from './invoice';
import { PaymentConfiguration } from './variations';

export type BankAccount = {
    accountHolder: string;
    bankAccountNumber: string;
};

export type InvoicePaymentConfig = {
    invoice: Invoice;
    paymentConfiguration: PaymentConfiguration;
};

export const authoriseNewDirectDebit = async (
    invoiceUuid: string,
    bankAccount: BankAccount
): Promise<InvoicePaymentConfig> => {
    const url = `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/payment-method/direct-debit`;

    return await axios.post(url, bankAccount).then(({ data }) => data);
};
