import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ButtonProps, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import {
    ActivityType,
    ActivityVariation,
    createActivityRequest,
    EnquiryStatus,
    StatusUpdate,
} from '../../../apis/enquiry';
import EnquiryStatusChip from '../../../components/EnquiryStatusChip';
import SnackAlert, { SnackState } from '../../../components/SnackAlert';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { getFriendlyEnumsString } from '../../../util/stringUtils';

type Props = {
    enquiryIdentifier: string;
    enquiryStatus?: EnquiryStatus;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
    enquiryStatusUpdateCallback?: (newEnquiryStatus: EnquiryStatus) => void;
};

export default function EnquiryStatusAction({
    enquiryIdentifier,
    enquiryStatus,
    addNewActivityCallback,
    enquiryStatusUpdateCallback,
}: Readonly<Props>) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { enquiryUpdateAllowed } = permissions;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackState, setSnackState] = useState<SnackState | undefined>();

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!enquiryUpdateAllowed) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    const createActivity = (data: EnquiryStatus) => {
        if (enquiryStatus === data) {
            closeActionMenu();
            return;
        }

        const requestBody = {
            activityType: ActivityType.STATUS_UPDATE,
            oldStatus: enquiryStatus,
            newStatus: data,
        } as StatusUpdate;

        createActivityRequest(enquiryIdentifier, requestBody)
            .then((newActivity) => {
                if (addNewActivityCallback) {
                    addNewActivityCallback(newActivity);
                }
                if (enquiryStatusUpdateCallback) {
                    enquiryStatusUpdateCallback(data);
                }
                closeActionMenu();
            })
            .catch(() => {
                closeActionMenu();
                handleSetSnack({
                    severity: 'error',
                    msg: 'Something went wrong, please try again.',
                    anchorOriginVertical: 'bottom',
                    variant: 'filled',
                });
            });
    };

    const closeSnackbar = () => {
        setSnackOpen(false);
    };

    const handleSetSnack = (state: SnackState) => {
        setSnackState(state);
        setSnackOpen(true);
    };

    return (
        <Box>
            {enquiryUpdateAllowed ? (
                <Button
                    sx={{
                        width: 160,
                        boxShadow: 'none',
                        justifyContent: 'space-between',
                        my: 1,
                    }}
                    id='enquiry-status-action'
                    onClick={handleOpenMenu}
                    variant='contained'
                    disableElevation={true}
                    color={getVariantByStatus(enquiryStatus)}
                    endIcon={actionMenuOpen ? <ExpandLess /> : <ExpandMore />}
                >
                    {getFriendlyEnumsString(enquiryStatus as EnquiryStatus)}
                </Button>
            ) : (
                <EnquiryStatusChip
                    status={enquiryStatus as EnquiryStatus}
                    customSize='medium'
                    customStyle={{
                        my: 1.5,
                    }}
                />
            )}

            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
                slotProps={{ paper: { sx: { minWidth: 280 } } }}
            >
                {Object.values(EnquiryStatus).map((enquiryStatus) => {
                    return (
                        <MenuItem key={enquiryStatus} onClick={() => createActivity(enquiryStatus)}>
                            <ListItemText>{getFriendlyEnumsString(enquiryStatus)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
            <SnackAlert state={snackState} open={snackOpen} closeSnackbar={closeSnackbar} />
        </Box>
    );
}

const getVariantByStatus = (enquiryStatus?: EnquiryStatus): ButtonProps['color'] => {
    switch (enquiryStatus) {
        case EnquiryStatus.IN_PROGRESS:
            return 'info';
        case EnquiryStatus.NEW:
            return 'inherit';
        case EnquiryStatus.ON_HOLD:
            return 'warning';
        case EnquiryStatus.SPAM:
            return 'inherit';
        case EnquiryStatus.CLOSED:
            return 'success';
        default:
            return 'inherit';
    }
};
