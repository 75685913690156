import { Box, Divider, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PaymentFrequency } from '../../../apis/invoice';
import { PolicyVersion } from '../../../apis/quotes';

type Props = {
    policyVersion: PolicyVersion;
    paymentFrequency: PaymentFrequency;
};

export default function Premiums({ policyVersion, paymentFrequency }: Readonly<Props>) {
    const instalmentPreview = policyVersion.instalmentPreviews
        .map((preview) => preview.instalmentPreview)
        .find((preview) => preview.paymentFrequency === paymentFrequency);

    const gst = (instalmentPreview?.gstPerInstalment ?? 0) * (instalmentPreview?.numberOfAnnualPayments ?? 0);
    const totalPremiums = policyVersion.annualPremiums;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Typography variant='h6'>Premiums</Typography>
            <DescriptionTable>
                <DescriptionHeader>
                    <Typography variant='subtitle2'>Type</Typography>
                    <Typography variant='subtitle2'>Amount</Typography>
                </DescriptionHeader>
                <DescriptionRow>
                    <Typography variant='caption'>Company Premium</Typography>
                    <Typography variant='caption'>{currencyFormatter.format(totalPremiums - gst)}</Typography>
                </DescriptionRow>
                {gst > 0 && (
                    <DescriptionRow>
                        <Typography variant='caption'>Policy GST</Typography>
                        <Typography variant='caption'>{currencyFormatter.format(gst)}</Typography>
                    </DescriptionRow>
                )}
                <Divider variant='middle' />
                <DescriptionRow>
                    <Typography variant='subtitle2'>Gross Premium</Typography>
                    <Typography variant='subtitle2'>{currencyFormatter.format(totalPremiums)}</Typography>
                </DescriptionRow>
            </DescriptionTable>
        </Box>
    );
}

const DescriptionTable = styled(Box)(({ theme }) => ({
    border: grey[300],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius + 'px',
}));

const DescriptionHeader = styled(Box)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    border: grey[300],
    borderWidth: '0',
    borderBottomWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: grey[50],
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const DescriptionRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
