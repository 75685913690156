import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import PaymentMethodDetails from '../../../components/PaymentMethodDetails';
import ChangePaymentMethod from '.';
import { InvoiceDetailsResponse } from '../../../apis/invoice';
import { useFetch } from '../../../hooks/useFetch';
import { getPaymentConfiguration, PaymentConfiguration } from '../../../apis/variations';
import { ExtendableFetchWrapper } from '../../../components/ExtendableFetchWrapper';
import { PaymentMethodType } from '../../../apis/clientLead';
import { useAppSelector } from '../../../store/reducer/Hooks';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
};

export default function PaymentMethodRow({ invoiceDetails }: Readonly<Props>) {
    const paymentConfigState = useFetch(() => getPaymentConfiguration(invoiceDetails.invoice.uuid));

    return (
        <ExtendableFetchWrapper<PaymentConfiguration, Props>
            state={paymentConfigState}
            SuccessComponent={Loaded}
            invoiceDetails={invoiceDetails}
        />
    );
}

type LoadedProps = Props & {
    data: PaymentConfiguration;
};

const Loaded = ({ data, invoiceDetails }: Readonly<LoadedProps>) => {
    const [paymentConfiguration, setPaymentConfiguration] = useState(data);
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
    const { invoiceUpdateAllowed } = useAppSelector((root) => root.UserSessionReducer).permissions;
    const canEditPaymentMethod =
        invoiceUpdateAllowed &&
        !!invoiceDetails.invoice.term.termPaymentMethods.find(
            (method) => method.paymentMethodType === PaymentMethodType.DIRECT_DEBIT
        );

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='caption' component='p'>
                    Payment method
                </Typography>
                <PaymentMethodDetails paymentMethod={paymentConfiguration.paymentMethod} />
            </Box>
            {canEditPaymentMethod && (
                <Box>
                    <IconButton onClick={() => setOpenPaymentMethod(true)} size='small' color='primary'>
                        <Edit />
                    </IconButton>
                    <ChangePaymentMethod
                        open={openPaymentMethod}
                        setClosed={() => setOpenPaymentMethod(false)}
                        invoiceDetails={invoiceDetails}
                        paymentConfiguration={paymentConfiguration}
                        updatePaymentConfiguration={setPaymentConfiguration}
                    />
                </Box>
            )}
        </Box>
    );
};
