import moment from 'moment';
import { Policy } from '../apis/invoice';
import { DATE_SERVER_FORMAT } from './dateUtils';
import { upperCase } from 'lodash';

export const getEndDate = (policies: Policy[]) => {
    const endDates = policies.map((policy) => moment(policy.currentPolicyVersion.endDate, DATE_SERVER_FORMAT));
    return moment.max(endDates).endOf('day');
};

export const getStartDate = (policies: Policy[]) => {
    const startDates = policies.map((policy) =>
        moment(policy.currentPolicyVersion.anniversaryDate, DATE_SERVER_FORMAT)
    );
    return moment.min(startDates).endOf('day');
};

export const getInitials = (productCode: string) => {
    const trimmedCode = productCode.replace(/\s/g, '');
    return upperCase(trimmedCode.substring(0, Math.min(trimmedCode.length, 2)));
};
