import {
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { EnquiryType } from '../../../apis/enquiry';
import { getMenuPropsStyle } from '../../../style/theme';
import { getFriendlyEnumsString } from '../../../util/stringUtils';

type Props = {
    availableEnquiryTypes: EnquiryType[];
    enquiryTypes: string[];
    setEnquiryTypes: (enquiryTypes: string[]) => void;
};

export default function EnquiryTypeSelect({ availableEnquiryTypes, enquiryTypes, setEnquiryTypes }: Readonly<Props>) {
    const handleEnquiryTypeChange = (event: SelectChangeEvent<typeof enquiryTypes>) => {
        const {
            target: { value },
        } = event;
        const newEnquiryTypes = (typeof value === 'string' ? value.split(',') : value) as string[];

        setEnquiryTypes(newEnquiryTypes);
    };

    return (
        <FormControl size='small' fullWidth>
            <FormLabel htmlFor='enquiry-type-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                Type
            </FormLabel>
            <Select
                multiple
                value={enquiryTypes}
                displayEmpty={true}
                onChange={handleEnquiryTypeChange}
                input={<OutlinedInput id='enquiry-type-multiple-checkbox' />}
                renderValue={(selected: string[]) =>
                    renderValues(selected, availableEnquiryTypes, getFriendlyEnumsString)
                }
                MenuProps={getMenuPropsStyle().PaperProps}
            >
                {availableEnquiryTypes.map((type) => (
                    <MenuItem key={type.uuid} value={type.name}>
                        <Checkbox checked={enquiryTypes.includes(type.name)} />
                        <ListItemText primary={type.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const renderValues = (selected: string[], values: EnquiryType[], transform: (t: string) => string) => {
    if (selected.length === values.length || selected.length === 0) {
        return 'Any';
    }

    return selected.map(transform).join(', ');
};
