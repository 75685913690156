import { Box, Typography } from '@mui/material';
import RenewalPageBody from './RenewalPageBody';

export default function Renewals() {
    return (
        <Box>
            <Box width='100%' sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, pb: 4 }}>
                <Typography variant='h3' component='h1'>
                    Renewals
                </Typography>
            </Box>
            <RenewalPageBody />
        </Box>
    );
}
