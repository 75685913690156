import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Chip, Fab, useTheme } from '@mui/material';
import { lowerCase, sortBy, upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import {
    BillingType,
    DishonourReason,
    DishonourReasonSource,
    ExpectedPayment,
    ExpectedPaymentStatus,
    Loan,
    Transaction,
    TransactionType,
} from '../../../apis/invoice';
import NoRecords from '../../../components/NoRecords';
import TransactionBreakdown from '../../../components/TransactionBreakdown';
import { DATE_SERVER_FORMAT, DATE_TIME_FRIENDLY } from '../../../util/dateUtils';
import { customStyles, initialDisplayAmount } from './tableStyles';

type Props = {
    loan: Loan;
};

export default function Transactions({ loan }: Readonly<Props>) {
    const theme = useTheme();

    const [showMore, setShowMore] = useState<boolean>(false);
    const waivedNonBilledEPs =
        loan.billingType === BillingType.PREMIUMS_ADVANCED
            ? []
            : loan.activeExpectedPayments.filter(
                  ({ status, billedDate, dueDate }) =>
                      status === ExpectedPaymentStatus.WAIVED &&
                      billedDate == null &&
                      moment(dueDate, DATE_SERVER_FORMAT).isBefore(moment())
              );
    const transactions = consolidateTransactionsAndWaivedNonBilledEPs(loan.paymentTransactions, waivedNonBilledEPs);
    return (
        <Box>
            <DataTable
                data={showMore ? transactions : transactions.slice(0, initialDisplayAmount)}
                columns={columns}
                striped={true}
                customStyles={customStyles(theme)}
                noDataComponent={<NoRecords />}
            />
            {transactions.length > initialDisplayAmount && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <Fab size='small' onClick={() => setShowMore(!showMore)} data-testid='show-more-payments'>
                        {showMore ? <ExpandLess /> : <ExpandMore />}
                    </Fab>
                </Box>
            )}
        </Box>
    );
}

const PaymentStatusChip = ({ transaction }: { transaction: Transaction }) => {
    if (transaction.transactionType === TransactionType.EXTERNAL_TRANSACTION) {
        return (
            <Chip
                size='small'
                label={transaction.amount < 0 ? 'Paid externally' : 'Refunded externally'}
                color='success'
            />
        );
    }

    if ([TransactionType.WAIVED, TransactionType.DEFAULT_FEE_REVERSAL].includes(transaction.transactionType)) {
        return <Chip size='small' label='Waived' color='primary' />;
    }

    if (!transaction.dishonourDate && !transaction.confirmationDate) {
        return <Chip size='small' label='Pending' color='info' />;
    }

    if (!transaction.dishonourDate) {
        return <Chip size='small' label='Paid' color='success' />;
    }

    if (!transaction.dishonourReason || transaction.dishonourReason.source === DishonourReasonSource.INTERNAL) {
        return <Chip size='small' label='Declined' color='error' />;
    }

    return <Chip size='small' label={getDishonourDisplayLabel(transaction.dishonourReason)} color='error' />;
};

const PaymentDateCell = ({ transaction }: { transaction: Transaction }) => {
    return (
        <>
            <Moment format={DATE_TIME_FRIENDLY} style={{ marginRight: '8px' }}>
                {transaction.transactionDate}
            </Moment>
            <PaymentStatusChip transaction={transaction} />
        </>
    );
};

const columns: TableColumn<Transaction>[] = [
    {
        name: 'Payment Date',
        cell: (transaction) => <PaymentDateCell transaction={transaction} />,
    },
    {
        name: 'Amount',
        cell: (transaction) => (
            <TransactionBreakdown
                {...transaction}
                declinedResult={
                    transaction.dishonourDate ? getDishonourDisplayLabel(transaction.dishonourReason) : undefined
                }
            />
        ),
        right: true,
    },
];

const getDishonourDisplayLabel = (dishonourReason?: DishonourReason): string => {
    if (!dishonourReason?.code) {
        return 'Declined';
    }

    switch (dishonourReason.code) {
        case 'AVS_FAILED':
            return 'Declined: AVS failed';
        case 'DISHONOUR-03':
        case 'INSUFFICIENT_FUND':
            return 'Declined: Insufficient funds';
        case 'CVN_NOT_MATCH':
            return 'Declined: CVS does not match';
        case 'STOLEN_LOST_CARD':
            return 'Declined: Stolen/lost card';
        case 'INVALID_CVN':
            return 'Declined: Invalid CVN';
        case 'CV_FAILED':
            return 'Declined: CV failed';
        case 'DECISION_PROFILE_REJECT':
            return 'Declined: Decision profile rejected';
        case 'ACH_VERIFICATION_FAILED':
            return 'Declined: ACH verification failed';
        case 'DISHONOUR-01':
            return 'Declined: Unauthorised';
        case 'DISHONOUR-02':
            return 'Declined: No account';
        case 'DISHONOUR-04':
            return 'Declined: Payment stopped';
        case 'DISHONOUR-05':
            return 'Declined: Authority cancelled';
        case 'DISHONOUR-06':
            return 'Declined: Account closed';
        case 'DISHONOUR-07':
            return 'Declined: Account transferred';
        case 'DISHONOUR-08':
            return 'Declined: Payment limit exceeded';
        case 'DISHONOUR-99':
        default:
            return dishonourReason.source === DishonourReasonSource.CYBERSOURCE
                ? `Declined: ${upperFirst(lowerCase(dishonourReason.code))}`
                : 'Declined';
    }
};

const consolidateTransactionsAndWaivedNonBilledEPs = (
    paymentTransactions: Transaction[],
    waivedNonBilledEPs: ExpectedPayment[]
) => {
    const converted: Transaction[] = waivedNonBilledEPs.map((expectedPayment) => {
        const waivedAmount = expectedPayment.amount - (expectedPayment.paidAmount ?? 0);
        return {
            transactionType: TransactionType.WAIVED,
            amount: -waivedAmount,
            feeAmount: 0,
            transactionDate: expectedPayment.dueDate,
            confirmationDate: expectedPayment.dueDate,
            expectedPaymentTransactionMapping: [
                {
                    amountAllocated: waivedAmount,
                    expectedPayment,
                },
            ],
            arrangementTransactionMapping: [],
        };
    });

    return sortBy([...paymentTransactions, ...converted], 'transactionDate');
};
