import { ConsolidatedExpectedPayment, ExpectedPaymentStatus } from '../../../apis/invoice';
import {
    ExternalTransactionType,
    OndemandPaymentType,
    PaymentConfiguration,
    RecordExternalTransactionRequest,
} from '../../../apis/variations';

export type PaymentHolidyFields = {
    expectedPayments: PaymentHolidyExpectedPaymentFields[];
    note: string;
};

export type PaymentHolidyExpectedPaymentFields = ConsolidatedExpectedPayment & {
    newDueDate?: string;
};

export type PromiseToPayFields = {
    arrangementsOrExpectedPayments: ArrangementOrExpectedPaymentFields[];
    note: string;
};

export type ArrangementFields = {
    dueDate: string;
    amount: number;
};

export type ArrangementOrExpectedPaymentFields = ArrangementFields & {
    key: string;
    feeAmount?: number;
    paidAmount?: number;
    status?: ExpectedPaymentStatus;
    isNewArrangement: boolean;
};

export type RecordExternalTransactionRequestFields = RecordExternalTransactionRequest & {
    externalTransactionType: ExternalTransactionType;
};

export type OndemandPaymentFields = {
    amount: number;
    paymentType?: OndemandPaymentType;
    paymentConfiguration?: PaymentConfiguration;
    note: string;
};

export type PaymentWaiverFields = {
    expectedPayments: PaymentWaiverExpectedPaymentFields[];
    note: string;
};

export type PaymentWaiverExpectedPaymentFields = ConsolidatedExpectedPayment & {
    selected?: boolean;
};

export enum Step {
    TYPE,
    CONFIGURE,
    REVIEW,
    DONE,
}
