import { Box, FormHelperText, InputLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { capitalize } from 'lodash';
import { BREAKPOINT } from '../../../../store/reducer/BreakpointReducer';
import { useAppSelector } from '../../../../store/reducer/Hooks';
import { PaymentFrequency } from '../../../../apis/invoice';
import { AvailableTerm } from '../../../../apis/policy';

type Props = {
    terms: AvailableTerm[];
    frequency: PaymentFrequency;
    setFrequency: (frequency: PaymentFrequency) => void;
    errorMessage?: string;
};

export default function ToggleFrequency({ terms, frequency, setFrequency, errorMessage }: Readonly<Props>) {
    const { down } = useAppSelector((state) => state.BreakpointReducer);

    const handleUpdateFrequency = (_event: React.MouseEvent<HTMLElement>, newFrequency?: PaymentFrequency) => {
        if (newFrequency) {
            setFrequency(newFrequency);
        }
    };

    const verticalControlOrientation = () => down(BREAKPOINT.sm) && terms.length > 2;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <InputLabel htmlFor='payment-frequency' error={!!errorMessage}>
                Payment frequency
            </InputLabel>
            <ToggleButtonGroup
                id='payment-frequency'
                value={frequency}
                exclusive
                onChange={handleUpdateFrequency}
                color={errorMessage ? 'error' : 'primary'}
                orientation={verticalControlOrientation() ? 'vertical' : 'horizontal'}
            >
                {terms.map((term) => (
                    <ToggleButton
                        key={term.paymentFrequency}
                        value={term.paymentFrequency}
                        sx={{
                            flexDirection: verticalControlOrientation() ? 'row' : 'column',
                            justifyContent: verticalControlOrientation() ? 'flex-start' : 'center',
                        }}
                        color={errorMessage ? 'error' : 'primary'}
                    >
                        {capitalize(term.paymentFrequency)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Box>
    );
}
