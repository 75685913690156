import { Box, Chip, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { consolidateExpectedPayments } from '.';
import { DeferPaymentAction } from '../../../../apis/variations';
import PaymentAmount from '../../../../components/PaymentAmount';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: DeferPaymentAction;
};

export default function DeferPaymentActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();
    const consolidatedExpectedPayments = consolidateExpectedPayments(data.expectedPayments);

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={3} pr={2}>
                        <Typography variant='subtitle2'>Scheduled date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2'>New date</Typography>
                    </Grid>
                    <Grid item xs={3} container direction='row' justifyContent='flex-end'>
                        <Typography variant='subtitle2'>Amount</Typography>
                    </Grid>
                </StyledGridHeaderContainer>

                {consolidatedExpectedPayments.map((expectedPayment) => {
                    return (
                        <StyledGridItemContainer key={expectedPayment.uuid}>
                            <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='caption'>
                                    {moment(expectedPayment.originalDueDate).format(DATE_FRIENDLY)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='caption' sx={{ mr: 1 }}>
                                    {moment(expectedPayment.dueDate).format(DATE_FRIENDLY)}
                                </Typography>
                                <Chip size='small' label='Payment Holiday' color='primary' />
                            </Grid>
                            <Grid item xs={3} container direction='row' justifyContent='flex-end' alignItems='center'>
                                <PaymentAmount
                                    amount={expectedPayment.amount}
                                    paidAmount={expectedPayment.paidAmount}
                                />
                            </Grid>
                        </StyledGridItemContainer>
                    );
                })}
            </Grid>
        </Box>
    );
}
