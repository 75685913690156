import { Avatar, Box, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Policy } from '../../apis/invoice';
import { getInitials } from '../../util/policyUtils';

type Props = {
    policy: Policy;
    sellerProductLogo?: string;
};

export default function PolicyHeader({ policy, sellerProductLogo }: Readonly<Props>) {
    return (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {sellerProductLogo ? (
                <img src={sellerProductLogo} style={{ width: '60px', height: '60px' }} alt='Seller Product logo' />
            ) : (
                <Avatar sx={{ bgcolor: blue['200'], width: '60px', height: '60px' }}>
                    {getInitials(policy.currentPolicyVersion.productCode)}
                </Avatar>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h5'>
                    {policy.currentPolicyVersion.productName} ({policy.currentPolicyVersion.productCode})
                </Typography>
                <Typography>Policy {policy.number}</Typography>
            </Box>
        </Box>
    );
}
