import moment from 'moment';
import { useState } from 'react';
import { ExpectedPaymentStatus, Loan } from '../../../../apis/invoice';
import { ActionStatus, ActionType, SetupArrangementRequest } from '../../../../apis/variations';
import PageLoading from '../../../../components/PageLoading';
import StepsDrawer from '../../../../components/StepsDrawer';
import { FetchStateType, isSuccess } from '../../../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../../../store/reducer/Hooks';
import { setLoanState } from '../../../../store/reducer/LoanReducer';
import { DATE_SERVER_FORMAT } from '../../../../util/dateUtils';
import { PaymentHolidyFields, PaymentWaiverFields, Step } from '../types';
import { getExpectedPaymentsInStatuses } from '../utils';
import ArrangementDoneStep from './ArrangementDoneStep';
import ArrangementTypeStep from './ArrangementTypeStep';
import PaymentHolidayDetailsStep from './PaymentHolidayDetailsStep';
import PaymentHolidayReviewStep from './PaymentHolidayReviewStep';
import PaymentWaiverDetailsStep from './PaymentWaiverDetailsStep';
import PaymentWaiverReviewStep from './PaymentWaiverReviewStep';
import PromiseToPayDetailsStep from './PromiseToPayDetailsStep';
import PromiseToPayReviewStep from './PromiseToPayReviewStep';

type Props = {
    open: boolean;
    setClosed: () => void;
};

export default function CreateArrangementSteps({ open, setClosed }: Readonly<Props>) {
    const [step, setStep] = useState<Step>(Step.TYPE);
    const [loan, setLoan] = useState<Loan>();
    const [configureType, setConfigureType] = useState<PaymentVariationType>(PaymentVariationType.UNDEFINED);
    const [paymentHolidayFields, setPaymentHolidayFields] = useState<PaymentHolidyFields>();
    const [setupArrangementRequest, setSetupArrangementRequest] = useState<SetupArrangementRequest>();
    const [paymentWaiverFields, setPaymentWaiverFields] = useState<PaymentWaiverFields>();

    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const { state: sellerProductState } = useAppSelector((root) => root.SellerProductReducer);
    const dispatch = useAppDispatch();

    const handleBackToConfigureStep = () => {
        setStep(Step.CONFIGURE);
    };

    const handleBackToTypeStep = () => {
        setStep(Step.TYPE);
    };

    const handleConfigureTypeUpdate = (configureType: PaymentVariationType) => {
        setConfigureType(configureType);
        setStep(Step.CONFIGURE);
    };

    const handlePaymentHolidayConfigurationUpdate = (fields: PaymentHolidyFields) => {
        setPaymentHolidayFields(fields);
        setStep(Step.REVIEW);
    };

    const handleArrangementSetupRequestUpdate = (request: SetupArrangementRequest) => {
        setSetupArrangementRequest(request);
        setStep(Step.REVIEW);
    };

    const handlePaymentWaiverConfigurationUpdate = (fields: PaymentWaiverFields) => {
        setPaymentWaiverFields(fields);
        setStep(Step.REVIEW);
    };

    const handleLoanUpdate = (loan: Loan) => {
        setLoan(loan);
        setStep(Step.DONE);
    };

    const handleClose = () => {
        if (loan) {
            dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS }));
        }

        setLoan(undefined);
        setPaymentHolidayFields(undefined);
        setSetupArrangementRequest(undefined);
        setPaymentWaiverFields(undefined);

        setStep(Step.TYPE);
        setConfigureType(PaymentVariationType.UNDEFINED);
        setClosed();
    };

    if (!isSuccess(loanState) || !isSuccess(invoiceState) || !isSuccess(sellerProductState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    const overdueAmount = loanState.value.consolidatedExpectedPayments
        .filter((expectedPayment) => expectedPayment.status === ExpectedPaymentStatus.OVERDUE)
        .reduce((total, expectedPayment) => total + expectedPayment.amount - (expectedPayment.paidAmount ?? 0), 0);

    const noOverdueExpectedPayments = overdueAmount.toFixed(2) === '0.00';
    const hasActiveArrangementSetup =
        loanState.value.actions.filter(
            (action) => action.type === ActionType.SETUP_ARRANGEMENT && action.status === ActionStatus.ACTIVE
        ).length > 0;

    const finalPaymentDate = moment(invoiceState.value.invoice.term.finalPaymentDate, DATE_SERVER_FORMAT);
    const unpaidExpectedPayments = getExpectedPaymentsInStatuses(loanState.value, [
        ExpectedPaymentStatus.PENDING,
        ExpectedPaymentStatus.OVERDUE,
        ExpectedPaymentStatus.DEFERRED,
    ]);
    const hasExpectedPaymentsToDefer =
        unpaidExpectedPayments.findIndex((expectedPayment) =>
            moment(expectedPayment.originalDueDate, DATE_SERVER_FORMAT).isBefore(finalPaymentDate)
        ) !== -1;

    const hasExpectedPaymentsUnpaid = unpaidExpectedPayments.length > 0;
    const paymentDeferralEnabled = sellerProductState.value.paymentDeferralEnabled;

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === Step.TYPE && (
                <ArrangementTypeStep
                    cancel={handleClose}
                    configureType={configureType}
                    setConfigureType={handleConfigureTypeUpdate}
                    details={invoiceState.value}
                    disablePromiseToPay={noOverdueExpectedPayments || hasActiveArrangementSetup}
                    disablePromiseToPayHints={
                        noOverdueExpectedPayments
                            ? 'Only available when there is an overdue amount'
                            : 'Only one promise to pay arrangement is allowed at one time'
                    }
                    disablePaymentHoliday={!hasExpectedPaymentsToDefer}
                    disablePaymentHolidayHints='There is only final payment remaining and the payment date cannot be changed'
                    disablePaymentWaiver={!hasExpectedPaymentsUnpaid}
                    disablePaymentWaiverHints='There are no payments scheduled or unpaid to waive'
                    paymentDeferralEnabled={paymentDeferralEnabled}
                />
            )}
            {step === Step.CONFIGURE && configureType === PaymentVariationType.PAYMENT_HOLIDAY && (
                <PaymentHolidayDetailsStep
                    cancel={handleClose}
                    handleBack={handleBackToTypeStep}
                    paymentHolidyFields={paymentHolidayFields}
                    setPaymentHolidayFields={handlePaymentHolidayConfigurationUpdate}
                    loan={loanState.value}
                    details={invoiceState.value}
                />
            )}
            {step === Step.REVIEW &&
                configureType === PaymentVariationType.PAYMENT_HOLIDAY &&
                paymentHolidayFields != null && (
                    <PaymentHolidayReviewStep
                        cancel={handleClose}
                        handleBack={handleBackToConfigureStep}
                        paymentHolidayFields={paymentHolidayFields}
                        loan={loanState.value}
                        setLoan={handleLoanUpdate}
                        details={invoiceState.value}
                    />
                )}
            {step === Step.CONFIGURE && configureType === PaymentVariationType.PROMISE_TO_PAY && (
                <PromiseToPayDetailsStep
                    cancel={handleClose}
                    handleBack={handleBackToTypeStep}
                    setupArrangementRequest={setupArrangementRequest}
                    setSetupArrangementRequest={handleArrangementSetupRequestUpdate}
                    loan={loanState.value}
                    details={invoiceState.value}
                    overdueAmount={parseFloat(overdueAmount.toFixed(2))}
                />
            )}
            {step === Step.REVIEW &&
                configureType === PaymentVariationType.PROMISE_TO_PAY &&
                setupArrangementRequest != null && (
                    <PromiseToPayReviewStep
                        cancel={handleClose}
                        handleBack={handleBackToConfigureStep}
                        setupArrangementRequest={setupArrangementRequest}
                        loan={loanState.value}
                        setLoan={handleLoanUpdate}
                        details={invoiceState.value}
                    />
                )}
            {step === Step.CONFIGURE && configureType === PaymentVariationType.PAYMENT_WAIVER && (
                <PaymentWaiverDetailsStep
                    cancel={handleClose}
                    handleBack={handleBackToTypeStep}
                    paymentWaiverFields={paymentWaiverFields}
                    setPaymentWaiverFields={handlePaymentWaiverConfigurationUpdate}
                    loan={loanState.value}
                    details={invoiceState.value}
                />
            )}
            {step === Step.REVIEW &&
                configureType === PaymentVariationType.PAYMENT_WAIVER &&
                paymentWaiverFields != null && (
                    <PaymentWaiverReviewStep
                        cancel={handleClose}
                        handleBack={handleBackToConfigureStep}
                        paymentWaiverFields={paymentWaiverFields}
                        loan={loanState.value}
                        setLoan={handleLoanUpdate}
                        details={invoiceState.value}
                    />
                )}
            {step === Step.DONE && <ArrangementDoneStep close={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Type', 'Configure', 'Review', 'Done'];

export enum PaymentVariationType {
    UNDEFINED = 'UNDEFINED',
    PROMISE_TO_PAY = 'PROMISE_TO_PAY',
    PAYMENT_HOLIDAY = 'PAYMENT_HOLIDAY',
    PAYMENT_WAIVER = 'PAYMENT_WAIVER',
}
