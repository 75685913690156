import { ClientLead } from '../apis/clientLead';
import {
    AuthorizedIndividual,
    BaseClient,
    BusinessClient,
    CreateBusinessFields,
    CreateIndividualFields,
    CreateTrustFields,
    IndividualClient,
    InsuredType,
    TrustClient,
} from '../apis/clients';
import { Address, ContactDetails, Email, PersonalDetails, PhoneNumber } from '../types/Types';

export const getInsuredDisplayName = (
    client: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient
) => {
    const baseClient = client as BaseClient;
    if (baseClient.uuid) {
        return baseClient.displayName;
    }

    const ind = client as CreateIndividualFields;
    if (ind.firstName) {
        return `${ind.firstName} ${ind.lastName}`;
    }

    const bus = client as CreateBusinessFields;
    if (bus.businessName) {
        return bus.businessName;
    }

    const trust = client as CreateTrustFields;
    if (trust.trustName) {
        return trust.trustName;
    }
};

export const castLeadToBaseClient = (clientLead: ClientLead): BaseClient => {
    switch (clientLead.insuredType) {
        case InsuredType.INDIVIDUAL:
            return castLeadToIndividual(clientLead) as BaseClient;
        case InsuredType.BUSINESS:
            return castLeadToBusiness(clientLead) as BaseClient;
        case InsuredType.TRUST:
            return castLeadToTrust(clientLead) as BaseClient;
    }
};

export const castLeadToIndividual = (clientLead: ClientLead): Partial<IndividualClient> => {
    const names = clientLead.name ? clientLead.name.split(' ') : [''];
    return {
        contactDetails: {
            preferredPhoneNumber: {
                number: clientLead.mobileNumber,
            } as PhoneNumber,
            email: {
                address: clientLead.emailAddress,
            } as Email,
            preferredAddress: clientLead.address ?? ({} as Address),
        } as ContactDetails,
        insuredType: InsuredType.INDIVIDUAL,
        displayName: clientLead.name,
        personalDetails: {
            givenName: names.slice(0, names.length - 1).join(' '),
            surname: names[names.length - 1],
        } as PersonalDetails,
    };
};

export const castLeadToBusiness = (clientLead: ClientLead): Partial<BusinessClient> => {
    return {
        contactDetails: {
            preferredPhoneNumber: {
                number: clientLead.mobileNumber,
            } as PhoneNumber,
            email: {
                address: clientLead.emailAddress,
            } as Email,
            preferredAddress: clientLead.address ?? ({} as Address),
        } as ContactDetails,
        insuredType: InsuredType.BUSINESS,
        authorizedIndividual: {
            personalDetails: {
                givenName: '',
                surname: '',
            },
            contactDetails: {
                preferredPhoneNumber: {
                    number: clientLead.mobileNumber,
                } as PhoneNumber,
                email: {
                    address: clientLead.emailAddress,
                } as Email,
            },
        } as AuthorizedIndividual,
        displayName: clientLead.name,
        businessName: clientLead.name,
        tradingName: clientLead.name,
    };
};

export const castLeadToTrust = (clientLead: ClientLead): Partial<TrustClient> => {
    return {
        contactDetails: {
            preferredPhoneNumber: {
                number: clientLead.mobileNumber,
            } as PhoneNumber,
            email: {
                address: clientLead.emailAddress,
            } as Email,
            preferredAddress: clientLead.address ?? ({} as Address),
        } as ContactDetails,
        insuredType: InsuredType.TRUST,
        authorizedIndividual: {
            personalDetails: {
                givenName: '',
                surname: '',
            },
            contactDetails: {
                preferredPhoneNumber: {
                    number: clientLead.mobileNumber,
                } as PhoneNumber,
                email: {
                    address: clientLead.emailAddress,
                } as Email,
            },
        } as AuthorizedIndividual,
        displayName: clientLead.name,
        trustName: clientLead.name,
    };
};
