import { CalendarToday } from '@mui/icons-material';
import { Box, FormControl, FormLabel } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    renewalStartDate: string | undefined;
    setRenewalStartDate: (startDate: string | undefined) => void;
    setRenewalEndDate: (endDate: string | undefined) => void;
};

export default function RenewalCreateDateSelect({
    renewalStartDate,
    setRenewalStartDate,
    setRenewalEndDate,
}: Readonly<Props>) {
    const handleRenewalStartDateChange = (startDateInput: Moment | null) => {
        if (!startDateInput) {
            setRenewalStartDate(undefined);
            return;
        }

        if (startDateInput.isValid()) {
            setRenewalStartDate(startDateInput.format(DATE_SERVER_FORMAT));
        }
    };

    const handleRenewalEndDateChange = (endDateInput: Moment | null) => {
        if (!endDateInput) {
            setRenewalEndDate(undefined);
            return;
        }

        if (endDateInput?.isValid()) {
            setRenewalEndDate(endDateInput.format(DATE_SERVER_FORMAT));
        }
    };

    return (
        <FormControl size='small' fullWidth>
            <FormLabel sx={{ mb: 1, whiteSpace: 'nowrap' }}>Date created</FormLabel>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label='Start date'
                        defaultValue={null}
                        onChange={handleRenewalStartDateChange}
                        sx={{ width: '50%' }}
                        slotProps={{
                            textField: {
                                id: 'startDate',
                                size: 'small',
                                InputLabelProps: {
                                    style: {
                                        paddingTop: 4,
                                    },
                                },
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarToday,
                        }}
                        format={DATE_COMPACT}
                    />

                    <DatePicker
                        label='End date'
                        defaultValue={null}
                        onChange={handleRenewalEndDateChange}
                        sx={{ width: '50%' }}
                        slotProps={{
                            textField: {
                                id: 'endDate',
                                size: 'small',
                                InputLabelProps: {
                                    style: {
                                        paddingTop: 4,
                                    },
                                },
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarToday,
                        }}
                        format={DATE_COMPACT}
                        minDate={moment(renewalStartDate)}
                    />
                </LocalizationProvider>
            </Box>
        </FormControl>
    );
}
