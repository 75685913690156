import { Paper } from '@mui/material';
import { InvoicePage, listInvoices } from '../../../apis/invoice';
import { FetchWrapper } from '../../../components/FetchWrapper';
import NoRecords from '../../../components/NoRecords';
import { useFetch } from '../../../hooks/useFetch';
import PolicyDetail from './PolicyDetail';

type CustomerInvoiceProps = {
    clientUuid?: string;
};

export default function Policies({ clientUuid }: Readonly<CustomerInvoiceProps>) {
    const state = useFetch(() => listInvoices({ clientUuid }), [clientUuid]);
    return <FetchWrapper state={state} SuccessComponent={LoadedComponent} />;
}

const LoadedComponent = ({ data }: { data: InvoicePage }) => {
    return (
        <>
            {!data.records || data.records.length === 0 ? (
                <Paper variant='flat' sx={{ p: 2 }}>
                    <NoRecords />
                </Paper>
            ) : (
                data.records.map((invoice) => {
                    return <PolicyDetail key={invoice.identifier} invoice={invoice} uuid={invoice.identifier} />;
                })
            )}
        </>
    );
};
