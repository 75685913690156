import { AccessTime, CheckCircle, Edit, Error, Handshake, HelpOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Avatar, Box, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { lowerCase, upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import {
    ExpectedPaymentStatus,
    Invoice,
    InvoiceDetailsResponse,
    Loan,
    LoanSubStatus,
    PaymentFrequency,
} from '../../../apis/invoice';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { DATE_CARDINAL, DATE_COMPACT, DATE_SERVER_FORMAT, DAY_OF_WEEK } from '../../../util/dateUtils';
import CancellationAlert from '../Cancellation/CancellationAlert';
import PaymentMethodRow from '../ChangePaymentMethod/PaymentMethodRow';
import ChangePaymentSchedule from '../ChangePaymentSchedule';
import { calculateDaysDifference, getDaysDiffDesc } from '../common';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    loan: Loan;
};

export default function PaymentSummaryCard({ invoiceDetails, loan }: Readonly<Props>) {
    const { invoice } = invoiceDetails;
    const [openPaymentSchedule, setOpenPaymentSchedule] = useState(false);

    const paymentFrequency = invoice.term.paymentFrequency;
    const firstPaymentDate = moment(loan.firstPaymentDate, DATE_SERVER_FORMAT).startOf('day');
    const isFirstPayment = !loan.activeExpectedPayments.some(
        (ep) =>
            moment(ep.dueDate, DATE_SERVER_FORMAT).isBefore(firstPaymentDate, 'day') ||
            ![ExpectedPaymentStatus.PENDING, ExpectedPaymentStatus.REPLACED, ExpectedPaymentStatus.VOIDED].includes(
                ep.status
            )
    );
    const isFirstPaymentScheduled = isFirstPayment && firstPaymentDate.isSameOrAfter(moment(), 'day');
    const paidUntilDateDays = loan.paidUntilDate ? calculateDaysDifference(loan.paidUntilDate) : 0;
    const isPaidInFull = paymentFrequency === PaymentFrequency.IN_FULL;
    const {
        permissions: { invoiceUpdateAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Payment summary
            </Typography>
            <Paper variant='flat' sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {loan.cancellationRequest && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <CancellationAlert />
                        {loan.subStatus === LoanSubStatus.CANCELLED && loan.balance !== 0 && (
                            <>
                                <Box>
                                    <Typography variant='caption'>Closing balance</Typography>
                                    <Typography variant='body2'>
                                        {currencyFormat.format(loan.cancellationRequest.cancellationClosingBalance!)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', mt: 1 }}>
                                    <Error color='error' />
                                    <Typography variant='caption' color='error' sx={{ ml: 1 }}>
                                        Total is {loan.balance > 0 ? 'underpaid' : 'overpaid'} by{' '}
                                        {currencyFormat.format(loan.balance)}
                                    </Typography>
                                </Box>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                            </>
                        )}
                    </Box>
                )}
                <Box>
                    <Box>{renderPaymentSummaryState(isFirstPaymentScheduled, loan)}</Box>

                    {isFirstPaymentScheduled ? (
                        <Box>
                            <Typography variant='caption'>
                                {isPaidInFull ? 'P' : 'First p'}ayment scheduled for
                            </Typography>
                            <Typography variant='body2'>
                                {moment(invoice.term.firstPaymentDate).format(DATE_COMPACT)} (
                                {getFirstPaymentDesc(invoice.term.firstPaymentDate)})
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant='caption'>Policy paid until</Typography>
                            <Typography variant='body2'>
                                {moment(loan.paidUntilDate).format(DATE_COMPACT)} ({getDaysDiffDesc(paidUntilDateDays)})
                            </Typography>
                        </Box>
                    )}
                </Box>

                {!isPaidInFull && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='caption' component='p'>
                                Payment frequency
                            </Typography>
                            <Box>{buildFriendlyFrequency(invoice, paymentFrequency)}</Box>
                        </Box>
                        {invoiceUpdateAllowed && (
                            <Box>
                                <IconButton
                                    color='primary'
                                    onClick={() => setOpenPaymentSchedule(true)}
                                    disabled={!loan.canUpdateSchedule}
                                    size='small'
                                >
                                    <Edit />
                                </IconButton>
                                <ChangePaymentSchedule
                                    open={openPaymentSchedule}
                                    setClosed={() => setOpenPaymentSchedule(false)}
                                    invoiceDetails={invoiceDetails}
                                />
                            </Box>
                        )}
                    </Box>
                )}

                <PaymentMethodRow invoiceDetails={invoiceDetails} />
            </Paper>
        </Box>
    );
}

const calculateOverdueAmount = (loan: Loan) => {
    if (!loan.consolidatedExpectedPayments) {
        return 0;
    }
    const overdueAmount = loan.consolidatedExpectedPayments
        .filter(({ status }) => [ExpectedPaymentStatus.OVERDUE, ExpectedPaymentStatus.ARRANGEMENT].includes(status))
        .reduce((total, expectedPayment) => total + expectedPayment.amount - (expectedPayment.paidAmount ?? 0), 0);

    return overdueAmount;
};

const renderPaymentSummaryState = (isFirstPaymentScheduled: boolean, loan: Loan) => {
    if (isFirstPaymentScheduled) {
        return (
            <Box sx={{ display: 'flex', gap: 1, direction: 'column', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ backgroundColor: yellow[600], width: 24, height: 24 }}>
                    <AccessTime color='action' sx={{ width: 18, height: 18 }} />
                </Avatar>
                <Typography variant='body2'>Payment scheduled</Typography>
            </Box>
        );
    }

    const overdueAmount = calculateOverdueAmount(loan);
    if (overdueAmount > 0) {
        const inArrangement = loan.consolidatedExpectedPayments.some(
            ({ status }) => status === ExpectedPaymentStatus.ARRANGEMENT
        );
        if (inArrangement) {
            return (
                <Alert severity='warning' sx={{ mb: 2 }} icon={<Handshake />}>
                    <AlertTitle>{currencyFormat.format(calculateOverdueAmount(loan))} Overdue</AlertTitle>
                    In Arrangement
                    <Tooltip title='The client has committed to a payment arrangement which is currently on track.'>
                        <IconButton>
                            <HelpOutlined style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </Alert>
            );
        }

        return (
            <Alert severity='error' sx={{ mb: 2 }}>
                <AlertTitle>{currencyFormat.format(calculateOverdueAmount(loan))} Overdue</AlertTitle>
                {buildOverdueAlertMessage(loan.daysPastDue)}
            </Alert>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, direction: 'column', alignItems: 'center', mb: 2 }}>
            <CheckCircle color='success' fontSize='medium' />
            <Typography variant='body2'>Payments up to date</Typography>
        </Box>
    );
};

const buildOverdueAlertMessage = (daysPastDue: number) => {
    if (daysPastDue < 0) {
        // negative days past due will not be displayed
        return;
    }
    if (daysPastDue === 0) {
        return 'Payment failed today';
    }

    return `${daysPastDue} ${daysPastDue === 1 ? 'day' : 'days'} past due`;
};

const getFirstPaymentDesc = (firstPaymentDate: string): string => {
    const today = moment();
    const diff = moment(firstPaymentDate).diff(today, 'days');
    return getDaysDiffDesc(diff, 'from now');
};

const buildFriendlyFrequency = (invoice: Invoice, paymentFrequency: PaymentFrequency) => {
    const firstPaymentDate = moment(invoice.term.firstPaymentDate);

    if (paymentFrequency === PaymentFrequency.WEEKLY) {
        return <Typography variant='body2'>Weekly on {firstPaymentDate.format(DAY_OF_WEEK)}s</Typography>;
    }

    if (paymentFrequency === PaymentFrequency.FORTNIGHTLY) {
        return <Typography variant='body2'>Fortnightly on {firstPaymentDate.format(DAY_OF_WEEK)}s</Typography>;
    }

    if ([PaymentFrequency.MONTHLY, PaymentFrequency.QUARTERLY].includes(paymentFrequency)) {
        const dayOfMonth = Number(firstPaymentDate.date());
        const standardMessage = (
            <Typography variant='body2'>
                {upperFirst(lowerCase(paymentFrequency!))} on the {firstPaymentDate.format(DATE_CARDINAL)}
            </Typography>
        );

        if (dayOfMonth > 20) {
            return (
                <span>
                    {standardMessage}
                    <Typography variant='subtitle1' component='span'>
                        * Or the last day of the month for months with less than {dayOfMonth} days
                    </Typography>
                </span>
            );
        }

        return standardMessage;
    }
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
