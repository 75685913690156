import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { PaymentFormFields } from './PaymentRadioGroup';

type Props = {
    directDebitAuthorityNumber?: string;
    instalmentAmount: number;
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
};

export default function BankAccount({
    directDebitAuthorityNumber,
    instalmentAmount,
    control,
    errors,
}: Readonly<Props>) {
    const currencyFormatter = new Intl.NumberFormat('en-nz', {
        style: 'currency',
        currency: 'NZD',
    });

    return (
        <>
            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account holder</FormLabel>
                <Controller
                    name='accountHolder'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size='small'
                            error={errors.accountHolder !== undefined}
                            helperText={errors.accountHolder?.message}
                            inputProps={{ 'data-testid': 'name' }}
                        />
                    )}
                />
            </FormControl>

            <FormControl required fullWidth sx={{ mb: '20px' }}>
                <FormLabel>Account number</FormLabel>
                <Controller
                    name='bankAccountNumber'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => {
                        return (
                            <ReactInputMask
                                mask='99-9999-9999999-99'
                                alwaysShowMask={true}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                            >
                                <TextField
                                    size='small'
                                    autoComplete='no'
                                    error={errors.bankAccountNumber !== undefined}
                                    helperText={errors.bankAccountNumber?.message}
                                    {...field}
                                    inputProps={{ 'data-testid': 'bankAccount' }}
                                />
                            </ReactInputMask>
                        );
                    }}
                />
            </FormControl>

            <Typography sx={{ mt: 1, mb: 2 }}>
                The regular instalment amount is{' '}
                <span style={{ display: 'inline', fontWeight: 600, fontSize: 18 }} data-testid='regularInstalment'>
                    {currencyFormatter.format(instalmentAmount)}
                </span>
                <span style={{ display: 'inline', fontSize: 11 }}> (includes processing fee).</span>
            </Typography>

            <Typography variant='body1' sx={{ mt: 2 }}>
                Authority to accept direct debits. Authorisation code {directDebitAuthorityNumber}.
            </Typography>
        </>
    );
}
