import { Alert, AlertTitle, Box, Button, Paper, Typography } from '@mui/material';
import { size } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { InvoiceDetailsResponse, Policy } from '../../../apis/invoice';
import { PolicyVersionStatus } from '../../../apis/quotes';
import { SellerProduct } from '../../../apis/sellerProduct';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import Header from './Header';
import ItemDetail from './Item';
import Premiums from './Premiums';

export type PolicyDetailProps = {
    policy: Policy;
    invoiceDetails: InvoiceDetailsResponse;
};

type Props = PolicyDetailProps & {
    data: SellerProduct;
};

const RENEWAL_STATUSES = [
    PolicyVersionStatus.PENDING_RENEWAL,
    PolicyVersionStatus.READY_FOR_RENEWAL,
    PolicyVersionStatus.RENEWAL_PROCESSING,
];

export default function PolicyDetail({ policy, data: sellerProduct, invoiceDetails }: Readonly<Props>) {
    const [policyVersion, setPolicyVersion] = useState(policy.currentPolicyVersion);
    const renewalVersion = policy.policyVersions.find((version) => RENEWAL_STATUSES.includes(version.status));
    const paymentFrequency = invoiceDetails.invoice.term?.paymentFrequency;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {renewalVersion && (
                <Alert
                    severity='info'
                    action={
                        policyVersion.uuid === renewalVersion.uuid ? (
                            <Button onClick={() => setPolicyVersion(policy.currentPolicyVersion)}>
                                Go to current version
                            </Button>
                        ) : (
                            <Button onClick={() => setPolicyVersion(renewalVersion)}>Go to renewal version</Button>
                        )
                    }
                >
                    {policyVersion.uuid === renewalVersion.uuid
                        ? `This version will automatically be in force on ${moment(renewalVersion.anniversaryDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}.`
                        : `This policy will be renewed on ${moment(renewalVersion.anniversaryDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}.`}
                </Alert>
            )}
            <Paper
                variant='flat'
                sx={{
                    px: 2,
                    py: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Header
                    sellerProduct={sellerProduct}
                    invoiceDetails={invoiceDetails}
                    policy={policy}
                    policyVersion={policyVersion}
                />
                {policyVersion.status === PolicyVersionStatus.PENDING_RENEWAL && (
                    <Alert
                        severity='warning'
                        action={
                            <Button color='inherit' size='small' variant='outlined'>
                                Add renewal details
                            </Button>
                        }
                        sx={{ width: '100%' }}
                    >
                        <AlertTitle>Action needed: Add renewal details</AlertTitle>
                        Please provide renewal details to complete renewal configuration.
                    </Alert>
                )}
                <Premiums policyVersion={policyVersion} paymentFrequency={paymentFrequency} />
                {paymentFrequency != null && size(policyVersion.items) > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography variant='h6'>Lives Insured</Typography>
                        {policyVersion.items.map((item) => (
                            <ItemDetail key={item.uuid} item={item} paymentFrequency={paymentFrequency} />
                        ))}
                    </Box>
                )}
            </Paper>
        </Box>
    );
}
