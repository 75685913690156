import { Chip, SxProps } from '@mui/material';
import { PolicyVersionStatus } from '../../apis/quotes';
import { getFriendlyEnumsString } from '../../util/stringUtils';

type Props = {
    status: PolicyVersionStatus;
    customStyle?: SxProps;
};

export default function RenewalStatusChip({ status, customStyle }: Readonly<Props>) {
    switch (status) {
        case PolicyVersionStatus.PENDING_RENEWAL:
            return (
                <Chip
                    label={getFriendlyEnumsString(status)}
                    color='warning'
                    variant='outlined'
                    sx={customStyle}
                    size='small'
                />
            );
        case PolicyVersionStatus.READY_FOR_RENEWAL:
            return (
                <Chip
                    label={getFriendlyEnumsString(status)}
                    color='info'
                    variant='outlined'
                    sx={customStyle}
                    size='small'
                />
            );
        default:
            return <Chip label='No Status' variant='outlined' sx={customStyle} size='small' />;
    }
}
