import { InsertDriveFile, PictureAsPdf } from '@mui/icons-material';

type Props = {
    contentType: string;
};

export default function DocumentIcon({ contentType }: Readonly<Props>) {
    switch (contentType) {
        case 'application/pdf':
            return <PictureAsPdf fontSize='small' color='error' />;
        case 'text/csv; charset=utf-8':
            return <InsertDriveFile fontSize='small' color='success' />;
        default:
            return <></>;
    }
}
