import { UseFormSetError } from 'react-hook-form';
import { authoriseNewDirectDebit, BankAccount, InvoicePaymentConfig } from '../../../apis/paymentMethod';
import { PaymentFormFields } from './PaymentRadioGroup';

export default async ({
    bankAccount,
    invoiceUuid,
    setLoadingRes,
    setError,
    setUpdatedModels,
    setFormError,
}: {
    bankAccount: BankAccount;
    invoiceUuid: string;
    setLoadingRes: (loading: boolean) => void;
    setError: UseFormSetError<PaymentFormFields>;
    setUpdatedModels: (invoicePaymentConfig: InvoicePaymentConfig) => void;
    setFormError: (error: string | null) => void;
}) => {
    setFormError(null);
    setLoadingRes(true);
    await authoriseNewDirectDebit(invoiceUuid, bankAccount)
        .then(setUpdatedModels)
        .catch((e) => {
            if (e.response && e.response.status === 400) {
                setError('bankAccountNumber', { message: 'Invalid bank/branch' });
            }
            setLoadingRes(false);
            setFormError('Something went wrong, please try again.');
        });
};
