import axios from 'axios';
import { Invoice } from './invoice';
import { assignDefaultListParams, Direction, ListParams, SearchResult } from './common';
import { PolicyVersionStatus } from './quotes';

export enum RenewalSortProperty {
    RENEWAL_POLICY_NUMBER = 'RENEWAL_POLICY_NUMBER',
    RENEWAL_PRODUCT_NAME = 'RENEWAL_PRODUCT_NAME',
    RENEWAL_DATE = 'RENEWAL_DATE',
    RENEWAL_STATUS = 'RENEWAL_STATUS',
}

type ListRenewalsParams = ListParams & {
    renewalSortProperty: RenewalSortProperty;
    direction: Direction;
    renewalDateFrom?: string;
    renewalDateTo?: string;
    renewalStatuses?: PolicyVersionStatus[];
};

export type RenewalSummary = {
    invoiceIdentifier: string;
    policyNumber: string;
    productName: string;
    clientName: string;
    renewalDate: string;
    renewalStatus: PolicyVersionStatus;
};

export type RenewalsPage = SearchResult<RenewalSummary>;

export const voidRenewal = async (invoiceUuid: string, note: string): Promise<Invoice> => {
    return await axios
        .delete(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}`, { data: { note } })
        .then(({ data }) => data);
};

export const sendRenewalToCustomer = async (invoiceUuid: string): Promise<Invoice> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}/_action/send-to-customer`)
        .then(({ data }) => data);
};

export const searchRenewals = async (partial?: Partial<ListRenewalsParams>): Promise<RenewalsPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/_query/worklist`, searchRequest)
        .then(({ data }) => data);
};
