import { Block, EditCalendar, HandshakeOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { InvoiceDetailsResponse } from '../../../../apis/invoice';
import ChoiceCard from '../../../../components/ChoiceCard';
import StepCard from '../../../../components/StepCard';
import { getInvoicePolicyDescription } from '../utils';
import { PaymentVariationType } from './CreateArrangementSteps';

type Props = {
    cancel: () => void;
    configureType: PaymentVariationType;
    setConfigureType: (configureType: PaymentVariationType) => void;
    details: InvoiceDetailsResponse;
    disablePromiseToPay: boolean;
    disablePromiseToPayHints: string;
    disablePaymentHoliday: boolean;
    disablePaymentHolidayHints: string;
    disablePaymentWaiver: boolean;
    disablePaymentWaiverHints: string;
    paymentDeferralEnabled: boolean;
};

export default function ArrangementTypeStep({
    cancel,
    configureType,
    setConfigureType,
    details,
    disablePromiseToPay,
    disablePromiseToPayHints,
    disablePaymentHoliday,
    disablePaymentHolidayHints,
    disablePaymentWaiver,
    disablePaymentWaiverHints,
    paymentDeferralEnabled,
}: Readonly<Props>) {
    const theme = useTheme();
    const [chosenConfigureType, setChosenConfigureType] = useState<PaymentVariationType>(
        PaymentVariationType.UNDEFINED
    );

    useEffect(() => {
        setChosenConfigureType(configureType);
    }, [configureType]);

    const onNext = () => {
        setConfigureType(chosenConfigureType);
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Create a payment arrangement
            </Typography>
            <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid item xs={3}>
                    <ChoiceCard
                        selected={chosenConfigureType === PaymentVariationType.PROMISE_TO_PAY}
                        handleSelected={() => setChosenConfigureType(PaymentVariationType.PROMISE_TO_PAY)}
                        disabled={disablePromiseToPay}
                        disabledHints={disablePromiseToPayHints}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    sx={{
                                        bgcolor: disablePromiseToPay
                                            ? theme.palette.black[10]
                                            : theme.palette.primary.light,
                                        width: 64,
                                        height: 64,
                                    }}
                                >
                                    <HandshakeOutlined
                                        color={disablePromiseToPay ? 'disabled' : 'primary'}
                                        sx={{ fontSize: 32 }}
                                    />
                                </Avatar>
                                <Typography
                                    variant='h6'
                                    component='h2'
                                    color={disablePromiseToPay ? theme.palette.black[40] : theme.palette.black[100]}
                                >
                                    Promise to pay
                                </Typography>
                                <Typography textAlign='center' variant='subtitle1'>
                                    Create a flexible arrangement schedule for collecting an overdue balance.
                                </Typography>
                            </Box>
                        </Box>
                    </ChoiceCard>
                </Grid>
                {paymentDeferralEnabled && (
                    <Grid item xs={3}>
                        <ChoiceCard
                            selected={chosenConfigureType === PaymentVariationType.PAYMENT_HOLIDAY}
                            handleSelected={() => setChosenConfigureType(PaymentVariationType.PAYMENT_HOLIDAY)}
                            disabled={disablePaymentHoliday}
                            disabledHints={disablePaymentHolidayHints}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                    <Avatar
                                        sx={{
                                            bgcolor: disablePaymentHoliday
                                                ? theme.palette.black[10]
                                                : theme.palette.primary.light,
                                            width: 64,
                                            height: 64,
                                        }}
                                    >
                                        <EditCalendar
                                            color={disablePaymentHoliday ? 'disabled' : 'primary'}
                                            sx={{ fontSize: 32 }}
                                        />
                                    </Avatar>
                                    <Typography
                                        variant='h6'
                                        component='h2'
                                        color={
                                            disablePaymentHoliday ? theme.palette.black[40] : theme.palette.black[100]
                                        }
                                    >
                                        Payment holiday
                                    </Typography>
                                    <Typography textAlign='center' variant='subtitle1'>
                                        Defer scheduled payments to a future date.
                                    </Typography>
                                </Box>
                            </Box>
                        </ChoiceCard>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <ChoiceCard
                        selected={chosenConfigureType === PaymentVariationType.PAYMENT_WAIVER}
                        handleSelected={() => setChosenConfigureType(PaymentVariationType.PAYMENT_WAIVER)}
                        disabled={disablePaymentWaiver}
                        disabledHints={disablePaymentWaiverHints}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    sx={{
                                        bgcolor: disablePaymentWaiver
                                            ? theme.palette.black[10]
                                            : theme.palette.primary.light,
                                        width: 64,
                                        height: 64,
                                    }}
                                >
                                    <Block
                                        color={disablePaymentWaiver ? 'disabled' : 'primary'}
                                        sx={{ fontSize: 32 }}
                                    />
                                </Avatar>
                                <Typography
                                    variant='h6'
                                    component='h2'
                                    color={disablePaymentWaiver ? theme.palette.black[40] : theme.palette.black[100]}
                                >
                                    Payment waiver
                                </Typography>
                                <Typography textAlign='center' variant='subtitle1'>
                                    Void selected paymetns. They will not be collected or owned by the client.
                                </Typography>
                            </Box>
                        </Box>
                    </ChoiceCard>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={chosenConfigureType === PaymentVariationType.UNDEFINED}
                        onClick={onNext}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </StepCard>
    );
}
