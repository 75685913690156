import { createSlice } from '@reduxjs/toolkit';
import { SellerProduct } from '../../apis/sellerProduct';
import { FetchState, pendingState } from '../../hooks/useFetch';

type SellerProductAction = {
    type: string;
    payload: FetchState<SellerProduct>;
};

const initialState: { state: FetchState<SellerProduct> } = {
    state: pendingState,
};

export const sellerProductSlice = createSlice({
    name: 'sellerProduct',
    initialState,
    reducers: {
        setSellerProductState: (state, action: SellerProductAction) => {
            state.state = action.payload;
        },
        clearSellerProductState: (state) => {
            state.state = pendingState;
        },
    },
});

export const { setSellerProductState, clearSellerProductState } = sellerProductSlice.actions;

export default sellerProductSlice.reducer;
