import { PaymentFrequency } from '../../../../apis/invoice';
import { AvailableTerm } from '../../../../apis/policy';
import NextPaymentDate from './NextPaymentDate';
import ToggleFrequency from './ToggleFrequency';

export type FormProps = {
    frequency: PaymentFrequency;
    handleSetFrequency: (terms: AvailableTerm[]) => (frequency: PaymentFrequency) => void;
    selectedTerm: AvailableTerm;
    setNextPaymentDate: (date: string) => void;
    nextPaymentDate: string;
    frequencyError?: string;
    dateError?: string;
};

type Props = FormProps & {
    data: AvailableTerm[];
};

export default function Form({
    data: terms,
    frequency,
    handleSetFrequency,
    selectedTerm,
    setNextPaymentDate,
    nextPaymentDate,
    frequencyError,
    dateError,
}: Readonly<Props>) {
    return (
        <>
            <ToggleFrequency
                terms={terms}
                frequency={frequency}
                setFrequency={handleSetFrequency(terms)}
                errorMessage={frequencyError}
            />
            <NextPaymentDate
                selectedTerm={selectedTerm!}
                setNextPaymentDate={setNextPaymentDate}
                nextPaymentDate={nextPaymentDate}
                errorMessage={dateError}
            />
        </>
    );
}
