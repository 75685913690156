import { Box, Button, Paper, Typography } from '@mui/material';

import {
    CalendarTodayOutlined,
    EmailOutlined,
    NearMeOutlined,
    PersonOutlineOutlined,
    PhoneOutlined,
} from '@mui/icons-material';
import moment from 'moment/moment';
import { Client, isIndividual } from '../../../apis/clients';
import FormattedAddress from '../../../components/FormattedAddress';

interface ClientDetailsCardProps {
    client: Client;
}

const ClientDetailsCard = (props: ClientDetailsCardProps) => {
    const client = props.client;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Client details
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Typography variant='h6' mb={2}>
                    {client.displayName}
                </Typography>

                {isIndividual(client) && (
                    <Typography variant='body2' sx={{ display: 'flex', gap: 1, wordBreak: 'break-word', mb: 1 }}>
                        <CalendarTodayOutlined titleAccess='Date of birth' fontSize='small' />
                        {client.personalDetails.dateOfBirth
                            ? moment(client.personalDetails.dateOfBirth).format('DD MMMM YYYY')
                            : '-'}
                    </Typography>
                )}

                <Typography variant='body2' sx={{ display: 'flex', gap: 1, wordBreak: 'break-word', mb: 1 }}>
                    <EmailOutlined titleAccess='Email address' fontSize='small' />
                    {client.contactDetails?.email?.address ? client.contactDetails?.email?.address : '-'}
                </Typography>

                <Typography variant='body2' sx={{ display: 'flex', gap: 1, wordBreak: 'break-word', mb: 1 }}>
                    <PhoneOutlined titleAccess='Mobile number' fontSize='small' />
                    {client.contactDetails.preferredPhoneNumber.number}
                </Typography>

                <Typography variant='body2' sx={{ display: 'flex', gap: 1, wordBreak: 'break-word', mb: 1 }}>
                    <NearMeOutlined titleAccess='Home address' fontSize='small' />
                    <FormattedAddress address={client.contactDetails.preferredAddress} />
                </Typography>

                <Box sx={{ mt: 2 }}>
                    <Button
                        href={'/clients/' + client.uuid}
                        variant='outlined'
                        fullWidth
                        startIcon={<PersonOutlineOutlined />}
                    >
                        View Client
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default ClientDetailsCard;
