import { Box, Chip, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { consolidateExpectedPayments } from '.';
import { ExpectedPaymentCausedByType } from '../../../../apis/invoice';
import { WaivePaymentAction } from '../../../../apis/variations';
import PaymentAmount from '../../../../components/PaymentAmount';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: WaivePaymentAction;
};

export default function WaivePaymentActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();
    const consolidatedExpectedPayments = consolidateExpectedPayments(data.expectedPayments);

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={6} pr={2}>
                        <Typography variant='subtitle2'>Scheduled date</Typography>
                    </Grid>
                    <Grid item xs={6} container direction='row' justifyContent='flex-end'>
                        <Typography variant='subtitle2'>Amount</Typography>
                    </Grid>
                </StyledGridHeaderContainer>

                {consolidatedExpectedPayments.map((expectedPayment) => {
                    return (
                        <StyledGridItemContainer key={expectedPayment.uuid}>
                            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='caption'>
                                    {moment(expectedPayment.dueDate).format(DATE_FRIENDLY)}
                                </Typography>
                                <Chip size='small' label='Waived' color='primary' sx={{ ml: 1 }} />
                                {expectedPayment.causedBy === ExpectedPaymentCausedByType.DEFAULT_FEE && (
                                    <Chip
                                        size='small'
                                        label='default fee'
                                        color='error'
                                        variant='outlined'
                                        sx={{ ml: 1 }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                                <PaymentAmount
                                    amount={expectedPayment.amount}
                                    paidAmount={expectedPayment.paidAmount}
                                    feeAmount={expectedPayment.feeAmount}
                                    waived={true}
                                />
                            </Grid>
                        </StyledGridItemContainer>
                    );
                })}
            </Grid>
        </Box>
    );
}
