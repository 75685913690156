import { ContactDetails, Email, EmailType } from '../../../types/Types';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { Client, createEmailPromise, updateEmailPromise } from '../../../apis/clients';
import { LoadingButton } from '../../../components/LoadingButton';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';

type Props = {
    email?: Email;
    client: Client;
    callbackEmailUpdate: (contactDetails: ContactDetails) => void;
};

const schema = yup.object({
    address: yup.string().email('Valid email required').required('Email address is required'),
    preferred: yup.boolean().optional(),
    emailType: yup.string().required('Email type is required'),
});

export default function EmailForm({ email, client, callbackEmailUpdate }: Readonly<Props>) {
    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Email>({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => {
            return email;
        }, [email]),
    });

    const onSubmit: SubmitHandler<Email> = (data) => {
        setErrorMsg(undefined);
        setSubmitting(true);
        if (email?.uuid) {
            updateEmail(client.uuid, data);
        } else {
            createEmail(client.uuid, data);
        }
    };

    const updateEmail = async (clientUuid: string, email: Email) => {
        updateEmailPromise(clientUuid, email)
            .then((contactDetails: ContactDetails) => {
                callbackEmailUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    const createEmail = async (clientUuid: string, email: Email) => {
        createEmailPromise(clientUuid, email)
            .then((contactDetails: ContactDetails) => {
                callbackEmailUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    return (
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{email ? <>Add new email</> : <>Update email</>}</DialogTitle>
            <DialogContent sx={{ width: 480 }}>
                <Box sx={{ mb: 2 }}>
                    <FormControl component='fieldset'>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='emailType'
                            render={({ field }) => (
                                <RadioGroup {...field} row name='emailType' id='emailType'>
                                    <FormControlLabel value={EmailType.PERSONAL} control={<Radio />} label='Personal' />
                                    <FormControlLabel value={EmailType.WORK} control={<Radio />} label='Work' />
                                </RadioGroup>
                            )}
                        />
                        {!!errors?.emailType && (
                            <FormHelperText error={!!errors?.emailType}>{errors?.emailType?.message}</FormHelperText>
                        )}
                    </FormControl>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Controller
                            name='address'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id='email'
                                    autoComplete='no'
                                    error={!!errors?.address}
                                    helperText={errors?.address?.message}
                                />
                            )}
                        />
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ position: 'relative' }}>
                        <Controller
                            name='preferred'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={onChange}
                                            checked={value}
                                            id='preferred'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                    }
                                    label='Set as preferred contact address'
                                />
                            )}
                        />
                    </Box>
                </Box>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
            </DialogContent>

            <DialogActions>
                <Button variant='text' color='primary'>
                    Close
                </Button>
                <LoadingButton data-testid='saveEmail' type='submit' variant='contained' loading={submitting}>
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
