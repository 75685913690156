import axios from 'axios';
import { assignDefaultListParams, ListParams, SearchResult } from './common';

export type DocumentDto = {
    contentType: string;
    name: string;
    uuid: string;
    createdDate: string;
};

export type PagedDocumentDto = {
    invoiceIdentifier: string;
} & SearchResult<DocumentDto>;

export const fetchDocumentByIdentifier = async (documentIdentifier: string): Promise<DocumentDto> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/document/${documentIdentifier}`)
        .then(({ data }) => data);
};

export const fetchAvailableDocuments = async (
    invoiceIdentifier: string,
    partial: Partial<ListParams>
): Promise<PagedDocumentDto> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/documents`, searchRequest)
        .then(({ data }) => data);
};

export const fetchDocument = async (
    invoiceIdentifier: string,
    documentIdentifier: string
): Promise<Blob | undefined> => {
    const url = new URL(
        `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/documents/${documentIdentifier}`
    );
    return await axios.get(url.href, { responseType: 'blob' }).then(({ data }) => data);
};

export enum FileAction {
    DOWNLOAD,
    VIEW,
}

export const processAction = (action: FileAction, fileUrl: string, name: string) => {
    if (action === FileAction.VIEW) {
        const pdfWindow = window.open();
        pdfWindow!.location.href = fileUrl;
    } else {
        const anchorElement = document.createElement('a');
        anchorElement.href = fileUrl;
        anchorElement.setAttribute('download', name);
        anchorElement.target = '_blank';
        document.body.appendChild(anchorElement);
        anchorElement.click();
        anchorElement?.parentNode?.removeChild(anchorElement);
    }
};
