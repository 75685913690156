import { Box, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { defaultPageSize } from '../../../apis/common';
import { DocumentDto, fetchAvailableDocuments } from '../../../apis/document';
import DocumentButton from '../../../components/DocumentButton';
import DocumentIcon from '../../../components/DocumentButton/DocumentIcon';
import ErrorMessage from '../../../components/ErrorMessage';
import FormattedDateTime from '../../../components/FormattedDateTime';
import NoRecords from '../../../components/NoRecords';
import PageLoading from '../../../components/PageLoading';
import { isError, isLoading, isPending, isSuccess, useFetch } from '../../../hooks/useFetch';
import { getWorklistStyles } from '../../../style/theme';
import { DATE_FRIENDLY, TIME_FRIENDLY } from '../../../util/dateUtils';
import { getFullName } from '../../../util/stringUtils';

type Props = {
    invoiceIdentifier: string;
};

export default function InvoiceDocumentList({ invoiceIdentifier }: Readonly<Props>) {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const state = useFetch(
        () =>
            fetchAvailableDocuments(invoiceIdentifier, {
                page: page - 1,
                pageSize,
            }),
        [page, pageSize, invoiceIdentifier]
    );

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <Paper variant='flat' sx={{ py: 3, px: 2, width: '100%' }}>
            <DataTable
                striped={true}
                data={isSuccess(state) ? state.value.records : []}
                columns={getColumns(invoiceIdentifier)}
                pagination
                paginationServer
                onChangePage={setPage}
                onChangeRowsPerPage={setPageSize}
                paginationPerPage={pageSize}
                paginationDefaultPage={page}
                paginationTotalRows={isSuccess(state) ? state.value.totalRecords : 0}
                progressPending={isLoading(state) || isPending(state)}
                progressComponent={<PageLoading />}
                noDataComponent={
                    <Box width='100%'>
                        <NoRecords />
                    </Box>
                }
                customStyles={{
                    ...getWorklistStyles('NAME'),
                    rows: {
                        style: {
                            height: 65,
                        },
                    },
                    cells: {
                        style: {
                            wrap: true,
                        },
                    },
                }}
            />
        </Paper>
    );
}

const getColumns = (invoiceIdentifier: string) => {
    return [
        {
            id: 'NAME',
            name: 'Name',
            cell: (row) => (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', gap: 1, width: '100%' }}>
                    <DocumentIcon contentType={row.contentType} />
                    <FormattedName name={row.name} />
                </Box>
            ),
            grow: 5,
        },
        {
            id: 'CREATED_DATE',
            name: 'Created date',
            cell: (row) => (
                <FormattedDateTime date={row.createdDate} dateFormat={DATE_FRIENDLY} timeFormat={TIME_FRIENDLY} />
            ),
            grow: 2,
        },
        {
            name: '',
            style: {
                justifyContent: 'flex-end',
            },
            cell: (row) => (
                <DocumentButton
                    invoiceIdentifier={invoiceIdentifier}
                    documentIdentifier={row.uuid}
                    name={row.name}
                    buttonType='button'
                />
            ),
            grow: 3,
        },
    ] as TableColumn<DocumentDto>[];
};

const FormattedName = ({ name }: { name: string }) => {
    return (
        <Typography
            noWrap
            variant='caption'
            data-tag='allowRowEvents'
            sx={{
                textAlign: 'center',
                overflow: 'hidden',
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                alignContent: 'center',
            }}
        >
            {getFullName(name)}
        </Typography>
    );
};
