import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Save } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ActivityType, ActivityVariation, createActivityRequest, Note } from '../../../../apis/enquiry';
import { LoadingButton } from '../../../../components/LoadingButton';
import { SnackState } from '../../../../components/SnackAlert';

type NoteFormProps = {
    enquiryIdentifier: string;
    closeDrawer: () => void;
    handleSetSnack: (state: SnackState) => void;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
};

type FormFields = {
    details: string;
};

const NoteForm = ({ enquiryIdentifier, closeDrawer, handleSetSnack, addNewActivityCallback }: NoteFormProps) => {
    const [saving, setSaving] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormFields> = (data) => {
        setSaving(true);

        const requestBody = {
            activityType: ActivityType.NOTE,
            details: data.details,
        } as Note;

        createActivityRequest(enquiryIdentifier, requestBody)
            .then((newActivity) => {
                if (addNewActivityCallback) {
                    addNewActivityCallback(newActivity);
                }
                setSaving(false);
                closeDrawer();
            })
            .catch(() => {
                setSaving(false);
                handleSetSnack({
                    severity: 'error',
                    msg: 'Something went wrong, please try again.',
                    anchorOriginVertical: 'bottom',
                    variant: 'filled',
                });
            });
    };

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h2'>Add Note</Typography>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth required sx={{ py: 2 }}>
                    <FormLabel>Note details</FormLabel>
                    <Controller
                        name='details'
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                multiline
                                rows={10}
                                autoComplete='no'
                                error={errors.details != undefined}
                                helperText={errors.details?.message}
                            />
                        )}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ width: '50%', alignSelf: 'flex-end' }}>
                        <LoadingButton
                            type='submit'
                            variant='contained'
                            fullWidth
                            size='large'
                            startIcon={<Save />}
                            loading={saving}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Box>
            </form>
        </Paper>
    );
};

const schema = yup.object({
    details: yup.string().required('Note contents required'),
});

export default NoteForm;
