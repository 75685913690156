import { Box, Button, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import StepCard from '../../../components/StepCard';

type Props = {
    handleClose: () => void;
};

export default function SuccessStp({ handleClose }: Readonly<Props>) {
    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CheckCircle color='success' fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        Payment updated
                    </Typography>
                </Box>
                <Typography textAlign='center'>
                    We've securely stored your new preferred payment method and will use it for all future payments on
                    this invoice.
                </Typography>
                <Button onClick={handleClose} variant='contained'>
                    Close
                </Button>
            </Box>
        </StepCard>
    );
}
