import { Download, VisibilityOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, useTheme } from '@mui/material';
import { FileAction } from '../../apis/document';

type DocumentTextIconButtonProps = {
    selectedAction?: FileAction;
    isLoading: boolean;
    onClick: (action: FileAction) => void;
};

export default function DocumentTextIconButton({
    selectedAction,
    isLoading,
    onClick,
}: Readonly<DocumentTextIconButtonProps>) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
                disabled={isLoading}
                onClick={() => onClick(FileAction.VIEW)}
                endIcon={
                    isLoading && selectedAction === FileAction.VIEW ? (
                        <CircularProgress color='inherit' size={20} data-testid='loadingSpinner' />
                    ) : (
                        <VisibilityOutlined sx={{ color: isLoading ? 'inherit' : theme.palette.primary.main }} />
                    )
                }
                sx={{ whiteSpace: 'nowrap' }}
                size='small'
            >
                View
            </Button>
            <Divider sx={{ mx: 0.5 }} />
            <Button
                disabled={isLoading}
                onClick={() => onClick(FileAction.DOWNLOAD)}
                endIcon={
                    isLoading && selectedAction === FileAction.DOWNLOAD ? (
                        <CircularProgress color='inherit' size={20} data-testid='loadingSpinner' />
                    ) : (
                        <Download sx={{ color: isLoading ? 'inherit' : theme.palette.primary.main }} />
                    )
                }
                size='small'
                sx={{ whiteSpace: 'nowrap' }}
            >
                Download
            </Button>
        </Box>
    );
}
