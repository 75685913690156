import { Box, Checkbox, Link, Paper, Typography, useTheme } from '@mui/material';
import { EnquiryCreateRequestKeys, EnquiryDetail, EnquiryFormFields } from '../../apis/enquiry';
import { DATE_FRIENDLY } from '../../util/dateUtils';
import { getFullName } from '../../util/stringUtils';
import moment from 'moment';

type Props = {
    enquiryDetail?: EnquiryDetail;
};

export default function EnquiryDetailSection({ enquiryDetail }: Readonly<Props>) {
    const theme = useTheme();
    const formData = enquiryDetail?.rawDetails
        ? JSON.parse(enquiryDetail?.rawDetails).filter(
              (detailItem: EnquiryFormFields) =>
                  !Object.values(EnquiryCreateRequestKeys).includes(detailItem.name as EnquiryCreateRequestKeys)
          )
        : [];

    return (
        <Box sx={{ my: 3 }}>
            <Typography variant='h6' component='h2' sx={{ py: 2 }}>
                Enquiry details
            </Typography>
            <Box>
                <Paper variant='flat' sx={{ py: 1, px: 3, wordBreak: 'break-all' }}>
                    <Box sx={{ my: 3 }}>
                        <Typography variant='subtitle2'>Name</Typography>
                        <Typography variant='caption'>{getFullName(enquiryDetail?.clientName)}</Typography>
                    </Box>

                    <Box sx={{ my: 3 }}>
                        <Typography variant='subtitle2'>Email</Typography>
                        <Link
                            variant='caption'
                            sx={{ ':hover': { cursor: 'pointer' } }}
                            onClick={() => {
                                window.location.href = `mailto:${enquiryDetail?.emailAddress}`;
                            }}
                        >
                            {enquiryDetail?.emailAddress}
                        </Link>
                    </Box>

                    <Box sx={{ my: 3 }}>
                        <Typography variant='subtitle2'>Phone</Typography>
                        <Link
                            variant='caption'
                            sx={{ ':hover': { cursor: 'pointer' } }}
                            onClick={() => {
                                window.location.href = `tel:${enquiryDetail?.mobileNumber}`;
                            }}
                        >
                            {enquiryDetail?.mobileNumber}
                        </Link>
                    </Box>

                    {formData.map((enquiryDetailItem: EnquiryFormFields, index: number) => {
                        switch (enquiryDetailItem.type) {
                            case 'radio-group':
                            case 'checkbox-group':
                            case 'select': {
                                return (
                                    <Box key={`enquiryDetailItem-${index}`} sx={{ my: 3 }}>
                                        <Typography variant='subtitle2'>{enquiryDetailItem.label}</Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            {enquiryDetailItem.values?.map((option, index) => {
                                                return (
                                                    <Typography key={`option-${index}`} variant='caption'>
                                                        <Checkbox
                                                            disabled
                                                            checked={option.selected}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: theme.palette.primary.main,
                                                                },
                                                            }}
                                                        />
                                                        {option.label}
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            }
                            case 'date': {
                                return (
                                    <Box sx={{ my: 3 }} key={`enquiryDetailItem-${index}`}>
                                        <Typography variant='subtitle2'>{enquiryDetailItem.label}</Typography>
                                        <Typography variant='caption'>
                                            {enquiryDetailItem.value?.length == 0
                                                ? '-'
                                                : moment(enquiryDetailItem.value as string).format(DATE_FRIENDLY)}
                                        </Typography>
                                    </Box>
                                );
                            }
                            default:
                                return (
                                    <Box sx={{ my: 3 }} key={`enquiryDetailItem-${index}`}>
                                        <Typography variant='subtitle2'>{enquiryDetailItem.label}</Typography>
                                        <Typography variant='caption'>
                                            {enquiryDetailItem.value?.length == 0 ? '-' : enquiryDetailItem.value}
                                        </Typography>
                                    </Box>
                                );
                        }
                    })}
                </Paper>
            </Box>
        </Box>
    );
}
