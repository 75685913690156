import {
    Accordion,
    AccordionDetails,
    AccordionSummary as BaseAccordionSummary,
    Box,
    FormControl,
    FormHelperText,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import { forwardRef } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Invoice } from '../../../apis/invoice';
import BankAccount from './BankAccount';
import { Charges, PaymentMethods } from './UpdateStep';

// @ts-ignore
import visaLogo from '../../../images/visa.svg';
// @ts-ignore
import mcLogo from '../../../images/mastercard.svg';

export enum InvoicePaymentMethod {
    CREDIT_CARD = 'CREDIT_CARD',
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    NOT_SET = 'NOT_SET',
}

export type PaymentFormFields = {
    accountHolder: string;
    bankAccountNumber: string;
    paymentMethod: InvoicePaymentMethod;
};

type Props = {
    charges: Charges;
    paymentMethods: PaymentMethods;
    control: Control<PaymentFormFields>;
    errors: FieldErrors<PaymentFormFields>;
    setValue: UseFormSetValue<PaymentFormFields>;
    getValues: UseFormGetValues<PaymentFormFields>;
    invoice: Invoice;
};

export default function PaymentRadioGroup({
    control,
    errors,
    charges,
    paymentMethods,
    getValues,
    invoice,
}: Readonly<Props>) {
    const instalmentAmount = invoice.term.instalmentAmount ?? 0;

    return (
        <Box>
            <FormControl fullWidth required sx={{ mb: 2 }}>
                <Controller
                    name='paymentMethod'
                    control={control}
                    render={({ field }) => (
                        <RadioGroup
                            id='paymentMethod'
                            {...field}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                if (value) {
                                    event.target.value = value;
                                    field.onChange(event);
                                }
                            }}
                        >
                            {paymentMethods.cardPaymentMethod != null && (
                                <Tooltip
                                    title='For extra security, credit card details can only be entered by the client in their portal.'
                                    placement='top'
                                    arrow
                                >
                                    <CardAccordian />
                                </Tooltip>
                            )}
                            {charges.directDebit != null && (
                                <Accordion
                                    expanded={getValues('paymentMethod') === InvoicePaymentMethod.DIRECT_DEBIT}
                                    disableGutters={true}
                                    sx={{ boxShadow: 3 }}
                                >
                                    <AccordionSummary>
                                        <label
                                            htmlFor='ddOption'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <Radio id='ddOption' value={InvoicePaymentMethod.DIRECT_DEBIT} />
                                                <Typography>Direct debit</Typography>
                                            </span>
                                        </label>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ bgcolor: grey['200'] }}>
                                        <BankAccount
                                            directDebitAuthorityNumber={
                                                paymentMethods.directDebitPaymentMethod!.directDebitAuthorityNumber
                                            }
                                            instalmentAmount={instalmentAmount + charges.directDebit}
                                            control={control}
                                            errors={errors}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </RadioGroup>
                    )}
                />
            </FormControl>
            {errors.paymentMethod?.message && (
                <FormHelperText error={!!errors?.paymentMethod}>{errors?.paymentMethod?.message}</FormHelperText>
            )}
        </Box>
    );
}

const CardAccordian = forwardRef(function CardAccordian(props, ref: React.ForwardedRef<HTMLDivElement>) {
    return (
        <Accordion disableGutters={true} sx={{ boxShadow: 3 }} ref={ref} {...props}>
            <AccordionSummary disabled>
                <label
                    htmlFor='ccOption'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        cursor: 'pointer',
                    }}
                >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio id='ccOption' value={InvoicePaymentMethod.CREDIT_CARD} disabled />
                        <Typography>Credit card</Typography>
                    </span>
                    <span style={{ height: '100%', display: 'flex', marginRight: '8px' }}>
                        <img src={visaLogo} alt='Visa logo' width='38px' />
                        <img
                            src={mcLogo}
                            style={{ marginLeft: 1, marginRight: 1 }}
                            alt='Mastercard logo'
                            width='38px'
                        />
                    </span>
                </label>
            </AccordionSummary>
        </Accordion>
    );
});

const AccordionSummary = styled(BaseAccordionSummary)(() => ({
    padding: 0,
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
}));
