import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import NoRecords from '../../../components/NoRecords';
import { DATE_MONTH_DAY_YEAR_TIME } from '../../../util/dateUtils';
import { Note } from '../../../types/Types';

type Props = {
    notes: Note[];
};

export default function ClientNote({ notes }: Readonly<Props>) {
    return (
        <Box>
            {!notes || notes.length === 0 ? (
                <NoRecords />
            ) : (
                notes.map((note, index) => {
                    return (
                        <Box key={note.uuid}>
                            <Box sx={{ pt: 2, pb: 1 }}>
                                <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: 1, pt: 1, pb: 1 }}>
                                    {note.contents}
                                </Typography>
                                <Typography variant='subtitle1'>
                                    {note.user} &#x2022;{' '}
                                    {moment.utc(note.createdDate).local().format(DATE_MONTH_DAY_YEAR_TIME)}
                                </Typography>
                            </Box>
                            {notes.length - 1 !== index && <Divider />}
                        </Box>
                    );
                })
            )}
        </Box>
    );
}
