import { yupResolver } from '@hookform/resolvers/yup';
import { ExpandLess, ExpandMore, RemoveCircleOutline } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Chip,
    Fab,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import { ArrangementActionMode, SetupArrangementRequest } from '../../../../apis/variations';
import ExpectedPaymentStatusChip from '../../../../components/ExpectedPaymentStatusChip';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../../util/dateUtils';
import { getLoanCancellationEffectiveDate } from '../../common';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { ArrangementFields, PromiseToPayFields } from '../types';
import {
    combineExpectedPaymentsAndArrangements,
    getExpectedPaymentsInStatuses,
    getInvoicePolicyDescription,
} from '../utils';
import CreateArrangement from './CreateArrangement';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    setupArrangementRequest?: SetupArrangementRequest;
    setSetupArrangementRequest: (request: SetupArrangementRequest) => void;
    loan: Loan;
    details: InvoiceDetailsResponse;
    overdueAmount: number;
};

export default function PromiseToPayDetailsStep({
    cancel,
    handleBack,
    setupArrangementRequest,
    setSetupArrangementRequest,
    loan,
    details,
    overdueAmount,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);

    const loanCancellationEffectiveDate = getLoanCancellationEffectiveDate(loan);
    const termFinalPaymentDate = moment(details.invoice.term.finalPaymentDate, DATE_SERVER_FORMAT);
    const finalPaymentDate = moment.min(loanCancellationEffectiveDate.subtract(1, 'days'), termFinalPaymentDate);

    const pendingExpectedPayments = useMemo(
        () => getExpectedPaymentsInStatuses(loan, [ExpectedPaymentStatus.PENDING, ExpectedPaymentStatus.DEFERRED]),
        [loan]
    );

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<PromiseToPayFields>({
        resolver: yupResolver(
            yup.object({
                note: yup.string().required('Note required'),
            })
        ),
        defaultValues: {
            ...setupArrangementRequest,
            arrangementsOrExpectedPayments: combineExpectedPaymentsAndArrangements(
                pendingExpectedPayments,
                setupArrangementRequest?.arrangements
            ),
        },
    });

    const {
        fields: arrangementsOrExpectedPayments,
        insert,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'arrangementsOrExpectedPayments',
    });

    const remainingAmountToAllocate =
        overdueAmount -
        arrangementsOrExpectedPayments
            .filter(({ isNewArrangement }) => isNewArrangement)
            .reduce((total, arrangement) => total + arrangement.amount, 0);

    const onSubmit: SubmitHandler<PromiseToPayFields> = (data) => {
        setErrorMsg(undefined);

        const request = cloneDeep({
            note: data.note,
            mode: ArrangementActionMode.STRICT,
            arrangements: data.arrangementsOrExpectedPayments
                .filter(({ isNewArrangement }) => isNewArrangement)
                .map((arrangement) => ({
                    ...arrangement,
                    dueDate: moment(arrangement.dueDate).format(DATE_SERVER_FORMAT),
                })),
        } as SetupArrangementRequest);

        if (isEmpty(request.arrangements)) {
            setErrorMsg('You must setup at least one payment arrangement for the overdue instalments.');
            return;
        }

        setSetupArrangementRequest(request);
    };

    const removeNewArrangement = (index: number) => {
        remove(index);
    };

    const addNewArrangement = ({ amount, dueDate }: ArrangementFields) => {
        const item = {
            amount,
            dueDate,
            isNewArrangement: true,
            key: Math.random().toString(),
        };

        const index = arrangementsOrExpectedPayments.findIndex((arrangementOrExpectedPayment) =>
            moment(arrangementOrExpectedPayment.dueDate).isAfter(moment(dueDate))
        );

        if (index === -1) {
            append(item);
        } else {
            insert(index, item);
        }
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Promise to pay
            </Typography>
            <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Typography variant='caption'>
                Create scheduled payments for the overdue balance by choosing an amount and payment date. The total
                overdue balance must be allocated to continue.
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 1, sm: 6 },
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>
                        <Typography variant='caption'>Total overdue</Typography>
                        <Typography variant='subtitle2' component='p'>
                            {currencyFormat.format(overdueAmount)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>
                        <Typography variant='caption'>Remaining to allocate</Typography>
                        <Typography variant='subtitle2' component='p'>
                            {currencyFormat.format(remainingAmountToAllocate)}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <CreateArrangement
                maxAmount={parseFloat(remainingAmountToAllocate.toFixed(2))}
                maxDueDate={finalPaymentDate}
                onArrangementCreated={addNewArrangement}
            />

            <form onSubmit={handleSubmit(onSubmit, console.log)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Box>
                        <Grid
                            container
                            sx={{
                                border: grey[400],
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: theme.shape.borderRadius + 'px',
                            }}
                        >
                            <StyledGridHeaderContainer>
                                <Grid item xs={6} pr={2}>
                                    <Typography variant='subtitle2'>Scheduled date</Typography>
                                </Grid>
                                <Grid item xs={6} container direction='row' justifyContent='flex-end'>
                                    <Typography variant='subtitle2'>Amount (excludes fees)</Typography>
                                </Grid>
                            </StyledGridHeaderContainer>

                            {overdueAmount <= 0 && (
                                <StyledGridItemContainer>
                                    <Grid item xs={12} pr={2}>
                                        <Typography variant='subtitle2'>
                                            No overdue instalments for payment arrangement setup.
                                        </Typography>
                                    </Grid>
                                </StyledGridItemContainer>
                            )}

                            {overdueAmount > 0 &&
                                arrangementsOrExpectedPayments.map(
                                    ({ id, dueDate, amount, paidAmount, status, isNewArrangement }, index) => {
                                        if (
                                            !showMore &&
                                            index >= defaultNumberOfPaymentsBeforeShowMore &&
                                            !isNewArrangement
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <StyledGridItemContainer key={id}>
                                                <Grid item xs={6} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography variant='caption' sx={{ mr: 1 }}>
                                                        {moment(dueDate).format(DATE_FRIENDLY)}
                                                    </Typography>
                                                    {isNewArrangement && (
                                                        <Chip size='small' label='Promise to pay' color='primary' />
                                                    )}
                                                    {status && (
                                                        <ExpectedPaymentStatusChip expectedPaymentStatus={status} />
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    container
                                                    direction='row'
                                                    justifyContent='flex-end'
                                                    alignItems='center'
                                                >
                                                    <PaymentAmount
                                                        amount={amount}
                                                        paidAmount={paidAmount}
                                                        feeAmount={0}
                                                    />
                                                    {isNewArrangement && (
                                                        <IconButton
                                                            onClick={() => removeNewArrangement(index)}
                                                            sx={{ mt: -1.5 }}
                                                        >
                                                            <RemoveCircleOutline color='error' />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </StyledGridItemContainer>
                                        );
                                    }
                                )}

                            {overdueAmount > 0 &&
                                arrangementsOrExpectedPayments.length > defaultNumberOfPaymentsBeforeShowMore && (
                                    <StyledGridFooterContainer>
                                        <Grid item xs={12} pr={2}>
                                            <Box
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Fab
                                                    size='small'
                                                    onClick={() => setShowMore(!showMore)}
                                                    data-testid='show-more-scheduled-payments'
                                                >
                                                    {showMore ? <ExpandLess /> : <ExpandMore />}
                                                </Fab>
                                            </Box>
                                        </Grid>
                                    </StyledGridFooterContainer>
                                )}
                        </Grid>
                    </Box>

                    <FormControl required>
                        <FormLabel htmlFor='note' required={false}>
                            Note
                        </FormLabel>
                        <Controller
                            name='note'
                            control={control}
                            defaultValue={setupArrangementRequest?.note ?? ''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register('note')}
                                    data-testid={'note'}
                                    size='small'
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 1024 }}
                                    error={!!errors?.note}
                                    helperText={errors?.note?.message}
                                />
                            )}
                        />
                    </FormControl>

                    {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                    <Grid container>
                        <Grid item xs={6}>
                            <Button onClick={handleBack} variant='outlined'>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                            <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                                Cancel
                            </Button>
                            {overdueAmount > 0 && (
                                <Button
                                    disabled={
                                        remainingAmountToAllocate.toFixed(2) > '0.00' ||
                                        arrangementsOrExpectedPayments.filter(
                                            ({ isNewArrangement }) => isNewArrangement
                                        ).length === 0
                                    }
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    sx={{ minWidth: '160px' }}
                                >
                                    Next
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </StepCard>
    );
}

const defaultNumberOfPaymentsBeforeShowMore = 6;

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
