import { ExpectedPaymentStatus } from '../apis/invoice';
import { Chip } from '@mui/material';

type Props = {
    expectedPaymentStatus: ExpectedPaymentStatus;
};

export default ({ expectedPaymentStatus }: Readonly<Props>) => {
    switch (expectedPaymentStatus) {
        case ExpectedPaymentStatus.PAID:
            return <Chip label='Paid' color='success' size='small' />;
        case ExpectedPaymentStatus.WAIVED:
            return <Chip label='Waived' color='info' size='small' />;
        case ExpectedPaymentStatus.OVERDUE:
            return <Chip label='Overdue' color='error' size='small' />;
        case ExpectedPaymentStatus.ARRANGEMENT:
            return <Chip label='Arrangement' color='warning' size='small' />;
        case ExpectedPaymentStatus.DEFERRED:
            return <Chip label='Payment Holiday' color='info' size='small' />;
        default:
            return <></>;
    }
};
