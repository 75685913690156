import { AccessTime, Check, Stop } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { InvoiceSummaryStatus } from '../apis/invoice';

type InvoiceStatusChipProps = {
    status: InvoiceSummaryStatus;
};

export default function InvoiceStatusChip({ status }: Readonly<InvoiceStatusChipProps>) {
    switch (status) {
        case InvoiceSummaryStatus.ACTIVE:
            return (
                <Chip
                    size='small'
                    sx={{ pointerEvents: 'none' }}
                    label='Active'
                    color='success'
                    variant='outlined'
                    icon={<Check fontSize='small' />}
                />
            );
        case InvoiceSummaryStatus.INACTIVE:
            return (
                <Chip
                    size='small'
                    sx={{ pointerEvents: 'none' }}
                    label='Inactive'
                    variant='outlined'
                    icon={<Stop fontSize='small' />}
                />
            );
        case InvoiceSummaryStatus.PENDING:
            return (
                <Chip
                    size='small'
                    sx={{ pointerEvents: 'none' }}
                    label='Pending'
                    color='info'
                    variant='outlined'
                    icon={<AccessTime fontSize='small' />}
                />
            );
    }
}
