import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { PolicyVersionStatus } from '../../apis/quotes';
import List from './List';
import RenewalCreateDateSelect from './selectFilters/RenewalCreatedDateSelect';
import RenewalStatusSelect from './selectFilters/RenewalStatusSelect';

export default function RenewalPageBody() {
    const [renewalStatuses, setRenewalStatuses] = useState<PolicyVersionStatus[]>([]);
    const [renewalStartDate, setRenewalStartDate] = useState<string>();
    const [renewalEndDate, setRenewalEndDate] = useState<string>();

    return (
        <Paper variant='flat' sx={{ py: 3, px: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', pb: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%', gap: 2 }}>
                    <Box sx={{ width: { xs: '100%', sm: '20%' } }}>
                        <RenewalStatusSelect
                            renewalStatuses={renewalStatuses}
                            setRenewalStatuses={setRenewalStatuses}
                        />
                    </Box>
                    <Box sx={{ width: { xs: '100%', sm: '30%' } }}>
                        <RenewalCreateDateSelect
                            renewalStartDate={renewalStartDate}
                            setRenewalStartDate={setRenewalStartDate}
                            setRenewalEndDate={setRenewalEndDate}
                        />
                    </Box>
                </Box>
            </Box>
            <List
                renewalStatuses={renewalStatuses}
                renewalStartDate={renewalStartDate}
                renewalEndDate={renewalEndDate}
            />
        </Paper>
    );
}
