import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Paper, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { PaymentFrequency } from '../../../apis/invoice';
import {
    Cover,
    CoverSet,
    CreateEnquiryRequestItem,
    InstalmentPreview,
    InstalmentPreviewWrapper,
    Item,
} from '../../../apis/quotes';
import { DATE_COMPACT, DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    item: Item;
    paymentFrequency: PaymentFrequency;
};

export default function ItemDetail({ item, paymentFrequency }: Readonly<Props>) {
    const [expanded, setExpanded] = useState(false);

    const frequencyLong = capitalize(paymentFrequency);
    const frequencyShort = findShortFrequency(paymentFrequency);
    const totalAnnualPremiums = findInstalmentPreviews(item.coverSets, paymentFrequency).reduce(
        (a, v) => a + v.annualPremiums,
        0
    );
    const questionnaire: CreateEnquiryRequestItem[] = JSON.parse(item.underwritingDetails.answers);

    return (
        <Paper
            variant='outlined'
            sx={{
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h6'>{item.name}</Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>
                        <Typography variant='subtitle1'>Annual premium</Typography>
                        <Typography variant='caption'>{currencyFormatter.format(totalAnnualPremiums)}</Typography>
                    </Box>
                    <IconButton onClick={() => setExpanded(!expanded)} color='primary' size='small'>
                        {expanded ? <ExpandLess fontSize='large' /> : <ExpandMore fontSize='large' />}
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={expanded}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 2 }}>
                    <Divider />
                    <Box>
                        <Typography variant='h6' mb={1}>
                            Covers
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {item.coverSets.map((coverSet) => {
                                const previews = findInstalmentPreviews([coverSet], paymentFrequency);
                                const totalAnnualPremiumsForCoverSet = previews.reduce(
                                    (a, v) => a + v.annualPremiums,
                                    0
                                );
                                const totalInstalmentAmountForCoverSet = previews.reduce(
                                    (a, v) => a + v.instalmentAmount,
                                    0
                                );

                                return (
                                    <DescriptionTable key={coverSet.uuid}>
                                        <DescriptionHeader>
                                            <Box flexGrow={1}>
                                                <Typography variant='subtitle2'>
                                                    Cover from {moment(coverSet.coverStartDate).format(DATE_FRIENDLY)}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ minWidth: 150, textAlign: 'right' }}>
                                                <Typography variant='subtitle2'>Annual premium</Typography>
                                            </Box>
                                            {paymentFrequency !== PaymentFrequency.IN_FULL && (
                                                <Box sx={{ minWidth: 150, textAlign: 'right' }}>
                                                    <Typography variant='subtitle2'>{frequencyLong} premium</Typography>
                                                </Box>
                                            )}
                                        </DescriptionHeader>
                                        {getItemCovers(coverSet.covers, paymentFrequency).map((cover) => {
                                            return (
                                                <DescriptionRow key={cover.uuid}>
                                                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                        <Typography variant='caption'>
                                                            {cover.productCoverName}
                                                        </Typography>
                                                        <Typography variant='subtitle1'>
                                                            {cover.coverageAmount > 0
                                                                ? `${currencyFormatter.format(cover.coverageAmount)} sum insured`
                                                                : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ minWidth: 150, textAlign: 'right' }}>
                                                        <Typography variant='caption'>
                                                            {currencyFormatter.format(
                                                                cover.instalmentPreview.annualPremiums
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    {paymentFrequency !== PaymentFrequency.IN_FULL && (
                                                        <Box
                                                            sx={{
                                                                minWidth: 150,
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Typography variant='caption'>
                                                                {currencyFormatter.format(
                                                                    cover.instalmentPreview.instalmentAmount
                                                                )}
                                                            </Typography>
                                                            <Typography variant='subtitle1' component='span'>
                                                                &nbsp;/{frequencyShort}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </DescriptionRow>
                                            );
                                        })}
                                        <Divider variant='middle' />
                                        <DescriptionRow>
                                            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant='caption'>Total</Typography>
                                            </Box>
                                            <Box sx={{ minWidth: 150, textAlign: 'right' }}>
                                                <Typography variant='caption'>
                                                    {currencyFormatter.format(totalAnnualPremiumsForCoverSet)}
                                                </Typography>
                                            </Box>
                                            {paymentFrequency !== PaymentFrequency.IN_FULL && (
                                                <Box
                                                    sx={{
                                                        minWidth: 150,
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant='caption'>
                                                        {currencyFormatter.format(totalInstalmentAmountForCoverSet)}
                                                    </Typography>
                                                    <Typography variant='subtitle1' component='span'>
                                                        &nbsp;/{frequencyShort}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </DescriptionRow>
                                    </DescriptionTable>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant='h6' mb={1}>
                            Personal details
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {twoArray(questionnaire).map((questions) => (
                                <Box key={`${questions[0].name}-parent`} sx={{ display: 'flex', gap: 4 }}>
                                    {questions.map((question) => {
                                        let value = question.value;
                                        if (question.type === 'radio-group') {
                                            value = question.values?.find((value) => value.selected)?.label ?? value;
                                        } else if (question.type === 'date' && value) {
                                            value = moment(value, DATE_SERVER_FORMAT).format(DATE_COMPACT);
                                        }

                                        return (
                                            <Box key={question.name} sx={{ display: 'flex', gap: 1, width: '50%' }}>
                                                <Box minWidth={150}>
                                                    <Typography variant='body2'>{question.label}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant='caption'>{value}</Typography>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant='h6' mb={1}>
                            Beneficiaries
                        </Typography>
                        <DescriptionTable>
                            <DescriptionHeader>
                                <Box flexGrow={1}>
                                    <Typography variant='subtitle2'>Person</Typography>
                                </Box>
                                <Box sx={{ minWidth: 95, maxWidth: 95 }}>
                                    <Typography variant='subtitle2'>Date of birth</Typography>
                                </Box>
                                <Box sx={{ minWidth: { xs: 160, xl: 300 }, maxWidth: { xs: 160, xl: 300 } }}>
                                    <Typography variant='subtitle2'>Contact details</Typography>
                                </Box>
                                <Box sx={{ minWidth: 80, maxWidth: 80, textAlign: 'right' }}>
                                    <Typography variant='subtitle2'>Percentage</Typography>
                                </Box>
                            </DescriptionHeader>
                            {item.beneficiaries.map(
                                ({
                                    uuid,
                                    name,
                                    relationshipToPolicyHolder,
                                    dateOfBirth,
                                    address,
                                    emailAddress,
                                    contactNumber,
                                    distribution,
                                }) => {
                                    const noContactDetails = !address && !emailAddress && !contactNumber;

                                    return (
                                        <DescriptionRow key={uuid}>
                                            <Box flexGrow={1}>
                                                <Typography variant='caption'>{name}</Typography>
                                                {relationshipToPolicyHolder && (
                                                    <Typography variant='subtitle1'>
                                                        ({relationshipToPolicyHolder})
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box sx={{ minWidth: 95, maxWidth: 95 }}>
                                                <Typography variant='caption'>
                                                    {dateOfBirth
                                                        ? moment(dateOfBirth, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)
                                                        : '-'}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{ minWidth: { xs: 160, xl: 300 }, maxWidth: { xs: 160, xl: 300 } }}
                                            >
                                                {address && <Typography variant='subtitle1'>{address}</Typography>}
                                                {emailAddress && (
                                                    <Typography variant='subtitle1'>{emailAddress}</Typography>
                                                )}
                                                {contactNumber && (
                                                    <Typography variant='subtitle1'>{contactNumber}</Typography>
                                                )}
                                                {noContactDetails && <Typography variant='caption'>-</Typography>}
                                            </Box>
                                            <Box sx={{ minWidth: 80, maxWidth: 80, textAlign: 'right' }}>
                                                <Typography variant='caption'>{distribution * 100}%</Typography>
                                            </Box>
                                        </DescriptionRow>
                                    );
                                }
                            )}
                        </DescriptionTable>
                    </Box>
                </Box>
            </Collapse>
        </Paper>
    );
}

const twoArray = <T,>(items: T[]): T[][] => {
    const split = [];
    while (items.length) {
        split.push(items.splice(0, 2));
    }
    return split;
};

const DescriptionTable = styled(Box)(({ theme }) => ({
    border: grey[300],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius + 'px',
}));

const DescriptionHeader = styled(Box)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    border: grey[300],
    borderWidth: '0',
    borderBottomWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: grey[50],
    display: 'flex',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    gap: theme.spacing(2),
}));

const DescriptionRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    gap: theme.spacing(2),
    alignItems: 'center',
    wordBreak: 'break-word',
}));

const findInstalmentPreviews = (coverSets: CoverSet[], paymentFrequency: PaymentFrequency): InstalmentPreview[] => {
    return coverSets
        .flatMap((set) => set.instalmentPreviews)
        .map((preview) => preview.instalmentPreview)
        .filter((preview) => preview.paymentFrequency === paymentFrequency);
};

const findInstalmentPreview = (
    instalmentPreviews: InstalmentPreviewWrapper[],
    paymentFrequency: PaymentFrequency
): InstalmentPreview | undefined => {
    return instalmentPreviews
        .map((preview) => preview.instalmentPreview)
        .find((preview) => preview.paymentFrequency === paymentFrequency);
};

const findShortFrequency = (frequency: PaymentFrequency) => {
    switch (frequency) {
        case PaymentFrequency.WEEKLY:
            return 'wk';
        case PaymentFrequency.FORTNIGHTLY:
            return 'fn';
        case PaymentFrequency.MONTHLY:
            return 'mo';
        case PaymentFrequency.QUARTERLY:
            return 'qt';
        case PaymentFrequency.IN_FULL:
            return 'an';
    }
};

type ItemCover = {
    uuid: string;
    productCoverName: string;
    coverageAmount: number;
    instalmentPreview: InstalmentPreview;
};

const getItemCovers = (covers: Cover[], paymentFrequency: PaymentFrequency): ItemCover[] => {
    return covers
        .filter((cover) => cover.coverageAmount > 0 || cover.fixedCoverage)
        .map((cover) => {
            const instalmentPreview = findInstalmentPreview(
                cover.selectedCoverOption.instalmentPreviews,
                paymentFrequency
            );

            if (instalmentPreview == null) {
                return null; // should never happen
            }

            return {
                uuid: cover.uuid,
                productCoverName: cover.productCoverName,
                coverageAmount: cover.coverageAmount,
                instalmentPreview,
            };
        })
        .filter((cover) => cover != null) as ItemCover[];
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
