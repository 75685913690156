import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { fetchDocument, FileAction, processAction } from '../../apis/document';
import { FetchState, FetchStateType, isLoading, isSuccess, loadingState, pendingState } from '../../hooks/useFetch';
import SnackAlert, { SnackState } from '../SnackAlert';
import DocumentChipButton from './DocumentChipButton';
import DocumentIconButton from './DocumentIconButton';
import DocumentTextIconButton from './DocumentTextIconButton';

type DocumentButtonProps = {
    invoiceIdentifier: string;
    documentIdentifier: string;
    name: string;
    buttonType: 'chip' | 'button';
    fileType?: string;
};

type LoadedDocument = {
    url: string;
    size: number;
};

export default function DocumentButton({
    invoiceIdentifier,
    documentIdentifier,
    name,
    buttonType,
    fileType,
}: Readonly<DocumentButtonProps>) {
    const theme = useTheme();
    const isLargeView = useMediaQuery(theme.breakpoints.up('lg'));
    const [fetchState, setFetchState] = useState<FetchState<LoadedDocument>>(pendingState);
    const [selectedAction, setSelectedAction] = useState<FileAction>();
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackState, setSnackState] = useState<SnackState | undefined>();

    const documentFetch = (action: FileAction) => {
        if (isLoading(fetchState)) {
            return;
        }

        setSelectedAction(action);

        if (isSuccess(fetchState)) {
            processAction(action, fetchState.value.url, name);
            return;
        }

        setFetchState(loadingState);

        fetchDocument(invoiceIdentifier, documentIdentifier)
            .then((blob) => {
                if (blob) {
                    const fileUrl = URL.createObjectURL(blob);
                    setFetchState({
                        type: FetchStateType.SUCCESS,
                        value: {
                            url: fileUrl,
                            size: blob.size,
                        },
                    });
                    processAction(action, fileUrl, name);
                } else {
                    setFetchState({
                        type: FetchStateType.ERROR,
                        error: new Error('File unavailable'),
                    });
                    handleSetSnack({
                        severity: 'error',
                        msg: 'File unavailable.',
                        anchorOriginVertical: 'bottom',
                        variant: 'filled',
                    });
                }
            })
            .catch((error) => {
                setFetchState({
                    type: FetchStateType.ERROR,
                    error,
                });
                handleSetSnack({
                    severity: 'error',
                    msg: 'Something went wrong, please try again later.',
                    anchorOriginVertical: 'bottom',
                    variant: 'filled',
                });
            });
    };

    const closeSnackbar = () => {
        setSnackOpen(false);
    };

    const handleSetSnack = (state: SnackState) => {
        setSnackState(state);
        setSnackOpen(true);
    };

    return (
        <Box>
            {buttonType === 'chip' && (
                <DocumentChipButton
                    name={name}
                    fileType={fileType as string}
                    isLoading={isLoading(fetchState)}
                    onClick={documentFetch}
                />
            )}

            {buttonType === 'button' && !isLargeView && (
                <DocumentIconButton
                    selectedAction={selectedAction}
                    isLoading={isLoading(fetchState)}
                    onClick={documentFetch}
                />
            )}
            {buttonType === 'button' && isLargeView && (
                <DocumentTextIconButton
                    selectedAction={selectedAction}
                    isLoading={isLoading(fetchState)}
                    onClick={documentFetch}
                />
            )}

            <SnackAlert state={snackState} open={snackOpen} closeSnackbar={closeSnackbar} />
        </Box>
    );
}
