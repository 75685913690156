import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Box,
    Button,
    Collapse,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    Control,
    Controller,
    FieldArrayWithId,
    FieldErrors,
    FieldPath,
    useFieldArray,
    UseFieldArrayRemove,
    UseFormRegister,
    useWatch,
} from 'react-hook-form';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange } from '../../../util/dateUtils';
import CovertNumberTextField from '../../CovertNumberTextField';
import { beneficiarySchema, newBeneficiary, QuoteFields } from '../common';
import RemoveChild from './RemoveChild';

type Props = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    itemIndex: number;
};

export default function BeneficiaryFields({ control, itemIndex, register, errors }: Readonly<Props>) {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `items.${itemIndex}.beneficiaries`,
    });

    const beneficiaries = useWatch({
        control,
        name: `items.${itemIndex}.beneficiaries`,
    });

    return (
        <Box>
            <Typography variant='h2' component='p'>
                Beneficiaries
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {fields.map((field, index) => (
                    <BeneficiaryField
                        key={field.id}
                        initialValue={field}
                        beneficiaryIndex={index}
                        itemIndex={itemIndex}
                        control={control}
                        register={register}
                        errors={errors}
                        remove={remove}
                    />
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                <Button variant='text' startIcon={<Add />} onClick={() => append(newBeneficiary())}>
                    Add new beneficiary
                </Button>
                {fields && fields.length > 0 && (
                    <Box width={180}>
                        <FormLabel
                            htmlFor={`items.${itemIndex}.distributionTotal`}
                            error={!!errors.items?.[itemIndex]?.beneficiaries?.[0]?.distribution}
                        >
                            Total percentage
                        </FormLabel>
                        <TextField
                            id={`items.${itemIndex}.distributionTotal`}
                            disabled
                            value={beneficiaries
                                .reduce((a, beneficiary) => {
                                    let asFloat = parseFloat(beneficiary.distribution as unknown as string);
                                    if (Number.isNaN(asFloat)) {
                                        asFloat = 0;
                                    }
                                    return a + asFloat;
                                }, 0)
                                .toFixed(2)}
                            fullWidth
                            size='small'
                            error={!!errors.items?.[itemIndex]?.beneficiaries?.[0]?.distribution}
                            helperText={
                                errors.items?.[itemIndex]?.beneficiaries?.[0]?.distribution ? 'Must add to 100' : ''
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

type BeneficiaryFieldProps = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    remove?: UseFieldArrayRemove;
    itemIndex: number;
    beneficiaryIndex: number;
    initialValue: FieldArrayWithId<QuoteFields, `items.${number}.beneficiaries`, 'id'>;
};

function BeneficiaryField({
    itemIndex,
    beneficiaryIndex,
    control,
    register,
    errors,
    remove,
    initialValue,
}: Readonly<BeneficiaryFieldProps>) {
    const [editMode, setEditMode] = useState(!beneficiarySchema.isValidSync(initialValue));
    const fieldNameRoot: FieldPath<QuoteFields> = `items.${itemIndex}.beneficiaries.${beneficiaryIndex}`;
    const error = errors.items?.[itemIndex]?.beneficiaries?.[beneficiaryIndex];

    const beneficiary = useWatch({
        control,
        name: fieldNameRoot,
    });

    useEffect(() => {
        if (error) {
            setEditMode(true);
        }
    }, [error]);

    return (
        <Paper sx={{ backgroundColor: grey['100'], p: 2, pt: 1, pb: editMode ? 2 : 1 }} variant='outlined'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ maxWidth: '400px', display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Typography variant='subtitle2' component='p' sx={{ pb: 0 }}>
                        {beneficiary.name ? beneficiary.name : `Beneficiary ${beneficiaryIndex + 1}`}
                    </Typography>
                    {beneficiary.relationshipToPolicyHolder && (
                        <Typography variant='body2'>({beneficiary.relationshipToPolicyHolder})</Typography>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!!beneficiary.distribution && (
                        <Typography
                            variant='subtitle2'
                            component='p'
                            sx={{ pb: 0 }}
                        >{`${beneficiary.distribution}%`}</Typography>
                    )}
                    <IconButton onClick={() => setEditMode(!editMode)} color='primary'>
                        {editMode ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
            </Box>

            <Collapse in={editMode}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Divider />
                    <FormControl fullWidth required>
                        <FormLabel htmlFor={`${fieldNameRoot}.name`}>Full name</FormLabel>
                        <Controller
                            control={control}
                            name={`${fieldNameRoot}.name`}
                            defaultValue={''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register(`${fieldNameRoot}.name`)}
                                    id={`${fieldNameRoot}.name`}
                                    fullWidth
                                    size='small'
                                    error={!!error?.name}
                                    helperText={error?.name?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor={`${fieldNameRoot}.dateOfBirth`}>Date of birth</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                    name={`${fieldNameRoot}.dateOfBirth`}
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            {...register(`${fieldNameRoot}.dateOfBirth`)}
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={field.value ? moment(field.value, DATE_SERVER_FORMAT) : null}
                                            slotProps={{
                                                textField: {
                                                    id: `${fieldNameRoot}.dateOfBirth`,
                                                    fullWidth: true,
                                                    size: 'small',
                                                    helperText: error?.dateOfBirth?.message,
                                                    error: !!error?.dateOfBirth,
                                                    placeholder: undefined,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                            maxDate={moment()}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor={`${fieldNameRoot}.emailAddress`}>Email address</FormLabel>
                            <Controller
                                name={`${fieldNameRoot}.emailAddress`}
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register(`${fieldNameRoot}.emailAddress`)}
                                        id={`${fieldNameRoot}.emailAddress`}
                                        size='small'
                                        fullWidth
                                        autoComplete='no'
                                        error={!!error?.emailAddress}
                                        helperText={error?.emailAddress?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor={`${fieldNameRoot}.address`}>Address</FormLabel>
                            <Controller
                                control={control}
                                name={`${fieldNameRoot}.address`}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register(`${fieldNameRoot}.address`)}
                                        id={`${fieldNameRoot}.address`}
                                        fullWidth
                                        size='small'
                                        error={!!error?.address}
                                        helperText={error?.address?.message}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor={`${fieldNameRoot}.relationshipToPolicyHolder`}>
                                Relationship to policy holder
                            </FormLabel>
                            <Controller
                                control={control}
                                name={`${fieldNameRoot}.relationshipToPolicyHolder`}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register(`${fieldNameRoot}.relationshipToPolicyHolder`)}
                                        id={`${fieldNameRoot}.relationshipToPolicyHolder`}
                                        fullWidth
                                        size='small'
                                        error={!!error?.relationshipToPolicyHolder}
                                        helperText={error?.relationshipToPolicyHolder?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor={`${fieldNameRoot}.contactNumber`}>Contact number</FormLabel>
                            <Controller
                                name={`${fieldNameRoot}.contactNumber`}
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register(`${fieldNameRoot}.contactNumber`)}
                                        id={`${fieldNameRoot}.contactNumber`}
                                        size='small'
                                        fullWidth
                                        type='tel'
                                        autoComplete='no'
                                        error={!!error?.contactNumber}
                                        helperText={error?.contactNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor={`${fieldNameRoot}.distribution`}>Benefit amount percentage</FormLabel>
                            <Controller
                                control={control}
                                name={`${fieldNameRoot}.distribution`}
                                defaultValue={'' as unknown as number}
                                render={({ field }) => (
                                    <CovertNumberTextField
                                        {...field}
                                        {...register(`${fieldNameRoot}.distribution`)}
                                        id={`${fieldNameRoot}.distribution`}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                        }}
                                        inputProps={{
                                            step: 'any',
                                        }}
                                        fullWidth
                                        autoComplete='no'
                                        size='small'
                                        error={!!error?.distribution}
                                        helperText={error?.distribution?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    {remove && (
                        <Box>
                            <RemoveChild remove={remove} index={beneficiaryIndex} childDescription='beneficiary' />
                        </Box>
                    )}
                </Box>
            </Collapse>
        </Paper>
    );
}
