import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Box,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from '@mui/material';
import { capitalize, chain } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { ConsolidatedExpectedPayment, ExpectedPayment, Loan } from '../../../../apis/invoice';
import { ActionStatus, ActionType, cancelArrangementSetup } from '../../../../apis/variations';
import NoRecords from '../../../../components/NoRecords';
import { DATE_COMPACT } from '../../../../util/dateUtils';
import ActionDetails from './ActionDetails';

type Props = {
    invoiceUuid: string;
    loan: Loan;
};

enum ActionStatusFilter {
    ACTIVE,
    COMPLETED,
    ACTIVE_AND_COMPLETED,
}

export default function Actions({ invoiceUuid, loan }: Readonly<Props>) {
    const [actionStatusFilter, setActionStatusFilter] = useState<ActionStatusFilter>(ActionStatusFilter.ACTIVE);

    const actions = loan.actions.filter(({ status }) => {
        const showActiveOnly = actionStatusFilter === ActionStatusFilter.ACTIVE;
        const showCompletedOnly = actionStatusFilter === ActionStatusFilter.COMPLETED;
        const showBothActiveAndCompleted = actionStatusFilter === ActionStatusFilter.ACTIVE_AND_COMPLETED;

        const statusIsActive = status === ActionStatus.ACTIVE;
        const statusIsCompleted = status !== ActionStatus.ACTIVE;

        return (
            showBothActiveAndCompleted || (showActiveOnly && statusIsActive) || (showCompletedOnly && statusIsCompleted)
        );
    });

    const onCancel =
        (actionIdentifier: string) =>
        (note: string): Promise<Loan> => {
            return cancelArrangementSetup(invoiceUuid, {
                originalActionIdentifier: actionIdentifier,
                note,
            });
        };

    const cancellableActions = actions.map((action) => ({
        ...action,
        onCancel: (note: string) => onCancel(action.uuid)(note),
    }));

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Variations
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                        <ToggleButtonGroup
                            value={actionStatusFilter}
                            exclusive
                            onChange={(_evt, newActionStatusFilter) => setActionStatusFilter(newActionStatusFilter)}
                        >
                            <ToggleButton value={ActionStatusFilter.ACTIVE} data-testid='show-active-actions'>
                                Active
                            </ToggleButton>
                            <ToggleButton value={ActionStatusFilter.COMPLETED} data-testid='show-completed-actions'>
                                Completed
                            </ToggleButton>
                            <ToggleButton
                                value={ActionStatusFilter.ACTIVE_AND_COMPLETED}
                                data-testid='show-all-actions'
                            >
                                All
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box>
                        {cancellableActions.length === 0 && <NoRecords />}
                        {cancellableActions.map(({ uuid, type, createdDate, actorName, status, ...rest }) => {
                            return (
                                <StyledAccordion key={uuid}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`${uuid}-content`}
                                        id={`${uuid}-header`}
                                    >
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant='caption'>
                                                    {moment.utc(createdDate).local().format(DATE_COMPACT)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='caption'>{`${describeAction(type)} by ${actorName}`}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant='caption'>{capitalize(status)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ActionDetails data={{ uuid, type, createdDate, actorName, status, ...rest }} />
                                    </AccordionDetails>
                                </StyledAccordion>
                            );
                        })}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

const StyledAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    })
);

const describeAction = (actionType: ActionType): string => {
    switch (actionType) {
        case ActionType.DEFER_PAYMENT:
            return 'Payment holiday';
        case ActionType.SETUP_ARRANGEMENT:
            return 'Arrangement setup';
        case ActionType.CANCEL_ARRANGEMENT_SETUP:
            return 'Cancel arrangement setup';
        case ActionType.CHANGE_PAYMENT_FREQUENCY:
            return 'Change payment frequency or date';
        case ActionType.RECORD_EXTERNAL_TRANSACTION:
            return 'Add transaction';
        case ActionType.ON_DEMAND_PAYMENT:
            return 'On-demand payment';
        case ActionType.WAIVE_PAYMENT:
            return 'Payment waiver';
        default:
            return capitalize(actionType).replace(/_/g, ' ');
    }
};

export const consolidateExpectedPayments = (expectedPayments: ExpectedPayment[]): ConsolidatedExpectedPayment[] => {
    return chain(expectedPayments)
        .groupBy('originalDueDate')
        .map((expectedPaymentsOfSameOriginalDueDate, originalDueDate) => {
            const firstExpectedPayemnt = expectedPaymentsOfSameOriginalDueDate[0];
            return {
                uuid: chain(expectedPaymentsOfSameOriginalDueDate).map('uuid').join(',').value(),
                dueDate: firstExpectedPayemnt.dueDate,
                status: firstExpectedPayemnt.status,
                causedBy: firstExpectedPayemnt.causedBy,
                originalDueDate,
                amount: sumOf(expectedPaymentsOfSameOriginalDueDate, 'amount'),
                paidAmount: sumOf(expectedPaymentsOfSameOriginalDueDate, 'paidAmount'),
                expectedPayments: expectedPaymentsOfSameOriginalDueDate,
            } as ConsolidatedExpectedPayment;
        })
        .value();
};

const sumOf = (expectedPayments: ExpectedPayment[], key: string): number => {
    return chain(expectedPayments)
        .map(key)
        .reduce((sum, n) => sum + (n ?? 0), 0)
        .value();
};
