import axios from 'axios';
import { Invoice, Loan, PaymentFrequency, Policy, TermPaymentMethod } from './invoice';
import { CardPaymentProcessor } from './variations';

export const updateCpiEnabled = async (
    invoiceIdentifier: string,
    policyIdentifier: string,
    enabled: boolean
): Promise<Policy> => {
    return await axios
        .patch(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/policies/${policyIdentifier}/_action/update-cpi-increase-enabled?enabled=${enabled}`
        )
        .then(({ data }) => data);
};

export type AnnotatedTermPaymentMethod = {
    termPaymentMethod: TermPaymentMethod;
    paymentGatewayIdentifier: string;
    cardPaymentProcessor?: CardPaymentProcessor;
    directDebitAuthorityNumber?: string;
};

export const fetchTermPaymentMethods = async (invoiceIdentifier: string): Promise<AnnotatedTermPaymentMethod[]> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/payment-method`)
        .then(({ data }) => data);
};

export type AvailableTerm = {
    paymentFrequency: PaymentFrequency;
    earliestNextPaymentDate: string;
    latestNextPaymentDate: string;
};

export const fetchAvailableTerms = async (invoiceIdentifier: string): Promise<AvailableTerm[]> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/terms`)
        .then(({ data }) => data);
};

export type AdjustmentOverview = {
    current: Adjustment;
    adjusted: Adjustment;
};

export type TermPayment = {
    regularInstalmentAmount: number;
    regularInstalmentFeeAmount: number;
};

export type Adjustment = {
    nextPaymentDate: string;
    paymentFrequency: PaymentFrequency;
    termPayment: TermPayment;
};

export const queryAdjustment = async (
    invoiceIdentifier: string,
    paymentFrequency: PaymentFrequency,
    nextPaymentDate: string
): Promise<AdjustmentOverview> => {
    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/_preview/adjustment`);
    url.searchParams.append('paymentFrequency', paymentFrequency);
    url.searchParams.append('nextPaymentDate', nextPaymentDate);

    return await axios.post(url.href).then(({ data }) => data);
};

export type InvoiceLoan = {
    invoice: Invoice;
    loan: Loan;
};

export const confirmAdjustment = async (
    invoiceIdentifier: string,
    paymentFrequency: PaymentFrequency,
    nextPaymentDate: string
): Promise<InvoiceLoan> => {
    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/adjustment`);
    url.searchParams.append('paymentFrequency', paymentFrequency);
    url.searchParams.append('nextPaymentDate', nextPaymentDate);

    return await axios.patch(url.href).then(({ data }) => data);
};
