import { ExpandMore } from '@mui/icons-material';
import { Button, Chip, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import moment from 'moment';
import { ClientLeadResult, isBankTransfer } from '../../../apis/clientLead';
import { BaseClient, CreateBusinessFields, CreateIndividualFields, CreateTrustFields } from '../../../apis/clients';
import { CalculatedOn, ChargeType } from '../../../apis/invoice';
import { FetchState, isLoading, isSuccess } from '../../../hooks/useFetch';
import { DATE_DAY_MONTH, DATE_SERVER_FORMAT, DATE_TIME_FRIENDLY } from '../../../util/dateUtils';
import { FeeConfig, getFeeConfig } from '../../../util/feeUtils';
import ClientDetails from './ClientDetails';
import EditingInExpandedView from './EditingInExpandedView';
import InvoiceDetails from './InvoiceDetails';
import PaymentMethodDetails from '../../../components/PaymentMethodDetails';
import { BaseInvoiceFormFields } from '../../CreateInvoice/invoiceValidation';

type Props = {
    result: ClientLeadResult;
    setEditMode: () => void;
    editMode: boolean;
    client?: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient;
    invoiceFields?: BaseInvoiceFormFields;
    matchState: FetchState<null>;
};

export default function ClientLeadDetails({
    result,
    setEditMode,
    editMode,
    client,
    invoiceFields,
    matchState,
}: Readonly<Props>) {
    const feeConfig = getFeeConfig(
        result.invoice.term.termPaymentMethod.paymentMethodCharges,
        ChargeType.TRANSACTION_FEE
    );

    const paymentDate = moment(result.paymentDate, DATE_SERVER_FORMAT);

    return (
        <>
            {paymentDate.isAfter(moment()) && (
                <Box pb={2}>
                    <Tooltip
                        title='Settles once matched and after the first scheduled payment has been confirmed'
                        arrow
                    >
                        <Chip
                            size='small'
                            color='primary'
                            variant='outlined'
                            label={`Scheduled for ${paymentDate.format(DATE_DAY_MONTH)}`}
                        ></Chip>
                    </Tooltip>
                </Box>
            )}
            <Grid container spacing={2}>
                {isBankTransfer(result.paymentMethod) && (
                    <Grid item xs={12}>
                        <Chip color='warning' variant='outlined' size='small' label='Bank Transfer' />
                    </Grid>
                )}
                <Grid item xs={6} md={2}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='subtitle1'>
                            {moment.utc(result.invoice.paidDate).local().format(DATE_TIME_FRIENDLY)}
                        </Typography>
                        <Typography variant='caption'>
                            {currencyFormat.format(result.invoice.portfolio.premiums)}
                        </Typography>
                        <Typography variant='caption'>{result.invoice.number}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={2} lg={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Typography variant='caption'>
                            {result.clientLead.name ?? result.clientLead.emailAddress}
                        </Typography>
                        {getFeeDescription(feeConfig, result.invoice.term.initialPaymentAmount)}
                        <PaymentMethodDetails paymentMethod={result.paymentMethod} />
                    </Box>
                </Grid>
                {editMode ? (
                    <EditingInExpandedView />
                ) : (
                    <>
                        <Grid
                            item
                            xs={6}
                            md={3}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            data-testid='client-grid-cell'
                        >
                            <Typography variant='caption'>Client</Typography>
                            <Paper
                                variant='outlined'
                                sx={{ p: 1, bgcolor: grey[50], height: '100%', display: 'flex', alignItems: 'center' }}
                            >
                                <ClientDetails client={client} suggestedInsured={result.suggestedInsured} />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='caption'>Invoice</Typography>
                            <Paper
                                variant='outlined'
                                sx={{ p: 1, bgcolor: grey[50], height: '100%', display: 'flex', alignItems: 'center' }}
                            >
                                <InvoiceDetails invoiceFields={invoiceFields} />
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            lg={1}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                            }}
                        >
                            {!isLoading(matchState) && !isSuccess(matchState) && (
                                <Button onClick={setEditMode} endIcon={<ExpandMore />} size='small'>
                                    View
                                </Button>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}

const getFeeDescription = (feeConfig: FeeConfig, premiums: number): JSX.Element => {
    if (feeConfig.charge?.initialAmount) {
        const feeDescription =
            feeConfig.charge?.calculatedOn === CalculatedOn.PERCENT
                ? `${feeConfig.charge.chargeValue}%`
                : currencyFormat.format(feeConfig.charge.initialAmount);

        return (
            <Typography variant='caption'>
                {currencyFormat.format(feeConfig.upfrontPaymentFee + premiums)} inc. {feeDescription} fee
            </Typography>
        );
    } else {
        return <Typography variant='caption'>{currencyFormat.format(premiums)}</Typography>;
    }
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
