import { Chip, SxProps } from '@mui/material';
import { EnquiryStatus } from '../apis/enquiry';

type Props = {
    status: EnquiryStatus;
    customSize?: 'small' | 'medium';
    customStyle?: SxProps;
};

export default function EnquiryStatusChip({ status, customSize, customStyle }: Readonly<Props>) {
    switch (status) {
        case EnquiryStatus.IN_PROGRESS:
            return (
                <Chip
                    label='In progress'
                    color='info'
                    variant='outlined'
                    sx={customStyle}
                    size={customSize ?? 'small'}
                />
            );
        case EnquiryStatus.NEW:
            return <Chip label='New' variant='outlined' sx={customStyle} size={customSize ?? 'small'} />;
        case EnquiryStatus.ON_HOLD:
            return (
                <Chip
                    label='On hold'
                    color='warning'
                    variant='outlined'
                    sx={customStyle}
                    size={customSize ?? 'small'}
                />
            );
        case EnquiryStatus.SPAM:
            return <Chip label='Spam' variant='outlined' sx={customStyle} size={customSize ?? 'small'} />;
        case EnquiryStatus.CLOSED:
            return (
                <Chip label='Closed' color='success' variant='outlined' sx={customStyle} size={customSize ?? 'small'} />
            );
        default:
            return <Chip label='No Status' variant='outlined' sx={customStyle} size={customSize ?? 'small'} />;
    }
}
