import axios from 'axios';
import { PaymentMethod } from './clientLead';
import { Charge, ConsolidatedExpectedPayment, ExpectedPayment, Loan, PaymentFrequency } from './invoice';

export type DeferPaymentRequest = {
    expectedPayments: DeferPaymentRequestExpectedPayment[];
    note: string;
};

export type DeferPaymentRequestExpectedPayment = ConsolidatedExpectedPayment & {
    newDueDate: string;
};

const general_api_error_message = 'Something went wrong, please try again.';

export const deferPayment = async (invoiceUuid: string, deferPaymentRequest: DeferPaymentRequest): Promise<Loan> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/defer-payment`, deferPaymentRequest)
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export type SetupArrangementRequest = {
    arrangements: Arrangement[];
    note: string;
    mode: ArrangementActionMode;
};

export type CancelArrangementSetupRequest = {
    originalActionIdentifier: string;
    note: string;
};

export type RecordExternalTransactionRequest = {
    amount: number;
    transactionDate: string;
    note: string;
};

export type OndemandPaymentRequest = {
    amount?: number;
    paymentType?: OndemandPaymentType;
    note: string;
};

export type WaivePaymentRequest = {
    expectedPayments: ConsolidatedExpectedPayment[];
    note: string;
};

export type Arrangement = {
    uuid?: string;
    dueDate: string;
    amount: number;
    paidAmount?: number;
    status?: ArrangementStatus;
};

export enum ArrangementActionMode {
    STRICT = 'STRICT',
    RELAXED = 'RELAXED',
}

export enum ArrangementStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    OVERDUE = 'OVERDUE',
    CANCELLED = 'CANCELLED',
}

export enum CardPaymentProcessor {
    CYBERSOURCE = 'CYBERSOURCE',
    WINDCAVE = 'WINDCAVE',
}

export enum ExternalTransactionType {
    PAYEMENT = 'PAYEMENT',
    REFUND = 'REFUND',
}

export enum OndemandPaymentType {
    OUTSTANDING = 'OUTSTANDING',
    OVERDUE = 'OVERDUE',
}

export type Action = {
    uuid: string;
    type: ActionType;
    actorType: ActorType;
    actorName: string;
    actorIdentifier: string;
    note: string;
    status: ActionStatus;
    createdDate: string;
    cancellable: boolean;
};

export type ArrangementSetupAction = Action & {
    arrangements: Arrangement[];
};

export type DeferPaymentAction = Action & {
    expectedPayments: ExpectedPayment[];
};

export type ChangePaymentFrequencyAction = Action & {
    paymentFrequency: PaymentFrequency;
    nextPaymentDate: string;
};

export type RecordExternalTransactionAction = Action & {
    amount: number;
    transactionDate: string;
};

export type OnDemandPaymentAction = Action & {
    onDemandPaymentType: OndemandPaymentType;
    amount: number;
    transactionFeeAmount: number;
    note: string;
};

export type WaivePaymentAction = Action & {
    expectedPayments: ExpectedPayment[];
};

export const isArrangementSetupAction = (toBeDetermined?: Action): toBeDetermined is ArrangementSetupAction => {
    return toBeDetermined?.type === ActionType.SETUP_ARRANGEMENT;
};

export const isDeferPaymentAction = (toBeDetermined?: Action): toBeDetermined is DeferPaymentAction => {
    return toBeDetermined?.type === ActionType.DEFER_PAYMENT;
};

export const isChangePaymentFrequencyAction = (
    toBeDetermined?: Action
): toBeDetermined is ChangePaymentFrequencyAction => {
    return toBeDetermined?.type === ActionType.CHANGE_PAYMENT_FREQUENCY;
};

export const isRecordExternalTransactionAction = (
    toBeDetermined?: Action
): toBeDetermined is RecordExternalTransactionAction => {
    return toBeDetermined?.type === ActionType.RECORD_EXTERNAL_TRANSACTION;
};

export const isOnDemandPaymentAction = (toBeDetermined?: Action): toBeDetermined is OnDemandPaymentAction => {
    return toBeDetermined?.type === ActionType.ON_DEMAND_PAYMENT;
};

export const isWaivePaymentAction = (toBeDetermined?: Action): toBeDetermined is WaivePaymentAction => {
    return toBeDetermined?.type === ActionType.WAIVE_PAYMENT;
};

export enum ActionType {
    DEFER_PAYMENT = 'DEFER_PAYMENT',
    SETUP_ARRANGEMENT = 'SETUP_ARRANGEMENT',
    CANCEL_ARRANGEMENT_SETUP = 'CANCEL_ARRANGEMENT_SETUP',
    CHANGE_PAYMENT_FREQUENCY = 'CHANGE_PAYMENT_FREQUENCY',
    RECORD_EXTERNAL_TRANSACTION = 'RECORD_EXTERNAL_TRANSACTION',
    ON_DEMAND_PAYMENT = 'ON_DEMAND_PAYMENT',
    WAIVE_PAYMENT = 'WAIVE_PAYMENT',
}

export enum ActorType {
    SELLER_USER = 'SELLER_USER',
    CLIENT_USER = 'CLIENT_USER',
    ADMIN_USER = 'ADMIN_USER',
}

export enum ActionStatus {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}

export const setupArrangement = async (
    invoiceUuid: string,
    setupArrangementRequest: SetupArrangementRequest
): Promise<Loan> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/setup-arrangement`,
            setupArrangementRequest
        )
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export const cancelArrangementSetup = async (
    invoiceUuid: string,
    cancelArrangementSetupRequest: CancelArrangementSetupRequest
): Promise<Loan> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/cancel-arrangement-setup`,
            cancelArrangementSetupRequest
        )
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export const recordExternalTransaction = async (
    invoiceUuid: string,
    recordExternalTransactionRequest: RecordExternalTransactionRequest
): Promise<Loan> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/record-external-transaction`,
            recordExternalTransactionRequest
        )
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export const getPaymentConfiguration = async (invoiceUuid: string): Promise<PaymentConfiguration> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/payment/configuration`)
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export const addOndemandPayment = async (
    invoiceUuid: string,
    ondemandPaymentRequest: OndemandPaymentRequest
): Promise<PaymentResponse> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/payment/on-demand`,
            ondemandPaymentRequest
        )
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export const waivePayment = async (invoiceUuid: string, waivePaymentRequest: WaivePaymentRequest): Promise<Loan> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/waive-payment`, waivePaymentRequest)
        .then(({ data }) => data)
        .catch((error) => Promise.reject(error.response?.data?.message || general_api_error_message));
};

export enum PaymentStatus {
    SUCCEED = 'SUCCEED',
    PROCESSING = 'PROCESSING',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    INITIATED = 'INITIATED',
    READY_FOR_BILLING = 'READY_FOR_BILLING',
    ERROR = 'ERROR',
}

type BasePaymentResponse = {
    status: PaymentStatus;
    uuid: string;
};

type SuccessfulPaymentResponse = BasePaymentResponse & {
    loan: Loan;
};

export type PaymentResponse = BasePaymentResponse | SuccessfulPaymentResponse;

export const queryPaymentResult = async (invoiceUuid: string, paymentUuid: string): Promise<PaymentResponse> => {
    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/payment/${paymentUuid}`);
    return await axios.get(url.href).then(({ data }) => data);
};

export const isSuccessfulPaymentResponse = (
    toBeDetermined?: PaymentResponse
): toBeDetermined is SuccessfulPaymentResponse => {
    return toBeDetermined?.status === PaymentStatus.SUCCEED;
};

export type PaymentConfiguration = {
    paymentMethod: PaymentMethod;
    transactionFeeCharges: Charge[];
};
