import { Box, Chip, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { capitalize } from 'lodash';
import moment from 'moment';
import { ArrangementSetupAction } from '../../../../apis/variations';
import PaymentAmount from '../../../../components/PaymentAmount';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: ArrangementSetupAction;
};

export default function ArrangementSetupActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={6} pr={2}>
                        <Typography variant='subtitle2'>Scheduled date</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='subtitle2'>Amount</Typography>
                    </Grid>
                    <Grid item xs={3} container direction='row' justifyContent='flex-end'>
                        <Typography variant='subtitle2'>Status</Typography>
                    </Grid>
                </StyledGridHeaderContainer>

                {data.arrangements.map((arrangement) => {
                    return (
                        <StyledGridItemContainer key={arrangement.uuid}>
                            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='caption' sx={{ mr: 1 }}>
                                    {moment(arrangement.dueDate).format(DATE_FRIENDLY)}
                                </Typography>
                                <Chip size='small' label='Promise to pay' color='primary' />
                            </Grid>
                            <Grid item xs={3}>
                                <PaymentAmount amount={arrangement.amount} />
                            </Grid>
                            <Grid item xs={3} container direction='row' justifyContent='flex-end' alignItems='center'>
                                {capitalize(arrangement.status)}
                            </Grid>
                        </StyledGridItemContainer>
                    );
                })}
            </Grid>
        </Box>
    );
}
