import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormLabel,
    Paper,
    Switch,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Invoice } from '../../../apis/invoice';
import { updateCpiEnabled } from '../../../apis/policy';
import { LoadingButton } from '../../../components/LoadingButton';
import SnackAlert from '../../../components/SnackAlert';
import { FetchStateType } from '../../../hooks/useFetch';

type Props = {
    invoice: Invoice;
};

export default function PolicyOptionsCard({ invoice }: Readonly<Props>) {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [cpiIncreaseEnabled, setCpiIncreaseEnabled] = useState(invoice.portfolio.policies[0].cpiIncreaseEnabled);
    const [updateState, setUpdateState] = useState(FetchStateType.PENDING);

    const handleUpdateCPIIncreaseEnabled = () => {
        setUpdateState(FetchStateType.LOADING);
        const enabled = !cpiIncreaseEnabled;
        setCpiIncreaseEnabled(enabled);
        updateCpiEnabled(invoice.uuid, invoice.portfolio.policies[0].uuid, enabled)
            .then(() => {
                setOpenConfirmationDialog(false);
                setUpdateState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setUpdateState(FetchStateType.ERROR);
                setCpiIncreaseEnabled(!enabled);
            });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Policy Options
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel htmlFor='cpi-toggle' sx={{ pb: 0 }}>
                        <Typography variant='caption'>
                            {cpiIncreaseEnabled ? 'CPI Increase (enabled)' : 'CPI Increase (disabled)'}
                        </Typography>
                    </FormLabel>
                    <Switch
                        id='cpi-toggle'
                        disabled={!cpiIncreaseEnabled}
                        checked={cpiIncreaseEnabled}
                        onClick={() => setOpenConfirmationDialog(true)}
                        size='medium'
                    />
                    <SnackAlert
                        state={{
                            severity: 'success',
                            msg: 'CPI increase disabled successfully',
                            anchorOriginVertical: 'bottom',
                            anchoreOriginHorizontal: 'right',
                        }}
                        open={updateState === FetchStateType.SUCCESS}
                        closeSnackbar={() => setUpdateState(FetchStateType.PENDING)}
                    />
                </Box>
            </Paper>
            <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
                <DialogTitle>Confirm disabling CPI increase</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        By disabling the CPI increase, the coverage amounts will not increase by the CPI percentage when
                        this policy renews. Disabling the CPI increase is permanent and will not be reinstatable.
                    </DialogContentText>
                    {updateState === FetchStateType.ERROR && (
                        <Alert severity='error' sx={{ mt: 2 }}>
                            Something went wrong, please try again
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmationDialog(false)} color='error' variant='outlined'>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        onClick={handleUpdateCPIIncreaseEnabled}
                        loading={updateState === FetchStateType.LOADING}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
