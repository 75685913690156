import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { ClientPage, ClientSearchResult, listClients } from '../apis/clients';
import { defaultPageSize } from '../apis/common';
import { useFetch } from '../hooks/useFetch';
import { useOpenRow } from '../hooks/useOpenRow';
import { FetchWrapper } from './FetchWrapper';
import NoSearchResults from './NoSearchResults';
import { Box } from '@mui/material';

type ClientListProps = {
    query?: string;
};

const ClientList = ({ query }: ClientListProps) => {
    const { rowClick, setUrl } = useOpenRow();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);

    useEffect(() => {
        setPage(1); // when query changes always reset to page 1
    }, [query]);

    const state = useFetch(
        () => listClients({ page: page - 1, pageSize: rowsPerPage, query }),
        [page, rowsPerPage, query]
    );

    const SuccessComponent = useCallback(
        ({ data }: { data: ClientPage }) => {
            return (
                <DataTable
                    data={data.records}
                    columns={columns}
                    onRowClicked={(row: ClientSearchResult, e: React.MouseEvent) => {
                        rowClick('/clients/' + row.uuid, e);
                    }}
                    onRowMouseEnter={(row: ClientSearchResult) => {
                        setUrl('/clients/' + row.uuid);
                    }}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    pagination
                    paginationServer
                    onChangePage={setPage}
                    onChangeRowsPerPage={setRowsPerPage}
                    paginationPerPage={rowsPerPage}
                    paginationDefaultPage={page}
                    paginationTotalRows={data.totalRecords}
                    customStyles={customStyles}
                    noDataComponent={
                        <Box width='100%' p={2}>
                            <NoSearchResults />
                        </Box>
                    }
                />
            );
        },
        [page, rowsPerPage, rowClick, setUrl]
    );

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

const columns: TableColumn<ClientSearchResult>[] = [
    {
        name: 'Name',
        selector: (row) => row.displayName,
    },
    {
        name: 'Date created',
        cell: (row) => (
            <Moment data-tag='allowRowEvents' format='DD MMM YYYY hh:mm a'>
                {moment.utc(row.createdDate).local()}
            </Moment>
        ),
    },
    {
        name: 'Email',
        selector: (row) => row.email,
    },
    {
        name: 'Phone number',
        selector: (row) => row.phoneNumber,
    },
    {
        name: 'Address',
        selector: (row) => row.address,
    },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#666',
        },
        cells: {
            style: {
                fontSize: '14px',
            },
        },
    },
};

export default ClientList;
