import {
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { PolicyVersionStatus } from '../../../apis/quotes';
import { getFriendlyEnumsString } from '../../../util/stringUtils';

type Props = {
    renewalStatuses: PolicyVersionStatus[];
    setRenewalStatuses: (renewalStatus: PolicyVersionStatus[]) => void;
};

const availableRenewalStatuses = [PolicyVersionStatus.PENDING_RENEWAL, PolicyVersionStatus.READY_FOR_RENEWAL];

export default function RenewalStatusSelect({ renewalStatuses, setRenewalStatuses }: Readonly<Props>) {
    const handleRenewalStatusesChange = (event: SelectChangeEvent<typeof renewalStatuses>) => {
        const {
            target: { value },
        } = event;
        const renewalStatusSelected = (typeof value === 'string' ? value.split(',') : value) as PolicyVersionStatus[];
        setRenewalStatuses(renewalStatusSelected);
    };

    return (
        <FormControl size='small' fullWidth>
            <FormLabel htmlFor='renewal-status-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                Renewal status
            </FormLabel>
            <Select
                multiple
                value={renewalStatuses}
                displayEmpty={true}
                onChange={handleRenewalStatusesChange}
                input={<OutlinedInput id='renewal-status-multiple-checkbox' />}
                renderValue={(selected: PolicyVersionStatus[]) => renderValues(selected, getFriendlyEnumsString)}
            >
                {availableRenewalStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                        <Checkbox checked={renewalStatuses.includes(status)} />
                        <ListItemText primary={getFriendlyEnumsString(status)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const renderValues = (selected: PolicyVersionStatus[], transform: (t: PolicyVersionStatus) => string) => {
    if (selected.length === 0) {
        return 'Any';
    }

    return selected.map(transform).join(', ');
};
