import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { defaultPageSize, Direction, sortOrderToDirection } from '../../apis/common';
import { PolicyVersionStatus } from '../../apis/quotes';
import { RenewalSortProperty, RenewalSummary, searchRenewals } from '../../apis/renewal';
import ErrorMessage from '../../components/ErrorMessage';
import NoSearchResults from '../../components/NoSearchResults';
import PageLoading from '../../components/PageLoading';
import SortIcons from '../../components/SortIcons';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { isError, isLoading, isPending, isSuccess, useFetch } from '../../hooks/useFetch';
import { useOpenRow } from '../../hooks/useOpenRow';
import { getWorklistStyles } from '../../style/theme';
import { DATE_FRIENDLY } from '../../util/dateUtils';
import { getFullName } from '../../util/stringUtils';
import RenewalStatusChip from './RenewalStatusChip';

type Props = {
    renewalStatuses: PolicyVersionStatus[];
    renewalStartDate?: string;
    renewalEndDate?: string;
};

const DEBOUNCE_MS = 500;

export default function List({ renewalStatuses, renewalStartDate, renewalEndDate }: Readonly<Props>) {
    const { rowClick, setUrl } = useOpenRow();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sortProperty, setSortProperty] = useState(RenewalSortProperty.RENEWAL_DATE);
    const [direction, setDirection] = useState(Direction.ASC);

    const debouncedRenewalStatuses = useDebouncedValue(renewalStatuses, DEBOUNCE_MS);
    const debouncedRenewalStart = useDebouncedValue(renewalStartDate, DEBOUNCE_MS);
    const debouncedRenewalEnd = useDebouncedValue(renewalEndDate, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchRenewals({
                page: page - 1,
                pageSize,
                renewalSortProperty: sortProperty,
                direction,
                renewalStatuses: debouncedRenewalStatuses,
                renewalDateFrom: debouncedRenewalStart,
                renewalDateTo: debouncedRenewalEnd,
            }),
        [page, pageSize, sortProperty, direction, debouncedRenewalStatuses, debouncedRenewalStart, debouncedRenewalEnd]
    );

    const handleSort = (column: TableColumn<RenewalSummary>, sortOrder: SortOrder) => {
        setSortProperty(column.id as RenewalSortProperty);
        setDirection(sortOrderToDirection(sortOrder));
        setPage(1);
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <DataTable
            data={isSuccess(state) ? state.value.records : []}
            columns={columns}
            onRowClicked={(row: RenewalSummary, e: React.MouseEvent) => {
                rowClick('/invoices/' + row.invoiceIdentifier, e);
            }}
            onRowMouseEnter={(row: RenewalSummary) => {
                setUrl('/invoices/' + row.invoiceIdentifier);
            }}
            pointerOnHover={true}
            highlightOnHover={true}
            pagination
            paginationServer
            onSort={handleSort}
            defaultSortAsc={true}
            defaultSortFieldId={RenewalSortProperty.RENEWAL_DATE}
            sortIcon={<SortIcons />}
            sortServer
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            paginationPerPage={pageSize}
            paginationDefaultPage={page}
            paginationTotalRows={isSuccess(state) ? state.value.totalRecords : 0}
            progressPending={isLoading(state) || isPending(state)}
            progressComponent={<PageLoading />}
            noDataComponent={
                <Box width='100%'>
                    <NoSearchResults />
                </Box>
            }
            customStyles={{
                ...getWorklistStyles(sortProperty),
                rows: {
                    style: {
                        height: 65,
                    },
                },
                cells: {
                    style: {
                        wrap: true,
                    },
                },
            }}
        />
    );
}

const columns: TableColumn<RenewalSummary>[] = [
    {
        id: RenewalSortProperty.RENEWAL_POLICY_NUMBER,
        name: 'Policy number',
        sortable: true,
        cell: (row) => <EllipsisText label={row.policyNumber} />,
    },
    {
        id: RenewalSortProperty.RENEWAL_PRODUCT_NAME,
        name: 'Policy name',
        sortable: true,
        cell: (row) => <EllipsisText label={row.productName} />,
    },
    {
        name: 'Client',
        cell: (row) => <EllipsisText label={getFullName(row.clientName)} />,
    },
    {
        id: RenewalSortProperty.RENEWAL_DATE,
        name: 'Renewal date',
        sortable: true,
        cell: (row) => moment(row.renewalDate).format(DATE_FRIENDLY),
    },
    {
        id: RenewalSortProperty.RENEWAL_STATUS,
        name: 'Status',
        sortable: true,
        cell: (row) => <RenewalStatusChip status={row.renewalStatus} customStyle={{ pointerEvents: 'none' }} />,
    },
];

const EllipsisText = ({ label }: { label: string }) => {
    return (
        <Typography
            noWrap
            variant='caption'
            data-tag='allowRowEvents'
            sx={{
                textAlign: 'center',
                overflow: 'hidden',
                maxWidth: 200,
                textOverflow: 'ellipsis',
                alignContent: 'center',
            }}
        >
            {label}
        </Typography>
    );
};
