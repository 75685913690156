import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { PaymentMethodType } from '../../../apis/clientLead';
import { Charge, InvoiceDetailsResponse } from '../../../apis/invoice';
import { InvoicePaymentConfig } from '../../../apis/paymentMethod';
import { AnnotatedTermPaymentMethod } from '../../../apis/policy';
import { PaymentConfiguration } from '../../../apis/variations';
import { LoadingButton } from '../../../components/LoadingButton';
import PageLoading from '../../../components/PageLoading';
import PaymentMethodDetails from '../../../components/PaymentMethodDetails';
import StepCard from '../../../components/StepCard';
import PaymentRadioGroup, { PaymentFormFields } from './PaymentRadioGroup';
import getFormSchema from './formSchema';
import submitDDPaymentMethod from './submitDDPaymentMethod';

export type UpdateStepProps = {
    cancel: () => void;
    setUpdatedModels: (invoicePaymentConfig: InvoicePaymentConfig) => void;
    invoiceDetails: InvoiceDetailsResponse;
    paymentConfiguration: PaymentConfiguration;
};

type Props = UpdateStepProps & {
    data: AnnotatedTermPaymentMethod[];
};

export default function UpdateStep({
    cancel,
    invoiceDetails: { invoice },
    setUpdatedModels,
    paymentConfiguration,
    data: availablePaymentMethods,
}: Readonly<Props>) {
    const [loadingRes, setLoadingRes] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const firstPolicyClasscode = invoice.portfolio.policies[0].currentPolicyVersion.productCode;
    const { charges, paymentMethods } = getChargesAndPaymentMethods(availablePaymentMethods);

    const {
        control,
        setError,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<PaymentFormFields>({
        resolver: yupResolver(getFormSchema()) as Resolver<PaymentFormFields>,
    });

    const submitHandler: SubmitHandler<PaymentFormFields> = (data) => {
        submitDDPaymentMethod({
            bankAccount: data,
            invoiceUuid: invoice.uuid,
            setLoadingRes,
            setError,
            setUpdatedModels,
            setFormError,
        });
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <StepCard>
                <Box>
                    <Typography variant='h5' component='h2'>
                        Change payment method
                    </Typography>
                    <Typography variant='body2'>
                        Insurance #{invoice.portfolio.policies[0].number}
                        {firstPolicyClasscode ? ` (${firstPolicyClasscode})` : ''}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant='h6' component='h3'>
                        Current payment method
                    </Typography>
                    <Typography variant='subtitle2' component='p'>
                        {invoice.term.termPaymentMethod.paymentMethodType === PaymentMethodType.DIRECT_DEBIT
                            ? 'Direct debit'
                            : 'Card'}
                    </Typography>
                    <PaymentMethodDetails paymentMethod={paymentConfiguration.paymentMethod} reversed />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant='h6' component='h3'>
                        New payment method
                    </Typography>
                    {formError && <Alert severity='error'>{formError}</Alert>}

                    {loadingRes ? (
                        <PageLoading />
                    ) : (
                        <PaymentRadioGroup
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            errors={errors}
                            charges={charges}
                            paymentMethods={paymentMethods}
                            invoice={invoice}
                        />
                    )}
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <LoadingButton loading={loadingRes} variant='contained' color='primary' type='submit' size='large'>
                        Confirm
                    </LoadingButton>
                    <Button disabled={loadingRes} onClick={cancel} variant='text' color='primary' size='large'>
                        Cancel
                    </Button>
                </Box>
            </StepCard>
        </form>
    );
}

export type Charges = {
    card?: number;
    directDebit?: number;
};

export type PaymentMethods = {
    cardPaymentMethod?: AnnotatedTermPaymentMethod;
    directDebitPaymentMethod?: AnnotatedTermPaymentMethod;
};

export type ChargesAndPaymentMethods = {
    charges: Charges;
    paymentMethods: PaymentMethods;
};

const getChargesAndPaymentMethods = (
    availablePaymentMethods: AnnotatedTermPaymentMethod[]
): ChargesAndPaymentMethods => {
    const charges: Charges = {};
    const cardPaymentMethod = availablePaymentMethods.find(
        (method) => method.termPaymentMethod.paymentMethodType === PaymentMethodType.CREDIT_CARD
    );

    const directDebitPaymentMethod = availablePaymentMethods.find(
        (method) => method.termPaymentMethod.paymentMethodType === PaymentMethodType.DIRECT_DEBIT
    );
    if (directDebitPaymentMethod) {
        charges.directDebit = calculateChargeAmount(directDebitPaymentMethod.termPaymentMethod.paymentMethodCharges);
    }

    const paymentMethods: PaymentMethods = { cardPaymentMethod, directDebitPaymentMethod };
    return { charges, paymentMethods };
};

const calculateChargeAmount = (charges: Charge[]): number => {
    return charges.reduce((a, charge) => a + charge.amount, 0);
};
