import { Box, Chip, Dialog, Divider, IconButton, Link, Paper, Typography } from '@mui/material';
import { Address, ContactDetails } from '../../../types/Types';
import FormattedAddress from '../../../components/FormattedAddress';
import AddressForm from './AddressForm';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Client } from '../../../apis/clients';
import { capitalize } from 'lodash';

type Props = {
    addresses: Address[];
    client: Client;
    updateClientContactDetails: (contactDetails: ContactDetails) => void;
};

export default function Addresses({ addresses, client, updateClientContactDetails }: Readonly<Props>) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({} as Address);

    const toggleModal = () => {
        setSelectedAddress({} as Address);
        setOpenModal(!openModal);
    };

    const editAddress = (address: Address) => {
        setSelectedAddress(address);
        setOpenModal(true);
    };

    const updateAddress = (contactDetails: ContactDetails) => {
        updateClientContactDetails(contactDetails);
        toggleModal();
    };

    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                    <Typography variant='h6' component='h2'>
                        Address
                    </Typography>
                </Box>
                <Link href='#' onClick={toggleModal}>
                    Add
                </Link>
            </Box>
            <Paper variant='flat' sx={{ p: 2 }}>
                {!addresses || addresses.length === 0 ? (
                    <Typography variant='body2'>No address</Typography>
                ) : (
                    addresses.map((address, index) => {
                        return (
                            <Box key={address.uuid}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 2,
                                        mb: 2,
                                    }}
                                >
                                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                        <Typography variant='body2'>
                                            <FormattedAddress address={address} />
                                            <br />
                                            Type: {capitalize(address.addressType)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {address.preferred && (
                                            <Chip size='small' label='Preferred' variant='filled' sx={{ mr: 3 }} />
                                        )}

                                        <IconButton
                                            color='primary'
                                            aria-label='Edit address'
                                            onClick={() => editAddress(address)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Box>
                                </Box>
                                {addresses.length - 1 !== index && <Divider />}
                            </Box>
                        );
                    })
                )}
            </Paper>
            <Dialog open={openModal} onClose={toggleModal}>
                <AddressForm client={client} callbackAddressUpdate={updateAddress} address={selectedAddress} />
            </Dialog>
        </Box>
    );
}
