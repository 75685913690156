import { Person, Search } from '@mui/icons-material';
import {
    Avatar,
    Checkbox,
    FormControl,
    FormLabel,
    InputAdornment,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { isError } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { UNASSIGNED_FLAG } from '../../../apis/enquiry';
import { Permission, searchUsersByNameWithPermission, UserSearchResult } from '../../../apis/users';
import ErrorMessage from '../../../components/ErrorMessage';
import useDebouncedValue from '../../../hooks/useDebouncedValue';
import { isSuccess, useFetch } from '../../../hooks/useFetch';
import { getMenuPropsStyle } from '../../../style/theme';
import { firstCharsInString, getFullName } from '../../../util/stringUtils';

type Props = {
    assigneesSelected: UserSearchResult[];
    setAssigneesSelected: (assigneeIdentifiers: UserSearchResult[]) => void;
};
const DEBOUNCE_MS = 500;

export default function EnquiryAssigneeSelect({ assigneesSelected, setAssigneesSelected }: Readonly<Props>) {
    const theme = useTheme();
    const searchBar = useRef<HTMLInputElement>(null);
    const [focusInput, setFocusInput] = useState<boolean>(false);
    const [assigneeSearchString, setAssigneeSearchString] = useState<string | undefined>('');
    const [assigneeSearchResult, setAssigneeSearchResult] = useState<UserSearchResult[]>([]);

    const debouncedAssigneeSearchString = useDebouncedValue(assigneeSearchString, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchUsersByNameWithPermission({
                page: 0,
                pageSize: 20,
                permission: Permission.ENQUIRY_UPDATE,
                searchString: debouncedAssigneeSearchString,
            }),
        [debouncedAssigneeSearchString]
    );

    useEffect(() => {
        if (isSuccess(state)) {
            setAssigneeSearchResult(state.value.records);
        }
    }, [state]);

    const handleUserSelectChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;

        // Parse the selected usernames
        const selected = new Set(typeof value === 'string' ? value.split(',') : value);

        // Keep selected assignees only
        const updatedList = assigneesSelected.filter((assignee) => selected.has(assignee.username));

        // Add newly selected assignee
        selected.forEach((username) => {
            if (assigneesSelected.findIndex((assignee) => assignee.username === username) < 0) {
                const assignee = assigneeSearchResult.find((assignee) => assignee.username === username);
                if (assignee) {
                    updatedList.push(assignee);
                }
            }
        });

        // Handle the "Unassigned" flag
        if (
            selected.has(UNASSIGNED_FLAG) &&
            assigneesSelected.findIndex((assignee) => assignee.username === UNASSIGNED_FLAG) < 0
        ) {
            updatedList.push({ username: UNASSIGNED_FLAG } as UserSearchResult);
        }

        setAssigneesSelected(updatedList);
    };

    const onChangeExpression = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value.toString();
        setAssigneeSearchString(value);
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <FormControl size='small' fullWidth>
            <FormLabel htmlFor='enquiry-assignee-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                Assigned to
            </FormLabel>
            <Select
                multiple
                displayEmpty={true}
                defaultValue={assigneesSelected.map((assignee) => assignee.username)}
                value={assigneesSelected.map((assignee) => assignee.username)}
                onChange={handleUserSelectChange}
                input={<OutlinedInput id='enquiry-assignee-multiple-checkbox' />}
                renderValue={() => renderValues(assigneesSelected, getFriendlyUserName)}
                MenuProps={MenuProps}
                onOpen={() => {
                    setFocusInput(true);
                }}
                onClose={() => {
                    setFocusInput(false);
                }}
            >
                <MenuItem
                    value=''
                    style={{ backgroundColor: grey[100] }}
                    sx={{ p: 2 }}
                    onFocus={() => {
                        if (focusInput) {
                            searchBar.current?.focus({ preventScroll: true });
                        } else {
                            searchBar.current?.blur();
                        }
                    }}
                    onBlur={() => {
                        if (focusInput) {
                            searchBar.current?.focus({ preventScroll: true });
                        } else {
                            searchBar.current?.blur();
                        }
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                    }}
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <TextField
                        inputRef={searchBar}
                        value={assigneeSearchString}
                        focused={focusInput}
                        placeholder='Search'
                        onChange={onChangeExpression}
                        fullWidth
                        size='small'
                        style={{ backgroundColor: grey[100] }}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </MenuItem>

                <MenuItem value={UNASSIGNED_FLAG} sx={{ height: 50, px: 2 }}>
                    <Checkbox
                        checked={assigneesSelected.findIndex((assignee) => assignee.username === UNASSIGNED_FLAG) > -1}
                    />
                    <Avatar
                        sx={{ bgcolor: grey[200], width: 30, height: 30, whiteSpace: 'nowrap', ml: 1, mr: 1 }}
                        style={{ fontSize: 12 }}
                    >
                        <Person sx={{ color: grey[500] }} />
                    </Avatar>
                    <ListItemText primary={UNASSIGNED_FLAG} />
                </MenuItem>
                {assigneeSearchResult.map((user: UserSearchResult) => (
                    <MenuItem key={user.uuid} value={user.username} sx={{ height: 50, px: 2 }}>
                        <Checkbox
                            checked={
                                assigneesSelected.findIndex((assignee) => assignee.username === user.username) > -1
                            }
                        />
                        <Avatar
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                width: 30,
                                height: 30,
                                whiteSpace: 'nowrap',
                                mx: 1,
                            }}
                            style={{ fontSize: 12 }}
                        >
                            {firstCharsInString(user.name)}
                        </Avatar>
                        <ListItemText primary={user.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const renderValues = (assigneesSelected: UserSearchResult[], transform: (username: string, name: string) => string) => {
    if (assigneesSelected.length === 0) {
        return 'Any';
    }

    return assigneesSelected.map((assignee) => transform(assignee.username, assignee.name)).join(', ');
};

const getFriendlyUserName = (username: string, name: string): string => {
    if (username === UNASSIGNED_FLAG) {
        return UNASSIGNED_FLAG;
    }

    return getFullName(name);
};

const MenuProps = {
    PaperProps: getMenuPropsStyle().PaperProps,
    MenuListProps: getMenuPropsStyle().MenuListProps,
};
