import { Box, Chip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment';
import { InvoiceDetailsResponse, Policy } from '../../../apis/invoice';
import { PolicyVersion, PolicyVersionStatus } from '../../../apis/quotes';
import { SellerProduct } from '../../../apis/sellerProduct';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { quoteableInvoice } from '../common';
import PolicyActionMenu from './PolicyActionMenu';

type Props = {
    policyVersion: PolicyVersion;
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
    policy: Policy;
};

export default function PolicyHeader({ policyVersion, sellerProduct, invoiceDetails, policy }: Readonly<Props>) {
    const { invoiceUpdateAllowed } = useAppSelector((root) => root.UserSessionReducer).permissions;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box>
                <Typography variant='subtitle1'>Version</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant='h6'>
                        {moment(policyVersion.activeDate ?? policyVersion.anniversaryDate, DATE_SERVER_FORMAT).format(
                            DATE_FRIENDLY
                        )}
                    </Typography>
                    {getVersionStatusChip(policyVersion.status)}
                </Box>
                <Box sx={{ mt: 1, display: 'flex', gap: 3 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography variant='body2'>Term: </Typography>
                        <Typography variant='caption'>
                            {moment(policyVersion.anniversaryDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)} -{' '}
                            {moment(policyVersion.endDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography variant='body2'>Created from:</Typography>
                        <Typography variant='caption'>{capitalize(policyVersion.reason)}</Typography>
                    </Box>
                </Box>
            </Box>
            {quoteableInvoice(sellerProduct, invoiceDetails.loan) && invoiceUpdateAllowed && (
                <Box>
                    <PolicyActionMenu policy={policy} sellerProduct={sellerProduct} invoiceDetails={invoiceDetails} />
                </Box>
            )}
        </Box>
    );
}

const getVersionStatusChip = (status: PolicyVersionStatus) => {
    switch (status) {
        case PolicyVersionStatus.PENDING_RENEWAL:
            return <Chip size='small' label='Pending renewal' color='warning' variant='outlined' />;
        case PolicyVersionStatus.READY_FOR_RENEWAL:
            return <Chip size='small' label='Ready for renewal' color='info' variant='outlined' />;
        case PolicyVersionStatus.RENEWAL_PROCESSING:
            return <Chip size='small' label='Renewal processing' color='success' variant='outlined' />;
        default:
            return null;
    }
};
