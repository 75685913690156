import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { InvoiceDetailsResponse, LoanStatus, Policy } from '../../apis/invoice';
import { Cover, CoverSet, Item, PolicyVersion, QuoteStatus } from '../../apis/quotes';
import { SellerProduct } from '../../apis/sellerProduct';
import { useAppSelector } from '../../store/reducer/Hooks';
import { DATE_COMPACT, DATE_FRIENDLY } from '../../util/dateUtils';
import CreateQuoteSteps from './CreateQuoteSteps';

type Props = {
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
    policy: Policy;
    policyVersion: PolicyVersion;
};

const RESUMABLE_QUOTE_STATUSES = [QuoteStatus.DRAFT, QuoteStatus.QUOTED];

export default function QuoteTile({ invoiceDetails, policy, policyVersion, sellerProduct }: Readonly<Props>) {
    const [openQuoteModal, setOpenQuoteModal] = useState(false);
    const createdDate = moment.utc(policyVersion.createdDate).local().format(DATE_FRIENDLY);
    const modifiedDate = moment.utc(policyVersion.lastModifiedDate).local().format(DATE_FRIENDLY);
    const policyIsActive =
        moment(policy.currentPolicyVersion.endDate).startOf('day').isAfter(moment().startOf('day')) &&
        invoiceDetails?.loan?.status === LoanStatus.OPEN;
    const { invoiceUpdateAllowed } = useAppSelector((root) => root.UserSessionReducer).permissions;

    return (
        <Paper
            variant='flat'
            sx={{
                p: 2,
                mb: 4,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography variant='h6'>
                        {policy.number} {policyVersion.version}
                    </Typography>
                    <PolicyVersionChip policyVersion={policyVersion} />
                </Box>
                <Typography variant='body2'>
                    Created {createdDate}
                    {createdDate !== modifiedDate && ` (Last saved ${modifiedDate})`}
                </Typography>
                <Typography variant='body2'>
                    Effective date: {moment.utc(policyVersion.effectiveDate).local().format(DATE_FRIENDLY)}
                </Typography>
            </Box>
            <Box>
                {RESUMABLE_QUOTE_STATUSES.includes(policyVersion.quote!.status) &&
                    policyIsActive &&
                    invoiceUpdateAllowed && (
                        <Button variant='outlined' onClick={() => setOpenQuoteModal(true)}>
                            Resume
                        </Button>
                    )}
                <CreateQuoteSteps
                    policy={policy}
                    sellerProduct={sellerProduct}
                    open={openQuoteModal}
                    setClosed={() => setOpenQuoteModal(false)}
                    invoiceDetails={invoiceDetails}
                    policyVersion={cleanupPolicy(policyVersion)}
                />
            </Box>
        </Paper>
    );
}

const cleanupPolicy = (policyVersion: PolicyVersion): PolicyVersion => {
    const request: Partial<PolicyVersion> = cloneDeep(policyVersion);

    request.instalmentPreviews = undefined;
    request.items?.forEach((item: Partial<Item>) => {
        // TODO: possibly don't need
        item.coverSets?.forEach((coverSet: Partial<CoverSet>) => {
            coverSet.covers?.forEach((cover: Partial<Cover>) => {
                cover.coverOptions = [];
                cover.selectedCoverOption = undefined;
            });
        });
    });

    return request as PolicyVersion;
};

type PolicyVersionChipProps = {
    policyVersion: PolicyVersion;
};
const PolicyVersionChip = ({ policyVersion }: Readonly<PolicyVersionChipProps>) => {
    switch (policyVersion.quote!.status) {
        case QuoteStatus.DRAFT:
            return <Chip size='small' variant='outlined' color='default' label='Not accepted' />;
        case QuoteStatus.QUOTED:
            return <Chip size='small' variant='outlined' color='primary' label='Quoted' />;
        case QuoteStatus.ACCEPTED:
            return (
                <Chip
                    size='small'
                    variant='outlined'
                    color='success'
                    label={`Accepted on ${moment.utc(policyVersion.activeDate).local().format(DATE_COMPACT)}`}
                />
            );
        case QuoteStatus.CANCELLED:
            return <Chip size='small' variant='outlined' color='error' label='Declined' />;
    }
};
