import * as yup from 'yup';

export default function getFormSchema() {
    return yup.object({
        accountHolder: yup.string().trim().required('Account holder name required'),
        bankAccountNumber: yup
            .string()
            .trim()
            .required('Bank account number required')
            .matches(/^\d{2}-\d{4}-\d{7}-\d{2,3}$/, 'Bank account number format is invalid'),
    });
}
