import { HelpOutlined } from '@mui/icons-material';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { defaultPageSize } from '../apis/common';
import { UserPage, UserSearchResult, listUsers } from '../apis/users';
import { getCurrentUsername, translatePermission } from '../helpers/permission';
import { useFetch } from '../hooks/useFetch';
import { useOpenRow } from '../hooks/useOpenRow';
import { DATE_FRIENDLY } from '../util/dateUtils';
import { FetchWrapper } from './FetchWrapper';
import NoRecords from './NoRecords';

interface UserListDataProps {
    userPage: UserPage;
    currentUsername: string;
}

const UserList = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const { rowClick, setUrl } = useOpenRow();

    const onRowClicked = useCallback(
        (row: UserSearchResult, e: React.MouseEvent) => {
            rowClick('/users/' + row.uuid, e);
        },
        [rowClick]
    );

    const onRowMouseEnter = useCallback(
        (row: UserSearchResult) => {
            setUrl('/users/' + row.uuid);
        },
        [setUrl]
    );

    const state = useFetch(async () => {
        const userPage = await listUsers({ page: page - 1, pageSize: rowsPerPage });
        const currentUsername = await getCurrentUsername();

        return { userPage, currentUsername };
    }, [page, rowsPerPage]);

    const SuccessComponent = useCallback(
        ({ data }: { data: UserListDataProps }) => {
            const { userPage, currentUsername } = data;
            return (
                <DataTable
                    data={userPage.records.map((record) => ({ currentUsername, ...record }))}
                    columns={columns}
                    onRowClicked={onRowClicked}
                    onRowMouseEnter={onRowMouseEnter}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    pagination
                    paginationServer
                    onChangePage={setPage}
                    onChangeRowsPerPage={setRowsPerPage}
                    paginationPerPage={rowsPerPage}
                    paginationDefaultPage={page}
                    paginationTotalRows={userPage.totalRecords}
                    customStyles={customStyles}
                    noDataComponent={<NoRecords />}
                />
            );
        },
        [page, rowsPerPage, onRowClicked, onRowMouseEnter, rowClick, setUrl]
    );

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

type EnhancedUserSearchResult = UserSearchResult & {
    currentUsername: string;
};

const columns: TableColumn<EnhancedUserSearchResult>[] = [
    {
        name: 'User name',
        maxWidth: '160px',
        cell: (row) => (
            <>
                {row.name}
                {row.username !== row.currentUsername ? (
                    <></>
                ) : (
                    <Chip color='success' label='You' size='small' sx={{ p: 0, ml: 1, pointerEvents: 'none' }} />
                )}
            </>
        ),
    },
    {
        name: 'Date added',
        maxWidth: '120px',
        cell: (row) => (
            <Moment data-tag='allowRowEvents' format={DATE_FRIENDLY}>
                {moment.utc(row.createdDate).local()}
            </Moment>
        ),
    },
    {
        name: 'Email',
        selector: (row) => row.email,
    },
    {
        name: 'Groups',
        cell: (row) => {
            if (isEmpty(row.groups)) {
                return '-';
            }

            const sortedGroups = [...row.groups].sort((g1, g2) =>
                g1.name.toLowerCase().localeCompare(g2.name.toLowerCase())
            );

            const few = 2;
            const firstFewGroups = sortedGroups
                .slice(0, few)
                .map((permissionGroup) => permissionGroup.name)
                .join(', ');

            if (row.groups.length <= few) {
                return firstFewGroups;
            }

            const message = `${firstFewGroups}, +${row.groups.length - few} more`;
            const tooltip = sortedGroups.map((permissionGroup) => permissionGroup.name).join(', ');
            return (
                <>
                    {message}
                    <Tooltip title={tooltip}>
                        <IconButton>
                            <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
    {
        name: 'Additional Permissions',
        cell: (row) => {
            if (isEmpty(row.permissions)) {
                return '-';
            }

            const sortedTranslatedPermissions = row.permissions
                .map((permission) => ({ permission, ...translatePermission(permission) }))
                .sort((p1, p2) => p1.permissionName.localeCompare(p2.permissionName));

            const few = 1;
            const firstFewPermissions = sortedTranslatedPermissions
                .slice(0, few)
                .map(({ permissionName }) => permissionName)
                .join(', ');

            if (row.permissions.length <= few) {
                return firstFewPermissions;
            }

            const message = `${firstFewPermissions}, +${row.permissions.length - few} more`;
            const tooltip = sortedTranslatedPermissions.map(({ permissionName }) => permissionName).join(', ');
            return (
                <>
                    {message}
                    <Tooltip title={tooltip}>
                        <IconButton>
                            <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#666',
        },
        cells: {
            style: {
                fontSize: '14px',
            },
        },
    },
};

export default UserList;
