import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Save } from '@mui/icons-material';
import {
    Box,
    FormControl,
    FormLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    ActivityType,
    ActivityVariation,
    Contact,
    ContactType,
    createActivityRequest,
    OutcomeType,
} from '../../../../apis/enquiry';
import { LoadingButton } from '../../../../components/LoadingButton';
import { SnackState } from '../../../../components/SnackAlert';
import {
    DATE_COMPACT_TIME_FORMAT,
    DATE_TIME_SERVER_FORMAT,
    handleDatePickerChange,
    invalidDate,
} from '../../../../util/dateUtils';

type ContactFormProps = {
    enquiryIdentifier: string;
    enquiryContactDate: string;
    closeDrawer: () => void;
    handleSetSnack: (state: SnackState) => void;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
};

type FormFields = {
    contactType: ContactType;
    contactDateTime: Date;
    noteDetails: string;
    outcomeType?: OutcomeType;
};

const ContactForm = ({
    enquiryIdentifier,
    enquiryContactDate,
    closeDrawer,
    handleSetSnack,
    addNewActivityCallback,
}: ContactFormProps) => {
    const [saving, setSaving] = useState(false);

    const {
        handleSubmit,
        register,
        watch,
        control,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: yupResolver(getSchema(moment.utc(enquiryContactDate).local())),
    });
    const contactType = watch('contactType');
    const outcomeType = watch('outcomeType');

    const onSubmit: SubmitHandler<FormFields> = (data) => {
        setSaving(true);

        const requestBody = {
            activityType: ActivityType.CONTACT,
            contactType: data.contactType,
            outcomeType: data.contactType === ContactType.CALL_OUTBOUND ? data.outcomeType : null,
            contactDateTime: moment(data.contactDateTime).utc().format(DATE_TIME_SERVER_FORMAT),
            noteDetails: data.noteDetails,
        } as Contact;

        createActivityRequest(enquiryIdentifier, requestBody)
            .then((newActivity) => {
                if (addNewActivityCallback) {
                    addNewActivityCallback(newActivity);
                }
                setSaving(false);
                closeDrawer();
            })
            .catch(() => {
                setSaving(false);
                handleSetSnack({
                    severity: 'error',
                    msg: 'Something went wrong, please try again.',
                    anchorOriginVertical: 'bottom',
                    variant: 'filled',
                });
            });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
                <Typography variant='h2'>Log contact</Typography>
                <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth required sx={{ py: 1 }}>
                        <FormLabel htmlFor='contactDateTime'>Date of contact</FormLabel>
                        <Controller
                            name='contactDateTime'
                            control={control}
                            render={({ field }) => {
                                return (
                                    <DateTimePicker
                                        {...field}
                                        {...register('contactDateTime')}
                                        onChange={handleDatePickerChange(field, DATE_TIME_SERVER_FORMAT)}
                                        onAccept={handleDatePickerChange(field, DATE_TIME_SERVER_FORMAT)}
                                        value={
                                            isEmpty(field.value)
                                                ? moment().local()
                                                : moment(field.value, DATE_TIME_SERVER_FORMAT)
                                        }
                                        slotProps={{
                                            textField: {
                                                id: 'contactDateTime',
                                                fullWidth: true,
                                                size: 'small',
                                                helperText: errors?.contactDateTime?.message,
                                                error: !!errors?.contactDateTime,
                                                placeholder: undefined,
                                            },
                                        }}
                                        format={DATE_COMPACT_TIME_FORMAT}
                                        minDateTime={moment.utc(enquiryContactDate).local()}
                                        maxDateTime={moment.utc().local()}
                                        viewRenderers={{
                                            hours: null,
                                            minutes: null,
                                            seconds: null,
                                        }}
                                    />
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth required sx={{ py: 2 }}>
                        <FormLabel htmlFor='contactType'>Contact type</FormLabel>
                        <Controller
                            name='contactType'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    {...register('contactType')}
                                    input={<OutlinedInput id='contactType' />}
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    value={contactType ?? ''}
                                    label='Type'
                                >
                                    {Object.values(ContactType).map((contactType, index) => {
                                        return (
                                            <MenuItem key={`contactType-${index}`} value={contactType}>
                                                {getFriendlyContactTypes(contactType)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        />
                    </FormControl>
                    {contactType === ContactType.CALL_OUTBOUND && (
                        <FormControl fullWidth required={contactType === ContactType.CALL_OUTBOUND} sx={{ py: 2 }}>
                            <FormLabel htmlFor='outcomeType'>Outcome</FormLabel>
                            <Controller
                                name='outcomeType'
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        {...register('outcomeType')}
                                        input={<OutlinedInput id='outcomeType' />}
                                        fullWidth
                                        size='small'
                                        variant='standard'
                                        value={outcomeType ?? ''}
                                        label='Type'
                                    >
                                        {Object.values(OutcomeType).map((outcomeType, index) => {
                                            return (
                                                <MenuItem key={`outcomeType-${index}`} value={outcomeType}>
                                                    {getFriendlyOutcomeTypes(outcomeType)}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    )}
                    <FormControl fullWidth required sx={{ py: 2 }}>
                        <FormLabel>Additional information</FormLabel>
                        <Controller
                            control={control}
                            name='noteDetails'
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    multiline
                                    rows={5}
                                    autoComplete='no'
                                    error={errors.noteDetails != undefined}
                                    helperText={errors.noteDetails?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ width: '50%', alignSelf: 'flex-end' }}>
                            <LoadingButton
                                type='submit'
                                variant='contained'
                                fullWidth
                                size='large'
                                startIcon={<Save />}
                                loading={saving}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Box>
                </form>
            </Paper>
        </LocalizationProvider>
    );
};

const getFriendlyOutcomeTypes = (outcomeType: OutcomeType) => {
    switch (outcomeType) {
        case OutcomeType.COMPLETED_CALL:
            return 'Completed call';
        case OutcomeType.LEFT_VOICEMAIL:
            return 'Left voicemail';
        case OutcomeType.CALL_FAILED:
            return 'Call failed';
        case OutcomeType.CALL_ATTEMPTED_NO_ANSWER:
            return 'Call attempted (no answer)';
        case OutcomeType.CALL_DISCONNECTED:
            return 'Call disconnected';
        case OutcomeType.BUSY_SIGNAL:
            return 'Busy signal';
        default:
            return 'Other';
    }
};

const getFriendlyContactTypes = (contactType: ContactType) => {
    switch (contactType) {
        case ContactType.CALL_OUTBOUND:
            return 'Call outbound';
        case ContactType.CALL_INBOUND:
            return 'Call inbound';
        case ContactType.SMS:
            return 'SMS';
        case ContactType.EMAIL:
            return 'Email';
        default:
            return 'UNKNOWN';
    }
};

const getSchema = (minContactDate: Moment) => {
    return yup.object({
        contactType: yup.string().required('Contact type required'),
        contactDateTime: yup
            .date()
            .transform((value) => (value ? value : moment().local().toDate()))
            .typeError('Contact date required')
            .required('Contact date required')
            .test('checkDateOutOfRange', 'Contact date must be between enquiry created date and today', (value) => {
                const targetDate = minContactDate && !invalidDate(minContactDate) ? minContactDate : moment().local();
                return !!(
                    value &&
                    !invalidDate(value) &&
                    moment(value).isAfter(targetDate) &&
                    moment(value).isBefore(moment().format(DATE_TIME_SERVER_FORMAT))
                );
            }),
        noteDetails: yup.string().required('Contact note required'),
        outcomeType: yup
            .string()
            .when('contactType', (contactType, outcomeType) =>
                contactType === ContactType.CALL_OUTBOUND ? outcomeType.required() : outcomeType
            ),
    });
};

export default ContactForm;
