import { Box } from '@mui/material';
import InvoiceDocumentList from './InvoiceDocumentList';

type Props = {
    invoiceIdentifier: string;
};

export default function InvoiceDocuments({ invoiceIdentifier }: Readonly<Props>) {
    return (
        <Box>
            <InvoiceDocumentList invoiceIdentifier={invoiceIdentifier} />
        </Box>
    );
}
