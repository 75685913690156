import { Download, VisibilityOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Divider, IconButton, useTheme } from '@mui/material';
import { FileAction } from '../../apis/document';

type DocumentIconButtonProps = {
    selectedAction?: FileAction;
    isLoading: boolean;
    onClick: (action: FileAction) => void;
};

export default function DocumentIconButton({ selectedAction, isLoading, onClick }: Readonly<DocumentIconButtonProps>) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <IconButton disabled={isLoading} onClick={() => onClick(FileAction.VIEW)}>
                {isLoading && selectedAction === FileAction.VIEW ? (
                    <CircularProgress color='inherit' size={20} data-testid='loadingSpinner' />
                ) : (
                    <VisibilityOutlined sx={{ color: isLoading ? 'inherit' : theme.palette.primary.main }} />
                )}
            </IconButton>
            <Divider sx={{ mx: 0.5 }} />
            <IconButton disabled={isLoading} onClick={() => onClick(FileAction.DOWNLOAD)}>
                {isLoading && selectedAction === FileAction.DOWNLOAD ? (
                    <CircularProgress color='inherit' size={20} data-testid='loadingSpinner' />
                ) : (
                    <Download sx={{ color: isLoading ? 'inherit' : theme.palette.primary.main }} />
                )}
            </IconButton>
        </Box>
    );
}
