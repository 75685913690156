import { NavigateNext } from '@mui/icons-material';
import { Avatar, Box, Paper, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { InvoiceSearchResult } from '../../../apis/invoice';
import InvoiceStatusChip from '../../../components/InvoiceStatusChip';
import ButtonBase from '@mui/material/ButtonBase';
import { getInitials } from '../../../util/policyUtils';

type Props = {
    invoice: InvoiceSearchResult;
    sellerProductLogo?: string;
    uuid: string;
};

export default function PolicyDetail({ invoice, sellerProductLogo, uuid }: Readonly<Props>) {
    const initials = useMemo(() => getInitials(invoice.productCode), [invoice]);

    return (
        <ButtonBase component={Link} to={'/invoices/' + uuid}>
            <Paper
                variant='flat'
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: grey[300],
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    p: 3,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {sellerProductLogo ? (
                        <img
                            src={sellerProductLogo}
                            style={{ width: '60px', height: '60px' }}
                            alt='Seller Product logo'
                        />
                    ) : (
                        <Avatar sx={{ bgcolor: blue['200'], width: '60px', height: '60px' }}>{initials}</Avatar>
                    )}
                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <Typography variant='h6'>{invoice.productName}</Typography>
                            <InvoiceStatusChip status={invoice.status} />
                        </Box>
                        <Typography variant='body2'>{invoice.policyNumber}</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <NavigateNext color='primary' />
                </Box>
            </Paper>
        </ButtonBase>
    );
}
