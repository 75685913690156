import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Alert, Box, Button, Fab, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import { WaivePaymentRequest, waivePayment } from '../../../../apis/variations';
import DetailedExpectedPaymentStatusChips from '../../../../components/DetailedExpectedPaymentStatusChips';
import { LoadingButton } from '../../../../components/LoadingButton';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { PaymentWaiverFields } from '../types';
import { getInvoicePolicyDescription } from '../utils';
import { calculateTotalWaived } from './PaymentWaiverDetailsStep';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    paymentWaiverFields: PaymentWaiverFields;
    loan: Loan;
    setLoan: (loan: Loan) => void;
    details: InvoiceDetailsResponse;
};

export default function PaymentWaiverReviewStep({
    cancel,
    handleBack,
    paymentWaiverFields,
    setLoan,
    details,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const onConfirmation = () => {
        setLoading(true);
        setErrorMsg(undefined);

        const waivePaymentRequest: WaivePaymentRequest = cloneDeep({
            ...paymentWaiverFields,
            expectedPayments: paymentWaiverFields.expectedPayments.filter(({ selected }) => selected),
        });

        waivePayment(details.invoice.uuid, waivePaymentRequest)
            .then(setLoan)
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setLoading(false));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review updated schedule
            </Typography>
            <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Box>
                <Grid
                    container
                    sx={{
                        border: grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: theme.shape.borderRadius + 'px',
                    }}
                >
                    <StyledGridHeaderContainer>
                        <Grid item xs={6} pr={2}>
                            <Typography variant='subtitle2'>Scheduled payment date</Typography>
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={3} container direction='row' justifyContent='flex-end'>
                            <Typography variant='subtitle2'>Amount</Typography>
                        </Grid>
                    </StyledGridHeaderContainer>

                    {buildPaymentWaiverListForReview(paymentWaiverFields, showMore)}

                    {paymentWaiverFields.expectedPayments.length > defaultNumberOfPaymentsBeforeShowMore && (
                        <StyledGridFooterContainer>
                            <Grid item xs={12} pr={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Fab
                                        size='small'
                                        onClick={() => setShowMore(!showMore)}
                                        data-testid='show-more-scheduled-payments'
                                    >
                                        {showMore ? <ExpandLess /> : <ExpandMore />}
                                    </Fab>
                                </Box>
                            </Grid>
                        </StyledGridFooterContainer>
                    )}
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'right',
                }}
            >
                <Typography variant='subtitle2' sx={{ ml: 'auto' }}>
                    Total waived: {currencyFormat.format(calculateTotalWaived(paymentWaiverFields))}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Note:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {paymentWaiverFields.note}
                    </Typography>
                </Box>
            </Box>

            <Typography variant='caption'>{`${details.client.displayName} will receive an email notification with the arrangement details.`}</Typography>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleBack} variant='outlined'>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={onConfirmation}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const defaultNumberOfPaymentsBeforeShowMore = 6;

const buildPaymentWaiverListForReview = (paymentWaiverFields: PaymentWaiverFields, showMore: boolean) => {
    return (
        <>
            {paymentWaiverFields.expectedPayments.map(
                ({ uuid, dueDate, amount, paidAmount, feeAmount, status, expectedPayments, selected }, index) => {
                    const alignCentreSx = {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    };

                    if (!showMore && index >= defaultNumberOfPaymentsBeforeShowMore && !selected) {
                        return <></>;
                    }

                    return (
                        <StyledGridItemContainer key={uuid}>
                            <Grid item xs={3} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='caption'>{moment(dueDate).format(DATE_FRIENDLY)}</Typography>
                            </Grid>
                            <Grid item xs={6} pr={2} sx={alignCentreSx}>
                                <DetailedExpectedPaymentStatusChips
                                    expectedPaymentStatus={selected ? ExpectedPaymentStatus.WAIVED : status}
                                    expectedPayments={expectedPayments}
                                />
                            </Grid>
                            <Grid item xs={3} container direction='row' justifyContent='flex-end' alignItems='center'>
                                <PaymentAmount
                                    amount={amount}
                                    paidAmount={paidAmount}
                                    feeAmount={feeAmount}
                                    waived={selected}
                                />
                            </Grid>
                        </StyledGridItemContainer>
                    );
                }
            )}
        </>
    );
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
