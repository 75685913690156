import moment from 'moment';
import { Activity, ActivityType, ActivityVariation, Contact, ContactType } from '../../../apis/enquiry';
import { DATE_FRIENDLY_MDY } from '../../../util/dateUtils';
import { getFullName } from '../../../util/stringUtils';

export const getFormattedDate = (activity: ActivityVariation) => {
    const sellerUserName = (activity as Activity).sellerUserName;
    const timeToBeChecked =
        activity.activityType === ActivityType.CONTACT ? (activity as Contact).contactDateTime : activity.createdDate;
    const submittedAt = moment.utc(timeToBeChecked).local();
    const time = submittedAt.format(DATE_FRIENDLY_MDY);
    return `Added by ${getFullName(sellerUserName)} on ${time}`;
};

export const prepareContactTitle = (contactType: ContactType) => {
    switch (contactType) {
        case ContactType.SMS:
            return 'SMS Sent';
        case ContactType.EMAIL:
            return 'Email added';
        case ContactType.CALL_INBOUND:
            return 'Incomming call';
        case ContactType.CALL_OUTBOUND:
            return 'Outgoing call';
        default:
            return '';
    }
};
