import { Box, Typography } from '@mui/material';
import moment from 'moment';

type Props = {
    date: string;
    dateFormat: string;
    timeFormat: string;
};

export default function FormattedDateTime({ date, dateFormat, timeFormat }: Readonly<Props>) {
    return (
        <Box>
            <Typography noWrap data-tag='allowRowEvents' variant='caption'>
                {moment.utc(date).local().format(dateFormat)}
            </Typography>
            <Typography noWrap data-tag='allowRowEvents' variant='subtitle1'>
                {moment.utc(date).local().format(timeFormat)}
            </Typography>
        </Box>
    );
}
