import { yupResolver } from '@hookform/resolvers/yup';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
    Control,
    Controller,
    FieldArrayWithId,
    SubmitHandler,
    useFieldArray,
    useForm,
    UseFormRegister,
    useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import DetailedExpectedPaymentStatusChips from '../../../../components/DetailedExpectedPaymentStatusChips';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { PaymentWaiverExpectedPaymentFields, PaymentWaiverFields } from '../types';
import { getExpectedPaymentsInStatuses, getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    paymentWaiverFields?: PaymentWaiverFields;
    setPaymentWaiverFields: (fields: PaymentWaiverFields) => void;
    loan: Loan;
    details: InvoiceDetailsResponse;
};

export default function PaymentWaiverDetailsStep({
    cancel,
    handleBack,
    paymentWaiverFields,
    setPaymentWaiverFields,
    loan,
    details,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [totalWaived, setTotalWaived] = useState<number>(calculateTotalWaived(paymentWaiverFields));

    const expectedPaymentsAllowWaive = useMemo(
        () =>
            getExpectedPaymentsInStatuses(loan, [
                ExpectedPaymentStatus.PENDING,
                ExpectedPaymentStatus.OVERDUE,
                ExpectedPaymentStatus.DEFERRED,
            ]),
        [loan]
    );

    const updateTotalWaived = (checked: boolean, amount: number, paidAmount: number) => {
        if (checked) {
            setTotalWaived(totalWaived + amount - paidAmount);
        } else {
            setTotalWaived(totalWaived - amount + paidAmount);
        }
    };

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<PaymentWaiverFields>({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            expectedPayments: expectedPaymentsAllowWaive.map((expectedPayment) => ({ ...expectedPayment })),
            ...paymentWaiverFields,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'expectedPayments',
    });

    const expectedPayments = useWatch({ control, name: 'expectedPayments' });

    const onSubmit: SubmitHandler<PaymentWaiverFields> = (data) => {
        setErrorMsg(undefined);

        const selectedExpectedPayment = data.expectedPayments.find(({ selected }) => selected);
        if (!selectedExpectedPayment) {
            setErrorMsg('You must choose at least one scheduled payment.');
            return;
        }

        setPaymentWaiverFields(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Payment waiver
                </Typography>
                <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

                <Typography variant='caption'>
                    Select one or more scheduled payments to be waived. They will not be collected or owed by the
                    client.
                </Typography>

                <Box>
                    <Grid
                        container
                        sx={{
                            border: grey[400],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: theme.shape.borderRadius + 'px',
                        }}
                    >
                        <StyledGridHeaderContainer>
                            <Grid item xs={4} pr={2}>
                                <Typography variant='subtitle2'>Scheduled date</Typography>
                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={4} container direction='row' justifyContent='flex-end'>
                                <Typography variant='subtitle2'>Amount (excludes fees)</Typography>
                            </Grid>
                        </StyledGridHeaderContainer>

                        {buildPaymentWaiverList(
                            fields,
                            expectedPayments,
                            showMore,
                            control,
                            register,
                            updateTotalWaived
                        )}

                        {fields.length > defaultNumberOfPaymentsBeforeShowMore && (
                            <StyledGridFooterContainer>
                                <Grid item xs={12} pr={2}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Fab
                                            size='small'
                                            onClick={() => setShowMore(!showMore)}
                                            data-testid='show-more-scheduled-payments'
                                        >
                                            {showMore ? <ExpandLess /> : <ExpandMore />}
                                        </Fab>
                                    </Box>
                                </Grid>
                            </StyledGridFooterContainer>
                        )}
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'right',
                    }}
                >
                    <Typography variant='subtitle2' sx={{ ml: 'auto' }}>
                        Total waived: {currencyFormat.format(totalWaived)}
                    </Typography>
                </Box>

                <FormControl required>
                    <FormLabel htmlFor='note' required={false}>
                        Note
                    </FormLabel>
                    <Controller
                        name='note'
                        control={control}
                        defaultValue={paymentWaiverFields?.note ?? ''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='note'
                                size='small'
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 1024 }}
                                error={!!errors?.note}
                                helperText={errors?.note?.message}
                            />
                        )}
                    />
                </FormControl>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Grid container>
                    <Grid item xs={6}>
                        <Button onClick={handleBack} variant='outlined'>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        {!isEmpty(expectedPaymentsAllowWaive) && (
                            <Button
                                disabled={expectedPayments.filter(({ selected }) => selected).length === 0}
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{ minWidth: '160px' }}
                            >
                                Next
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </StepCard>
        </form>
    );
}

const defaultNumberOfPaymentsBeforeShowMore = 6;

const buildPaymentWaiverList = (
    fields: FieldArrayWithId<PaymentWaiverFields, 'expectedPayments', 'id'>[],
    expectedPayments: PaymentWaiverExpectedPaymentFields[],
    showMore: boolean,
    control: Control<PaymentWaiverFields>,
    register: UseFormRegister<PaymentWaiverFields>,
    updateTotalWaived: (checked: boolean, amount: number, paidAmount: number) => void
) => {
    return (
        <>
            {fields.map(({ uuid, dueDate, amount, paidAmount, status, selected }, index) => {
                const expectedPayment = expectedPayments[index];
                const alignCentreSx = {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                };

                if (!showMore && index >= defaultNumberOfPaymentsBeforeShowMore && !selected) {
                    return null;
                }

                return (
                    <StyledGridItemContainer key={uuid}>
                        <Grid item xs={4} pr={2} sx={alignCentreSx}>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Controller
                                        name={`expectedPayments.${index}.selected`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value, ...rest } }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        {...rest}
                                                        {...register(`expectedPayments.${index}.selected`)}
                                                        data-testid={`expectedPayments.${index}.selected`}
                                                        checked={value}
                                                        onChange={(event, checked) => {
                                                            onChange(event);
                                                            updateTotalWaived(checked, amount, paidAmount ?? 0);
                                                        }}
                                                        size='small'
                                                    />
                                                }
                                                label={
                                                    <Typography variant='caption'>
                                                        {moment(dueDate).format(DATE_FRIENDLY)}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sx={alignCentreSx}>
                            <DetailedExpectedPaymentStatusChips
                                expectedPaymentStatus={status}
                                expectedPayments={expectedPayment.expectedPayments}
                            />
                        </Grid>
                        <Grid item xs={4} container direction='row' justifyContent='flex-end' alignItems='center'>
                            <PaymentAmount amount={amount} paidAmount={paidAmount} feeAmount={0} />
                        </Grid>
                    </StyledGridItemContainer>
                );
            })}
        </>
    );
};

export const calculateTotalWaived = (paymentWaiverFields?: PaymentWaiverFields): number => {
    return (paymentWaiverFields?.expectedPayments ?? [])
        .filter(({ selected }) => selected)
        .reduce((t, ep) => t + ep.amount - (ep.paidAmount ?? 0), 0);
};

const getSchema = () => {
    return yup.object({
        note: yup.string().required('Note required'),
    });
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
