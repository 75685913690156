import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchLoan, InvoiceDetailsResponse, InvoiceLoanAnnotation } from '../../apis/invoice';
import SnackAlert, { SnackState } from '../../components/SnackAlert';
import { useFetch } from '../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setLoanState } from '../../store/reducer/LoanReducer';
import InvoiceActionButton from './Actions';
import InvoiceBanners from './Banners';
import InvoiceBreakdown from './InvoiceBreakdown';
import InvoiceFields from './InvoiceFields';
import PolicyHeader from './PolicyHeader';

export type LoadedInvoicePageProps = {
    approvalRequestSentCount: number;
    setApprovalRequestSentCount: (count: number) => void;
};

type Props = LoadedInvoicePageProps & {
    data: InvoiceDetailsResponse;
};

export default function LoadedInvoicePage({
    approvalRequestSentCount,
    setApprovalRequestSentCount,
    data,
}: Readonly<Props>) {
    const {
        permissions: { accountAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);
    const dispatch = useAppDispatch();
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackState, setSnackState] = useState<SnackState | undefined>();

    const loanFetchingState = useFetch(async () => fetchLoan(data.invoice.uuid), [data.invoice.uuid], {
        canFetch: () => canFetchLoan(data.loan, accountAllowed),
    });
    useEffect(() => {
        dispatch(setLoanState(loanFetchingState));
    }, [loanFetchingState]);

    const closeSnackbar = () => {
        setSnackOpen(false);
    };

    const handleSetSnack = (state: SnackState) => {
        setSnackState(state);
        setSnackOpen(true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <PolicyHeader
                    policy={data.invoice.portfolio.policies[0]}
                    sellerProductLogo={data.invoice.productLogoUrl}
                />
                <Box>
                    <InvoiceActionButton
                        setApprovalRequestSentCount={setApprovalRequestSentCount}
                        approvalRequestSentCount={approvalRequestSentCount}
                        invoiceDetails={data}
                        handleSetSnack={handleSetSnack}
                    />
                </Box>
                <SnackAlert state={snackState} open={snackOpen} closeSnackbar={closeSnackbar} />
            </Box>
            <InvoiceFields invoice={data.invoice} />
            <Box mb={4}>
                <InvoiceBanners invoiceDetails={data} handleSetSnack={handleSetSnack} />
            </Box>
            <InvoiceBreakdown
                invoiceDetails={data}
                onApprovalRequestSent={() => setApprovalRequestSentCount(approvalRequestSentCount + 1)}
            />
        </Box>
    );
}

const canFetchLoan = (loan: InvoiceLoanAnnotation, accountAllowed: boolean) => {
    return accountAllowed && loan != null;
};
