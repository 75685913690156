import { Alert, Box, Button, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Invoice, PaymentFrequency } from '../../../../apis/invoice';
import { AdjustmentOverview, AvailableTerm, fetchAvailableTerms, queryAdjustment } from '../../../../apis/policy';
import { ExtendableFetchWrapper } from '../../../../components/ExtendableFetchWrapper';
import { LoadingButton } from '../../../../components/LoadingButton';
import StepCard from '../../../../components/StepCard';
import { FetchState, FetchStateType, isError, isLoading, useFetch } from '../../../../hooks/useFetch';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../../../util/dateUtils';
import Form, { FormProps } from './Form';

type Props = {
    cancel: () => void;
    setAdjustmentOverview: (overview: AdjustmentOverview) => void;
    invoice: Invoice;
};

export default function EditStep({ cancel, setAdjustmentOverview, invoice }: Readonly<Props>) {
    const termsState = useFetch(() =>
        fetchAvailableTerms(invoice.uuid).then((terms) => {
            setTermAndDate(terms, invoice.term.paymentFrequency);
            return terms;
        })
    );
    const [frequency, setFrequency] = useState<PaymentFrequency>(invoice.term.paymentFrequency!);
    const [frequencyError, setFrequencyError] = useState<string>();
    const [selectedTerm, setSelectedTerm] = useState<AvailableTerm>();
    const [nextPaymentDate, setNextPaymentDate] = useState<string>(moment().format(DATE_SERVER_FORMAT));
    const [dateError, setDateError] = useState<string>();
    const [adjustmentState, setAdjustmentState] = useState<FetchState<AdjustmentOverview>>({
        type: FetchStateType.PENDING,
    });

    const handleSetDate = (date: string) => {
        setNextPaymentDate(date);
        setDateError(undefined);
    };

    const handleSetFrequency = (terms: AvailableTerm[]) => (frequency: PaymentFrequency) => {
        setFrequencyError(undefined);
        setFrequency(frequency);
        setTermAndDate(terms, frequency);
    };

    const setTermAndDate = (terms: AvailableTerm[], frequency: PaymentFrequency) => {
        const term = terms.find((term) => term.paymentFrequency === frequency)!;
        setSelectedTerm(term);
        if (validateDate(nextPaymentDate, term) != null) {
            setNextPaymentDate(term.earliestNextPaymentDate);
        }
    };

    const reviewChanges = (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedTerm == null) {
            setFrequencyError('Please select a frequency');
            return;
        }
        const dateError = validateDate(nextPaymentDate, selectedTerm);
        if (dateError != null) {
            setDateError(dateError);
            return;
        }

        setAdjustmentState({ type: FetchStateType.LOADING });
        queryAdjustment(invoice.uuid, frequency, nextPaymentDate)
            .then(setAdjustmentOverview)
            .catch((error) => setAdjustmentState({ type: FetchStateType.ERROR, error }));
    };

    return (
        <form onSubmit={reviewChanges}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Edit schedule
                </Typography>
                <Typography>
                    Edit the schedule for Insurance #{invoice.portfolio.policies[0].number}{' '}
                    {invoice.portfolio.policies[0].currentPolicyVersion.productCode}
                </Typography>
                <ExtendableFetchWrapper<AvailableTerm[], FormProps>
                    state={termsState}
                    SuccessComponent={Form}
                    frequency={frequency}
                    handleSetFrequency={handleSetFrequency}
                    selectedTerm={selectedTerm!}
                    setNextPaymentDate={handleSetDate}
                    nextPaymentDate={nextPaymentDate}
                    frequencyError={frequencyError}
                    dateError={dateError}
                />
                {isError(adjustmentState) && (
                    <Alert color='error' severity='error'>
                        Something went wrong, please try again.
                    </Alert>
                )}
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <LoadingButton
                        sx={{ width: { sm: '230px' } }}
                        loading={isLoading(adjustmentState)}
                        variant='contained'
                        onClick={reviewChanges}
                        type='submit'
                    >
                        Review changes
                    </LoadingButton>
                    <Button variant='text' onClick={cancel}>
                        Cancel
                    </Button>
                </Box>
            </StepCard>
        </form>
    );
}

const validateDate = (date: string, selectedTerm: AvailableTerm): string | void => {
    const asMoment = moment(date, DATE_SERVER_FORMAT);
    if (isEmpty(date) || !asMoment.isValid()) {
        return 'Please input a valid date';
    }

    const minDate = moment(selectedTerm.earliestNextPaymentDate, DATE_SERVER_FORMAT);
    const maxDate = moment(selectedTerm.latestNextPaymentDate, DATE_SERVER_FORMAT);
    if (asMoment.isBefore(minDate) || asMoment.isAfter(maxDate)) {
        return `Please select a date between ${minDate.format(DATE_COMPACT)} and ${maxDate.format(DATE_COMPACT)}`;
    }
};
