import { Circle } from '@mui/icons-material';
import { Chip, ListItem, ListItemText } from '@mui/material';

type Props = { statement: string };

export default function LendingConditionDenied({ statement }: Readonly<Props>) {
    return (
        <ListItem>
            <Circle sx={{ fontSize: '10px', mr: 1 }} />
            <ListItemText primary={statement} />
            <Chip size='small' label='Denied' color='error' sx={{ ml: 1 }} />
        </ListItem>
    );
}
