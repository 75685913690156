import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { InvoiceDetailsResponse } from '../../../apis/invoice';
import { AdjustmentOverview, InvoiceLoan } from '../../../apis/policy';
import StepsDrawer from '../../../components/StepsDrawer';
import { FetchStateType } from '../../../hooks/useFetch';
import { useAppDispatch } from '../../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import DoneStep from './DoneStep';
import EditStep from './EditStep';
import ReviewStep from './ReviewStep';

type Props = {
    open: boolean;
    setClosed: () => void;
    invoiceDetails: InvoiceDetailsResponse;
};

export default function ChangePaymentSchedule({ open, setClosed, invoiceDetails }: Readonly<Props>) {
    const [step, setStep] = useState<0 | 1 | 2>(0);
    const [adjustmentOverview, setAdjustmentOverview] = useState<AdjustmentOverview>();
    const [adjustmentResult, setAdjustmentResult] = useState<InvoiceLoan>();
    const dispatch = useAppDispatch();

    const handleSetAdjustmentOverview = (overview: AdjustmentOverview) => {
        setAdjustmentOverview(overview);
        setStep(1);
    };

    const handleAdjustmentResult = (res: InvoiceLoan) => {
        setAdjustmentResult(res);
        setStep(2);
    };

    const handleClose = () => {
        if (adjustmentResult != null) {
            const updatedInvoiceDetails = cloneDeep(invoiceDetails);
            updatedInvoiceDetails.invoice = adjustmentResult.invoice;
            dispatch(setInvoiceDetailsState({ value: updatedInvoiceDetails, type: FetchStateType.SUCCESS }));
            dispatch(setLoanState({ value: adjustmentResult.loan, type: FetchStateType.SUCCESS }));
        }
        setStep(0);
        setClosed();
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === 0 && (
                <EditStep
                    cancel={handleClose}
                    setAdjustmentOverview={handleSetAdjustmentOverview}
                    invoice={invoiceDetails.invoice}
                />
            )}
            {step === 1 && (
                <ReviewStep
                    cancel={handleClose}
                    handleAdjustmentResult={handleAdjustmentResult}
                    adjustmentOverview={adjustmentOverview!}
                    invoice={invoiceDetails.invoice}
                />
            )}
            {step === 2 && <DoneStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Edit', 'Review', 'Done'];
