import { PaletteColor } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import { Palette, createTheme } from '@mui/material/styles';

type Black = {
    '100': string;
    '80': string;
    '40': string;
    '15': string;
    '10': string;
    '3': string;
};

type ChartColors = {
    '120': string;
    '110': string;
    '100': string;
    '80': string;
    '60': string;
    '40': string;
    '20': string;
    '10': string;
};

declare module '@mui/material/styles' {
    interface Palette {
        black: Black;
        blue: ChartColors;
        green: ChartColors;
        purple: ChartColors;
        navy: ChartColors;
        orange: ChartColors;
    }

    interface PaletteOptions {
        black: Black;
        blue: ChartColors;
        green: ChartColors;
        purple: ChartColors;
        navy: ChartColors;
        orange: ChartColors;
    }
    interface Components {
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: Record<string, unknown>;
            };
        };
    }
}

type UIConfig = {
    version: string;
    colors: {
        primary?: PaletteColor;
        secondary?: PaletteColor;
    };
};

const simfuniBrand = {
    blue: {
        '120': '#005898',
        '110': '#0078ba',
        '100': '#008BCE',
        '80': '#33A2D8',
        '60': '#66B9E2',
        '40': '#99D1EB',
        '20': '#CCE8F5',
        '10': '#E6F3FA',
    },
    black: {
        '100': '#000000',
        '80': '#333333',
        '40': '#9E9E9E',
        '15': '#D9D9D9',
        '10': '#E6E6E6',
        '3': '#F7F7F7',
    },
    navy: {
        '100': '#004669',
        '80': '#336B87',
        '60': '#6690A5',
        '40': '#99B5C3',
        '20': '#CCDAE1',
        '10': '#E6EDF0',
    },
    purple: {
        '100': '#8E29D1',
        '80': '#A554DA',
        '60': '#BB7FE3',
        '40': '#D2A9ED',
        '20': '#E8D4F6',
        '10': '#F4EAFA',
    },
    orange: {
        '120': '#bd590c',
        '110': '#c86e11',
        '100': '#CF7C15',
        '80': '#D99644',
        '60': '#E2B073',
        '40': '#ECCBA1',
        '20': '#F5E5D0',
        '10': '#FAF2E8',
    },
    green: {
        '120': '#2eaa00',
        '100': '#5CCF0A',
        '80': '#7DD93B',
        '60': '#9DE26C',
        '40': '#BEEC9D',
        '20': '#DEF5CE',
        '10': '#EFFAE7',
    },
};

const mergeThemeColors = (sellerUiConfig?: UIConfig): Palette => {
    return {
        ...simfuniBrand,
        primary: {
            main: sellerUiConfig?.colors.primary?.main ?? simfuniBrand.blue['100'],
            light: sellerUiConfig?.colors.primary?.light ?? simfuniBrand.blue['40'],
            dark: sellerUiConfig?.colors.primary?.dark ?? simfuniBrand.blue['110'],
            contrastText: sellerUiConfig?.colors.primary?.contrastText ?? common.white,
        },
        secondary: {
            main: sellerUiConfig?.colors.secondary?.main ?? simfuniBrand.black['80'],
            light: sellerUiConfig?.colors.secondary?.light ?? simfuniBrand.black['10'],
            dark: sellerUiConfig?.colors.secondary?.dark ?? simfuniBrand.black['100'],
            contrastText: sellerUiConfig?.colors.secondary?.contrastText ?? common.white,
        },
        success: {
            main: simfuniBrand.green['100'],
            light: simfuniBrand.green['40'],
            dark: simfuniBrand.green['120'],
            contrastText: common.white,
        },
    } as Partial<Palette> as Palette;
};

export const applyAmplifyCssVariables = (uiConfig?: UIConfig) => {
    const palette = mergeThemeColors(uiConfig);
    const root = document.querySelector(':root') as HTMLElement;

    /* Buttons */
    root.style.setProperty('--amplify-colors-brand-primary-80', palette.primary.main);
    root.style.setProperty('--amplify-colors-brand-primary-90', palette.primary.contrastText);
    root.style.setProperty('--amplify-colors-brand-primary-100', palette.primary.contrastText);
    root.style.setProperty('--amplify-components-button-primary-hover-color', palette.primary.main);
    root.style.setProperty('--amplify-components-button-primary-active-color', palette.primary.main);
    root.style.setProperty('--amplify-components-button-primary-focus-color', palette.primary.main);

    /* Password fields */
    root.style.setProperty('--amplify-components-passwordfield-button-hover-background-color', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-hover-border-color', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-hover-color)', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-active-background-color', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-active-border-color', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-active-color)', palette.primary.main);
    root.style.setProperty('--amplify-components-passwordfield-button-focus-border-color', palette.primary.main);
    root.style.setProperty('--amplify-components-fieldcontrol-focus-border-color', palette.primary.main);

    /* Tabs */
    root.style.setProperty('--amplify-components-tabs-item-hover-color', palette.primary.main);
    root.style.setProperty('--amplify-components-tabs-item-focus-color', palette.primary.main);

    /* Links */
    root.style.setProperty('--amplify-components-button-link-hover-background-color', 'transparent');
    root.style.setProperty('--amplify-components-button-link-hover-color', palette.primary.dark);
    root.style.setProperty('--amplify-components-button-link-active-background-color', 'transparent');
    root.style.setProperty('--amplify-components-button-link-active-color', palette.primary.dark);
    root.style.setProperty('--amplify-components-button-link-focus-background-color', 'transparent');
    root.style.setProperty('--amplify-components-button-link-focus-color', palette.primary.dark);
};

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        menu: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        customerLink: true;
    }
}

declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        flat: true;
        note: true;
    }
}

// to be progressively replaced
const navy3 = simfuniBrand.navy['60']; // "#667085"

const generateTheme = () => {
    const borderRadius = 8;
    const palette = mergeThemeColors();
    return createTheme({
        palette,
        shape: {
            borderRadius,
        },
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        height: '40px',
                        padding: '0 15px',
                        textTransform: 'none',
                        fontSize: '14px',
                        lineHeight: '20px',
                        borderRadius: '8px',
                        fontWeight: 600,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        borderRadius,
                        fontStyle: 'normal',
                    },
                    contained: ({ ownerState }) => {
                        const color = ownerState.color;
                        if (!['primary', 'secondary', undefined].includes(color)) {
                            return;
                        }
                        const paletteColor = palette[color === 'secondary' ? 'secondary' : 'primary'];
                        return {
                            color: paletteColor.contrastText,
                            boxShadow:
                                '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
                            '&:hover': {
                                color: paletteColor.main,
                                backgroundColor: paletteColor.contrastText,
                                boxShadow:
                                    '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
                            },
                        };
                    },
                },
                variants: [
                    {
                        props: { variant: 'menu' },
                        style: {
                            color: navy3,
                        },
                    },
                ],
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontFamily: ['Montserrat', 'Helvetica', 'Arial'].join(','),
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        textTransform: 'none',
                        color: 'rgba(0, 0, 0, 0.8)',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: 'rgba(0, 0, 0, 0.87)',
                        },
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '13px',
                        letterSpacing: '0.15px',
                        color: 'rgba(0, 0, 0, 0.6)',
                        paddingBottom: '4px',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        borderRadius,
                        background: common.white,
                        border: grey['300'],
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '& input[readonly]': {
                            color: grey['400'],
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '&[data-readonly=true]': {
                            '& .MuiInputBase-root:before': {
                                borderBottom: `1px solid ${grey['400']}`,
                            },
                            '& .MuiAutocomplete-endAdornment': {
                                display: 'none',
                            },
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: 'rgba(0, 0, 0, 0.6)',
                        weight: 400,
                        fontSize: '12px',
                        lineHeight: '20px',
                        margin: '4px 0 0 0',
                    },
                },
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    labelContainer: {
                        display: 'flex',
                    },
                },
            },
            MuiPaper: {
                variants: [
                    {
                        props: { variant: 'flat' },
                        style: {
                            background: common.white,
                            borderRadius: '10px',
                            padding: 4,
                        },
                    },
                    {
                        props: { variant: 'note' },
                        style: {
                            margin: '10px 0',
                            padding: '5px',
                            boxShadow:
                                '2px 4px 8px -2px rgba(16, 24, 40, 0.1), 2px 2px 4px -2px rgba(16, 24, 40, 0.06)',
                            borderRadius: '10px',
                        },
                    },
                ],
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'Helvetica', 'Arial'].join(','),
            h1: {
                fontWeight: 700,
                display: 'block',
                fontSize: '16px',
                lineHeight: '24px',
                verticalAlign: 'initial',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            h2: {
                display: 'block',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '28px',
                paddingBottom: '10px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            h3: {
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '32px',
                lineHeight: '37px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            h5: {
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '29px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            h6: {
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '26px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            body1: {
                display: 'block',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            body2: {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.17px',
                color: 'rgba(0, 0, 0, 0.6)',
            },
            caption: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
            },
            subtitle1: {
                fontWeight: 300,
                fontSize: '12px',
                lineHeight: '15px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            subtitle2: {
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '22px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
        },
    });
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const getMenuPropsStyle = () => {
    return {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: '100%',
                maxWidth: 250,
            },
        },
        MenuListProps: {
            style: {
                paddingTop: 0,
                width: '100%',
            },
        },
        MuiOutlinedInput: {
            input: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: '100%',
                maxWidth: 300,
                backgroundColor: 'transparent',
            },
        },
    };
};

export const getWorklistStyles = (sortProperty: string) => {
    return {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
                [`[data-column-id=${sortProperty}]`]: {
                    'svg.selectedSort': {
                        opacity: '1 !important',
                        display: 'block',
                    },
                    'svg.unselectedSort': {
                        opacity: '0 !important',
                        display: 'none',
                    },
                },
                [`:not([data-column-id=${sortProperty}])`]: {
                    'svg.selectedSort': {
                        opacity: '0 !important',
                        display: 'none',
                    },
                    'svg.unselectedSort': {
                        opacity: '1 !important',
                        display: 'block',
                    },
                },
            },
        },
    };
};
export default generateTheme;
