import { Close, HelpOutlined } from '@mui/icons-material';
import { Box, Chip, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { ExpectedPayment, Transaction, TransactionType } from '../apis/invoice';
import useDefaultFeeLabel from '../hooks/useDefaultFeeLabel';
import { DATE_TIME_FRIENDLY } from '../util/dateUtils';
import { getRowTitle, PaymentRow } from './UncomingPaymentBreakdown';

type Props = Transaction & {
    declinedResult?: string;
};

export default function TransactionBreakdown(transaction: Readonly<Props>) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const isPaymentTransaction = transaction.transactionType === TransactionType.PAYMENT;
    const isExternalTransaction = transaction.transactionType === TransactionType.EXTERNAL_TRANSACTION;
    const isWaiveTransaction = [TransactionType.WAIVED, TransactionType.DEFAULT_FEE_REVERSAL].includes(
        transaction.transactionType
    );

    const expectedPaymentAmount = -transaction.amount - transaction.feeAmount;
    const paidAmount = -transaction.amount;
    const feeAmount = transaction.feeAmount;

    return (
        <>
            <Typography variant='caption'>{currencyFormat.format(paidAmount)}</Typography>

            <IconButton onClick={openDialog}>
                <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
            </IconButton>

            <Dialog onClose={closeDialog} open={dialogOpen} fullWidth maxWidth='xs'>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={closeDialog}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                            <Typography variant='h3'>{currencyFormat.format(paidAmount)}</Typography>
                            {isPaymentTransaction && !transaction.declinedResult && (
                                <Chip size='small' label='Paid' color='success' />
                            )}
                            {isPaymentTransaction && transaction.declinedResult && (
                                <Chip size='small' label={transaction.declinedResult} color='error' />
                            )}
                            {isExternalTransaction && (
                                <Chip
                                    size='small'
                                    label={paidAmount < 0 ? 'Refunded externally' : 'Paid externally'}
                                    color='success'
                                />
                            )}
                            {isWaiveTransaction && <Chip size='small' label='Waived' color='primary' />}
                        </Box>
                        <Box mb={4}>
                            {isPaymentTransaction && <Typography variant='subtitle1'>Payment date</Typography>}
                            {isExternalTransaction && (
                                <Typography variant='subtitle1'>
                                    {paidAmount < 0 ? 'Refund date' : 'Payment date'}
                                </Typography>
                            )}
                            {isWaiveTransaction && <Typography variant='subtitle1'>Waive date</Typography>}
                            <Typography variant='caption'>
                                {moment(transaction.transactionDate).format(DATE_TIME_FRIENDLY)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        {transaction.expectedPaymentTransactionMapping?.map((ept) => (
                            <ExpectedPaymentTransactionRow
                                key={ept.expectedPayment.uuid}
                                expectedPayment={ept.expectedPayment}
                                amountAllocated={ept.amountAllocated}
                            />
                        ))}
                        {transaction.arrangementTransactionMapping?.map((at) => (
                            <PaymentRow key={at.arrangement.uuid} rowTitle='Arrangement' amount={at.amountAllocated} />
                        ))}
                    </Box>
                    <Box mt={2}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Subtotal
                            </Typography>
                            <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(expectedPaymentAmount)}
                            </Typography>
                        </Box>
                        {feeAmount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    + Transaction fee
                                </Typography>
                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(feeAmount)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='subtitle2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Total
                            </Typography>
                            <Typography variant='subtitle2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(paidAmount)}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

type ExpectedPaymentTransactionRowProps = {
    expectedPayment: ExpectedPayment;
    amountAllocated: number;
};

const ExpectedPaymentTransactionRow = ({
    expectedPayment,
    amountAllocated,
}: Readonly<ExpectedPaymentTransactionRowProps>) => {
    const defaultFeeLabel = useDefaultFeeLabel();
    return <PaymentRow rowTitle={getRowTitle(expectedPayment, defaultFeeLabel)} amount={amountAllocated} />;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
