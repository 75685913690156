import { Chip, CircularProgress } from '@mui/material';
import { FileAction } from '../../apis/document';
import DocumentIcon from './DocumentIcon';

type DocumentChipProps = {
    name: string;
    fileType: string;
    isLoading: boolean;
    onClick: (action: FileAction) => void;
};

export default function DocumentChipButton({ name, fileType, onClick, isLoading }: Readonly<DocumentChipProps>) {
    return (
        <Chip
            label={name}
            variant='outlined'
            icon={isLoading ? <CircularProgress size={20} /> : <DocumentIcon contentType={fileType} />}
            clickable={!isLoading}
            onClick={() => onClick(FileAction.VIEW)}
            size='small'
            sx={{ p: 1, height: 30, mr: 1, justifyContent: 'flex-start' }}
        />
    );
}
