import axios from 'axios';
import { assignDefaultListParams, ListParams } from './common';

type ListCommunicationsParams = ListParams & {
    sentStartDate?: string;
    sentEndDate?: string;
    deliveryType?: DeliveryType;
    notificationTypes?: NotificationType[];
    invoiceIdentifier?: string;
    insuredIdentifier?: string;
};

export enum DeliveryType {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
}

export enum NotificationType {
    INSURED_DELINQUENT_LOAN_SMS = 'INSURED_DELINQUENT_LOAN_SMS',
    INSURED_DELINQUENT_LOAN = 'INSURED_DELINQUENT_LOAN',
    SEND_INVOICE = 'SEND_INVOICE',
    BANK_ACCOUNT_CORRECTION_REQUIRED = 'BANK_ACCOUNT_CORRECTION_REQUIRED',
    WELCOME_EMAIL_FUNDED = 'WELCOME_EMAIL_FUNDED',
    WELCOME_EMAIL_IN_FULL = 'WELCOME_EMAIL_IN_FULL',
    UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
    SEND_ENDORSEMENT_CONFIRMATION = 'SEND_ENDORSEMENT_CONFIRMATION',
    PAYMENT_DEFERRAL = 'PAYMENT_DEFERRAL',
    PAYMENT_ARRANGEMENT = 'PAYMENT_ARRANGEMENT',
    CANCEL_PAYMENT_ARRANGEMENT = 'CANCEL_PAYMENT_ARRANGEMENT',
    INVOICE_RENEWAL_PIF = 'INVOICE_RENEWAL_PIF',
    INVOICE_RENEWAL_PF = 'INVOICE_RENEWAL_PF',
    UNSOLICITED_FUNDING_DECLINED = 'UNSOLICITED_FUNDING_DECLINED',
    UNSOLICITED_PAYMENT_RECEIVED = 'UNSOLICITED_PAYMENT_RECEIVED',
    LOAN_CANCELLATION_SCHEDULED = 'LOAN_CANCELLATION_SCHEDULED',
    POLICY_VERSION_QUOTE = 'POLICY_VERSION_QUOTE',
}

export type CommunicationsPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: Communication[];
};

export type Communication = {
    uuid: string;
    notificationDetail?: {
        messageBody: string;
    };
    template: {
        templateType: DeliveryType;
        notificationType: NotificationType;
    };
    submittedAt: string;
    subject?: string;
    fromAddress?: string;
    toAddress: string;
    bcc?: string;
    attachments?: Attachment[];
    invoiceIdentifier?: string;
    bouncedEmails?: string[];
};

export type Attachment = {
    name: string;
    contentType: string;
    contentId: string;
    documentIdentifier: string;
};

export const searchCommunications = async (
    partial?: Partial<ListCommunicationsParams>
): Promise<CommunicationsPage> => {
    const params = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/communications/_query`, params)
        .then(({ data }) => data);
};
