import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { EnquiryStatus, EnquiryType } from '../../apis/enquiry';
import { UserSearchResult } from '../../apis/users';
import { useAppSelector } from '../../store/reducer/Hooks';
import List from './List';
import EnquiryAssigneeSelect from './selectFilters/EnquiryAssigneeSelect';
import EnquiryCreateDateSelect from './selectFilters/EnquiryCreateDateSelect';
import EnquiryStatusSelect from './selectFilters/EnquiryStatusSelect';
import EnquiryTypeSelect from './selectFilters/EnquiryTypeSelect';

type Props = {
    data: EnquiryType[];
};

export default function EnquiryPageBody({ data }: Readonly<Props>) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { userAllowed } = permissions;
    const [enquiryStatuses, setEnquiryStatuses] = useState<EnquiryStatus[]>([]);
    const [enquiryTypes, setEnquiryTypes] = useState<string[]>([]);
    const [enquiryStartDate, setEnquiryStartDate] = useState<string>();
    const [enquiryEndDate, setEnquiryEndDate] = useState<string>();
    const [assigneesSelected, setAssigneesSelected] = useState<UserSearchResult[]>([]);

    return (
        <Paper variant='flat' sx={{ py: 3, px: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', pb: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%', gap: 2 }}>
                    {userAllowed && (
                        <Box sx={{ width: { xs: '100%', sm: '30%' } }}>
                            <EnquiryAssigneeSelect
                                assigneesSelected={assigneesSelected}
                                setAssigneesSelected={setAssigneesSelected}
                            />
                        </Box>
                    )}
                    {data.length !== 0 && (
                        <Box sx={{ width: { xs: '100%', sm: '20%' } }}>
                            <EnquiryTypeSelect
                                availableEnquiryTypes={data}
                                enquiryTypes={enquiryTypes}
                                setEnquiryTypes={setEnquiryTypes}
                            />
                        </Box>
                    )}
                    <Box sx={{ width: { xs: '100%', sm: '20%' } }}>
                        <EnquiryStatusSelect
                            enquiryStatuses={enquiryStatuses}
                            setEnquiryStatuses={setEnquiryStatuses}
                        />
                    </Box>
                    <Box sx={{ width: { xs: '100%', sm: '30%' } }}>
                        <EnquiryCreateDateSelect
                            enquiryStartDate={enquiryStartDate}
                            setEnquiryStartDate={setEnquiryStartDate}
                            enquiryEndDate={enquiryEndDate}
                            setEnquiryEndDate={setEnquiryEndDate}
                        />
                    </Box>
                </Box>
            </Box>
            <List
                enquiryTypes={enquiryTypes}
                enquiryStatuses={enquiryStatuses}
                enquiryStartDate={enquiryStartDate}
                enquiryEndDate={enquiryEndDate}
                assigneesSelected={assigneesSelected}
            />
        </Paper>
    );
}
