import axios from 'axios';

export type SellerProduct = {
    covers?: Cover[];
    questionnaire?: Questionnaire;
    quoteGeneratorType: QuoteGeneratorType;
    name: string;
    uuid: string;
    paymentDeferralEnabled: boolean;
};

export enum QuoteGeneratorType {
    INTERNAL = 'INTERNAL',
    MANUAL = 'MANUAL',
}

type Cover = {
    name: string;
    code: string;
    enabled: boolean;
    fixedCoverage: boolean;
    uuid: string;
};

type Questionnaire = {
    name: string;
    uuid: string;
    formDetails: string;
};

export const getSellerProduct = async (sellerProductIdentifier: string): Promise<SellerProduct> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/seller-products/${sellerProductIdentifier}`)
        .then(({ data }) => data);
};
