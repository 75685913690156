import { Box, Chip } from '@mui/material';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Direction, defaultPageSize, sortOrderToDirection } from '../../apis/common';
import { CancellationReason, LoanCancellationRequestStatus } from '../../apis/invoice';
import {
    CancellationRequestSortProperty,
    CancellationRequestSummary,
    searchCancellationRequests,
} from '../../apis/loan';
import ErrorMessage from '../../components/ErrorMessage';
import NoSearchResults from '../../components/NoSearchResults';
import PageLoading from '../../components/PageLoading';
import SortIcons from '../../components/SortIcons';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { isError, isSuccess, useFetch } from '../../hooks/useFetch';
import { useOpenRow } from '../../hooks/useOpenRow';
import { getWorklistStyles } from '../../style/theme';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../util/dateUtils';

type Props = {
    cancellationStatuses: LoanCancellationRequestStatus[];
    cancellationReasons: CancellationReason[];
    effectiveStartDate: string;
    effectiveEndDate: string;
};
const DEBOUNCE_MS = 500;
const POLICY_NUMBER_MAX_LENGTH = 14;

export default function List({
    cancellationStatuses,
    cancellationReasons,
    effectiveStartDate,
    effectiveEndDate,
}: Readonly<Props>) {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sortProperty, setSortProperty] = useState(CancellationRequestSortProperty.EFFECTIVE_DATE);
    const [direction, setDirection] = useState(Direction.DESC);
    const { rowClick, setUrl } = useOpenRow();

    const debouncedCancellationRequestStatuses = useDebouncedValue(cancellationStatuses, DEBOUNCE_MS);
    const debouncedCancellationRequestReasons = useDebouncedValue(cancellationReasons, DEBOUNCE_MS);
    const debouncedEffectiveStartDate = useDebouncedValue(effectiveStartDate, DEBOUNCE_MS);
    const debouncedEffectiveEndDate = useDebouncedValue(effectiveEndDate, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchCancellationRequests({
                page: page - 1,
                pageSize,
                cancellationRequestSortProperty: sortProperty,
                direction,
                statuses: debouncedCancellationRequestStatuses,
                reasons: debouncedCancellationRequestReasons,
                effectiveDateStart: debouncedEffectiveStartDate,
                effectiveDateEnd: debouncedEffectiveEndDate,
            }),
        [
            page,
            pageSize,
            sortProperty,
            direction,
            debouncedCancellationRequestStatuses,
            debouncedCancellationRequestReasons,
            debouncedEffectiveStartDate,
            debouncedEffectiveEndDate,
        ]
    );

    const handleSort = (column: TableColumn<CancellationRequestSummary>, sortOrder: SortOrder) => {
        setSortProperty(column.id as CancellationRequestSortProperty);
        setDirection(sortOrderToDirection(sortOrder));
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <DataTable
            data={isSuccess(state) ? state.value.records : []}
            columns={columns}
            onRowClicked={(row: CancellationRequestSummary, e: React.MouseEvent) => {
                rowClick('/invoices/' + row.invoiceIdentifier, e);
            }}
            onRowMouseEnter={(row: CancellationRequestSummary) => {
                setUrl('/invoices/' + row.invoiceIdentifier);
            }}
            pointerOnHover={true}
            highlightOnHover={true}
            pagination
            paginationServer
            onSort={handleSort}
            defaultSortAsc={false}
            defaultSortFieldId={CancellationRequestSortProperty.EFFECTIVE_DATE}
            sortIcon={<SortIcons />}
            sortServer
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            paginationPerPage={pageSize}
            paginationDefaultPage={page}
            paginationTotalRows={isSuccess(state) ? state.value.totalRecords : 0}
            progressPending={!isSuccess(state)}
            progressComponent={<PageLoading />}
            noDataComponent={
                <Box width='100%'>
                    <NoSearchResults />
                </Box>
            }
            customStyles={getWorklistStyles(sortProperty)}
        />
    );
}

const columns: TableColumn<CancellationRequestSummary>[] = [
    {
        name: 'Cancellation reason',
        selector: (row) => upperFirst(row.reason.toLowerCase().replace('_', ' ')),
        sortable: true,
        id: CancellationRequestSortProperty.REASON,
    },
    {
        name: 'Policies',
        cell: (row) => renderPolicyNumbers(row.policyNumbers),
    },
    {
        name: 'Client',
        cell: (row) => row.clientName,
    },
    {
        name: 'Effective date',
        selector: (row) => moment(row.effectiveDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY),
        sortable: true,
        id: CancellationRequestSortProperty.EFFECTIVE_DATE,
    },
    {
        name: 'Cancellation status',
        cell: (row) => renderCancellationStatus(row.status),
        sortable: true,
        id: CancellationRequestSortProperty.STATUS,
    },
    {
        name: 'Current Balance',
        selector: (row) => currencyFormatter.format(row.balance),
        sortable: true,
        id: CancellationRequestSortProperty.BALANCE,
        right: true,
    },
];

function renderCancellationStatus(status: LoanCancellationRequestStatus) {
    switch (status) {
        case LoanCancellationRequestStatus.SCHEDULED:
            return (
                <Chip
                    label='Scheduled'
                    color='success'
                    size='small'
                    variant='outlined'
                    sx={{ pointerEvents: 'none' }}
                />
            );
        case LoanCancellationRequestStatus.COMPLETED:
            return (
                <Chip
                    label='Completed'
                    color='default'
                    size='small'
                    variant='outlined'
                    sx={{ pointerEvents: 'none' }}
                />
            );
        case LoanCancellationRequestStatus.UNDERPAID:
            return (
                <Chip label='Underpaid' color='error' size='small' variant='outlined' sx={{ pointerEvents: 'none' }} />
            );
        case LoanCancellationRequestStatus.OVERPAID:
            return (
                <Chip label='Overpaid' color='error' size='small' variant='outlined' sx={{ pointerEvents: 'none' }} />
            );
    }
}

function renderPolicyNumbers(policyNumbers: string[]) {
    if (policyNumbers.length === 0) {
        return '';
    }

    let firstPolicyNumber = policyNumbers[0];

    if (firstPolicyNumber.length >= POLICY_NUMBER_MAX_LENGTH) {
        firstPolicyNumber = firstPolicyNumber.substring(0, POLICY_NUMBER_MAX_LENGTH).concat('...');
    }

    if (policyNumbers.length > 1) {
        return firstPolicyNumber.concat(' + ', policyNumbers.length - 1 + ' more');
    }

    return firstPolicyNumber;
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 2,
});
