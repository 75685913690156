import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { Invoice, InvoiceDetailsResponse } from '../../../apis/invoice';
import { AnnotatedTermPaymentMethod, fetchTermPaymentMethods } from '../../../apis/policy';
import { PaymentConfiguration } from '../../../apis/variations';
import { ExtendableFetchWrapper } from '../../../components/ExtendableFetchWrapper';
import StepsDrawer from '../../../components/StepsDrawer';
import { FetchStateType, useFetch } from '../../../hooks/useFetch';
import { useAppDispatch } from '../../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import SuccessStep from './SuccessStep';
import UpdateStep, { UpdateStepProps } from './UpdateStep';
import { InvoicePaymentConfig } from '../../../apis/paymentMethod';

type Props = {
    open: boolean;
    setClosed: () => void;
    invoiceDetails: InvoiceDetailsResponse;
    paymentConfiguration: PaymentConfiguration;
    updatePaymentConfiguration: (config: PaymentConfiguration) => void;
};

export default function ChangePaymentMethod({
    open,
    setClosed,
    invoiceDetails,
    paymentConfiguration,
    updatePaymentConfiguration,
}: Readonly<Props>) {
    const [step, setStep] = useState<0 | 1>(0);
    const [updatedInvoice, setUpdatedInvoice] = useState<Invoice>();
    const [updatedPaymentConfig, setUpdatedPaymentConfig] = useState<PaymentConfiguration>();
    const dispatch = useAppDispatch();
    const availablePaymentMethodsState = useFetch(() => fetchTermPaymentMethods(invoiceDetails.invoice.uuid));

    const handleSetUpdatedModels = (invoicePaymentConfig: InvoicePaymentConfig) => {
        setUpdatedInvoice(invoicePaymentConfig.invoice);
        setUpdatedPaymentConfig(invoicePaymentConfig.paymentConfiguration);
        setStep(1);
    };

    const handleClose = () => {
        if (updatedInvoice != null) {
            const updatedInvoiceDetails = cloneDeep(invoiceDetails);
            updatedInvoiceDetails.invoice = updatedInvoice;
            dispatch(setInvoiceDetailsState({ value: updatedInvoiceDetails, type: FetchStateType.SUCCESS }));
        }
        if (updatedPaymentConfig) {
            updatePaymentConfiguration(updatedPaymentConfig);
        }
        setStep(0);
        setClosed();
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === 0 && (
                <ExtendableFetchWrapper<AnnotatedTermPaymentMethod[], UpdateStepProps>
                    state={availablePaymentMethodsState}
                    SuccessComponent={UpdateStep}
                    cancel={handleClose}
                    setUpdatedModels={handleSetUpdatedModels}
                    invoiceDetails={invoiceDetails}
                    paymentConfiguration={paymentConfiguration}
                />
            )}
            {step === 1 && <SuccessStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Change', 'Done'];
