import { Close, HelpOutlined } from '@mui/icons-material';
import { Box, Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import {
    ConsolidatedPayment,
    ExpectedPayment,
    ExpectedPaymentCausedByType,
    ExpectedPaymentStatus,
} from '../apis/invoice';
import useDefaultFeeLabel from '../hooks/useDefaultFeeLabel';
import { DATE_COMPACT } from '../util/dateUtils';

type Props = Omit<ConsolidatedPayment, 'consolidatedExpectedPayments'> & {
    expectedPayments: ExpectedPayment[];
};

export default function UpcomingPaymentBreakdown(payment: Readonly<Props>) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const paidAmount = payment.paidAmount ?? 0;
    const waivedAmount = payment.waivedAmount ?? 0;
    const dueAmount = payment.dueAmount ?? 0;
    const feeAmount = payment.feeAmount ?? 0;
    const dueAmountWithFee = dueAmount + feeAmount;
    const isPaymentWaived = dueAmountWithFee === 0;

    return (
        <>
            <Typography variant='caption'>{currencyFormat.format(dueAmountWithFee)}</Typography>

            <IconButton onClick={openDialog}>
                <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
            </IconButton>

            <Dialog onClose={closeDialog} open={dialogOpen} fullWidth maxWidth='xs'>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={closeDialog}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                            <Typography variant='h3'>{currencyFormat.format(dueAmountWithFee)}</Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography variant='subtitle1'>Payment date</Typography>
                            {isPaymentWaived && <Typography variant='caption'>-</Typography>}
                            {!isPaymentWaived && (
                                <Typography variant='caption'>
                                    {moment(payment.dueDate).format(DATE_COMPACT)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        {payment.expectedPayments?.map((ep) => (
                            <ExpectedPaymentRow key={ep.uuid} expectedPayment={ep} />
                        ))}
                        {payment.arrangements?.map((arrangement) => (
                            <PaymentRow key={arrangement.uuid} rowTitle='Arrangement' amount={arrangement.amount} />
                        ))}
                    </Box>
                    <Box mt={2}>
                        {paidAmount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    - Payments made
                                </Typography>

                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(-paidAmount)}
                                </Typography>
                            </Box>
                        )}
                        {waivedAmount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    - Payments waived
                                </Typography>

                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(-waivedAmount)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Subtotal
                            </Typography>
                            <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(dueAmount)}
                            </Typography>
                        </Box>
                        {feeAmount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    + Transaction fee
                                </Typography>
                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(feeAmount)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='subtitle2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Total
                            </Typography>
                            <Typography variant='subtitle2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(dueAmountWithFee)}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export const PaymentRow = ({ rowTitle, amount }: { rowTitle: string; amount: number }) => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, mt: 2 }}>
                <Typography variant='subtitle2'>{rowTitle}</Typography>
                <Typography variant='subtitle2'>{currencyFormat.format(amount)}</Typography>
            </Box>
            <Divider />
        </>
    );
};

type ExpectedPaymentRowProps = {
    expectedPayment: ExpectedPayment;
};

const ExpectedPaymentRow = ({ expectedPayment }: Readonly<ExpectedPaymentRowProps>) => {
    const defaultFeeLabel = useDefaultFeeLabel();
    return <PaymentRow rowTitle={getRowTitle(expectedPayment, defaultFeeLabel)} amount={expectedPayment.amount} />;
};

export const getRowTitle = (expectedPayment: ExpectedPayment, defaultFeeLabel: string) => {
    if (expectedPayment.causedBy === ExpectedPaymentCausedByType.DEFAULT_FEE) {
        return upperFirst(defaultFeeLabel);
    }

    if (expectedPayment.causedBy === ExpectedPaymentCausedByType.CANCELLATION_PRORATA_PAYMENT) {
        return 'Cancellation repayment adjustment';
    }

    if (expectedPayment.status === ExpectedPaymentStatus.OVERDUE) {
        return `Overdue repayment (due on ${moment(expectedPayment.dueDate).format(DATE_COMPACT)})`;
    }

    return 'Regular repayment';
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
