import { Alert, AlertColor, Snackbar } from '@mui/material';

export type SnackState = {
    severity: AlertColor;
    msg: string;
    anchorOriginVertical?: 'top' | 'bottom';
    anchoreOriginHorizontal?: 'left' | 'center' | 'right';
    variant?: 'outlined' | 'filled' | 'standard';
};

type Props = {
    state?: SnackState;
    open: boolean;
    closeSnackbar: () => void;
};

export default ({ state, open, closeSnackbar }: Props) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: state?.anchorOriginVertical ?? 'top',
                horizontal: state?.anchoreOriginHorizontal ?? 'center',
            }}
            open={open}
            onClose={closeSnackbar}
            autoHideDuration={6000}
        >
            <Alert severity={state?.severity} variant={state?.variant ?? 'standard'}>
                {state?.msg}
            </Alert>
        </Snackbar>
    );
};
