import {
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { EnquiryStatus } from '../../../apis/enquiry';
import { getFriendlyEnumsString } from '../../../util/stringUtils';

type Props = {
    enquiryStatuses: EnquiryStatus[];
    setEnquiryStatuses: (enquiryTypes: EnquiryStatus[]) => void;
};

export default function EnquiryStatusSelect({ enquiryStatuses, setEnquiryStatuses }: Readonly<Props>) {
    const handleEnquiryStatusesChange = (event: SelectChangeEvent<typeof enquiryStatuses>) => {
        const {
            target: { value },
        } = event;
        const enquiryStatusSelected = (typeof value === 'string' ? value.split(',') : value) as EnquiryStatus[];
        setEnquiryStatuses(enquiryStatusSelected);
    };

    return (
        <FormControl size='small' fullWidth>
            <FormLabel htmlFor='enquiry-status-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                Status
            </FormLabel>
            <Select
                multiple
                value={enquiryStatuses}
                displayEmpty={true}
                onChange={handleEnquiryStatusesChange}
                input={<OutlinedInput id='enquiry-status-multiple-checkbox' />}
                renderValue={(selected: EnquiryStatus[]) =>
                    renderValues(selected, Object.values(EnquiryStatus), getFriendlyEnumsString)
                }
            >
                {Object.values(EnquiryStatus).map((status) => (
                    <MenuItem key={status} value={status}>
                        <Checkbox checked={enquiryStatuses.includes(status)} />
                        <ListItemText primary={getFriendlyEnumsString(status)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const renderValues = (selected: EnquiryStatus[], values: EnquiryStatus[], transform: (t: EnquiryStatus) => string) => {
    if (selected.length === values.length || selected.length === 0) {
        return 'Any';
    }

    return selected.map(transform).join(', ');
};
